import React, { useState, useRef, useMemo, useEffect, useCallback } from 'react';
import { Pagination, Spin, Typography, Tooltip, Tabs, Layout, InputNumber } from 'antd';
import { Card } from "antd";
import { Progress } from "antd";
import { Modal, Form, Input, Select, Checkbox, Button, Row, Col, Radio, notification, DatePicker, Divider, Space } from 'antd';
import { ArrowLeftOutlined, CloudUploadOutlined, DeleteOutlined, EditOutlined, CheckOutlined, CloseOutlined, FullscreenOutlined, ShrinkOutlined } from '@ant-design/icons';
import history from '../../services/history.js';

import moment from 'moment';
import ProjectsInputs from './ProjectsInputs.js';
import { AddOrUpdateMasterData, getListFromDatabase } from '../../redux/EMEAA/saga.js';
import TecniniciansPage from './TecniniciansPage.js';
const { Option } = Select;
const { Title, Text } = Typography;
const { TabPane } = Tabs;
const { Content, Footer } = Layout;
const ProjectDetailsGrid = (props) => {
    const {

        inputData,
        setInputData,
        isEdit,
        setIsEdit,
        editRecord,
        setShowPipelineModel,
        projectDetails,
        setProjectDetails,
        formData,
        setFormData,
        OnGenerateCashFlow,
        isGenerateLoading
    } = props;
    const [activeKey, setActiveKey] = useState('1')
    const [activeKeyLine, setActiveKeyLine] = useState('1')
    const [count, setCount] = useState(1)
    const [countries_List, setCountries_List] = useState([]);
    const [newItem, setNewItem] = useState('');
    //const [formData, setFormData] = useState({});
    useEffect(() => {
        if (countries_List.length == 0) {
            (async () => {
                const category = await getListFromDatabase('Countries_List');
                if (category) {
                    setCountries_List(category)
                }
            })();
        }
    }, [])





    const handleInputChange = (name, value) => {
        setFormData((prevData) => {
            const keys = name.split("."); // Split for nested keys
            const newData = { ...prevData }; // Create a copy to maintain immutability
            let ref = newData;

            // Traverse and ensure objects exist
            for (let i = 0; i < keys.length - 1; i++) {
                if (!ref[keys[i]]) ref[keys[i]] = {};
                ref = ref[keys[i]];
            }

            const field = keys[keys.length - 1]; // Extract the field name

            const dateFields = [
                "Expected_signing_date",
                "Start_of_job",
                "Leaves_port",
                "Arrives_port",
                "Production_start",
                "Production_end",
                "welding_wire_order",
                "Completion_of_PQT",
                "Welding_wire_shipment",
                "Invoice_Date",
                "Money_back_Date"
            ];

            // Handle date formatting
            if (dateFields.includes(field)) {
                value = value && moment(value).isValid() ? moment(value).format("YYYY-MM-DD") : "";
            }

            if (field === "Expected_signing_date") {
                if (newData?.Performance_Bond.At_signing == 'Yes')
                    newData.Performance_Bond.Invoice_Date = value || '';
                if (newData?.Cash_collateral.At_signing == 'Yes')
                    newData.Cash_collateral.Invoice_Date = value || '';

            }

            // Handle "At_Signing" condition
            else if (field === "At_signing") {
                // Get the index from the field name (Assuming it's inside Performance_Bond)
                const milestoneIndex = keys.length === 2 ? keys[1] : null;
                if (keys[0] === 'Performance_Bond') {
                    if (milestoneIndex && newData.Performance_Bond) {
                        newData.Performance_Bond.Invoice_Date = value === "Yes" ? prevData.Expected_signing_date || '' : '';
                    }

                }
                else if (keys[0] === 'Cash_collateral') {
                    if (milestoneIndex && newData.Cash_collateral) {
                        newData.Cash_collateral.Invoice_Date = value === "Yes" ? prevData.Expected_signing_date || '' : '';
                    }
                }
            }


            ref[field] = value; // Update the final key

            return newData;
        });
    };




    const GetInputRow = () => {
        // Function to handle input changes
        const handleInputChange = (name, value) => {
            const keys = name.split(".");

            if (keys.length === 3) {
                const [, index, field] = keys;

                // Format the date properly if it's an Invoice Date
                if (field === "Invoice_Date") {
                    value = moment(value).isValid() ? moment(value).format("YYYY-MM-DD") : "";
                }

                setFormData((prevData) => {
                    const updatedMilestones = {
                        ...prevData.Payment_Milestones,
                        [index]: {
                            ...prevData.Payment_Milestones[index],
                            [field]: value
                        }
                    };



                    return {
                        ...prevData,
                        Payment_Milestones: updatedMilestones
                    };
                });
            }
        };


        // Function to add a new milestone row
        const addMilestone = () => {
            const newIndex = Object.keys(formData.Payment_Milestones).length + 1;
            setFormData((prevData) => ({
                ...prevData,
                Payment_Milestones: {
                    ...prevData.Payment_Milestones,
                    [newIndex]: { Milestone_Text: "", Percentage: 0, Invoice_Date: null }
                }
            }));
        };

        // Function to remove a specific milestone row
        const removeMilestone = (index) => {
            const updatedMilestones = { ...formData.Payment_Milestones };
            delete updatedMilestones[index];

            setFormData((prevData) => ({
                ...prevData,
                Payment_Milestones: updatedMilestones
            }));
        };

        return (
            <Layout className='pipeline-modal date-picker'
                style={{ width: "100%", height: "calc(100vh - 260px)", padding: "10px", overflowX: "hidden" }}
            >
                <Content
                    style={{ height: "90%", overflowY: "auto", overflowX: "hidden", paddingRight: "0px" }}
                >
                    <Form name="project_form" layout="vertical" autoComplete="off">
                        <Row gutter={[16, 16]} style={{ display: "flex", flexDirection: "column", flexWrap: "wrap" }}>
                            {/* Headers Row (Only for Invoice Date) */}
                            <Row gutter={16} style={{ paddingLeft: "0px", width: "100%", margin: "0" }}>
                                <Col lg={10} md={12} sm={0}>
                                    <div style={{ textAlign: "left", paddingLeft: "12px" }}>Name<a style={{ color: 'red' }}>*</a></div>
                                </Col>
                                <Col lg={6} md={6} sm={12}>
                                    <div style={{ textAlign: "left", paddingLeft: "10px" }}>Percentage<a style={{ color: 'red' }}>*</a></div>
                                </Col>
                                <Col lg={6} md={6} sm={12}>
                                    <div style={{ textAlign: "left", paddingLeft: "6px" }}>Invoice Date <a style={{ color: 'red' }}>*</a></div>
                                </Col>
                            </Row>

                            {Object.entries(formData.Payment_Milestones).map(([index, milestone]) => (
                                <Row
                                    key={index}
                                    gutter={16}
                                    align="left"
                                    style={{ paddingLeft: "10px", flexWrap: "wrap", width: "100%", margin: "0" }}
                                >
                                    <Col lg={10} md={12} sm={24}>
                                        <Input style={{ borderRadius: '5px' }}
                                            size="large"
                                            placeholder="Milestone Name"
                                            value={milestone.Milestone_Text}
                                            onChange={(e) =>
                                                handleInputChange(`Payment_Milestones.${index}.Milestone_Text`, e.target.value)
                                            }
                                        />
                                    </Col>
                                    <Col lg={6} md={6} sm={12}>
                                        <Input
                                            addonAfter="%"
                                            className="Data360-addafter1"
                                            value={milestone.Percentage !== undefined ? Number((milestone.Percentage * 100).toFixed(2)) : ''}
                                            onChange={(e) => {
                                                const newValue = Number(e.target.value) / 100;
                                                handleInputChange(`Payment_Milestones.${index}.Percentage`, parseFloat(newValue.toFixed(4))); // Store with 4 decimal places for precision
                                            }}
                                            min={1}
                                            placeholder="Enter Percentage"
                                            type="number"
                                            style={{ width: "100%", textAlign: "left" }}
                                            size="large"
                                        />
                                    </Col>
                                    <Col lg={6} md={6} sm={12}>

                                        <DatePicker
                                            value={milestone.Invoice_Date ? moment(milestone.Invoice_Date) : null}
                                            onChange={(date) => handleInputChange(`Payment_Milestones.${index}.Invoice_Date`, date)}
                                            format="DD-MMM-YYYY"
                                            placeholder="DD-MM-YYYY"
                                            style={{ width: "100%", borderRadius: '5px' }}
                                            disabledDate={(current) => {
                                                const signingDate = formData["Expected_signing_date"] ? moment(formData["Expected_signing_date"]) : null;

                                                if (!current) return true; // Disable invalid selections

                                                // Ensure no selection before "Expected_signing_date"
                                                if (signingDate && current < signingDate) {
                                                    return true;
                                                }


                                                // else if (dateField === "Arrives_port" && formData["Leaves_port"]) {
                                                //     return current < moment(formData["Leaves_port"]);
                                                // }

                                                return false; // Otherwise, allow selection
                                            }}
                                        />
                                    </Col>
                                    <Col lg={2} md={4} sm={6} style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                                        <DeleteOutlined danger onClick={() => removeMilestone(index)} className="icon-18">X</DeleteOutlined>
                                    </Col>
                                </Row>
                            ))}
                        </Row>
                    </Form>
                </Content>

                {/* Fixed Add Button in Footer */}
                <Footer
                    className="equipment-footer"
                    style={{ height: "10%", display: "flex", justifyContent: "flex-end", }}
                >
                    <Button type="primary" onClick={addMilestone}>+ Add Milestone</Button>
                </Footer>
            </Layout>

        );
    };


    const UpdatePipeline = (newCount) => {

        setFormData((prevData) => {
            const updatedTechnicians = { ...prevData.Technicians };
            const currentCount = prevData.Pipelines_count;
            if (newCount > currentCount) {
                // Add new pipelines while keeping existing ones
                for (let i = currentCount + 1; i <= newCount; i++) {
                    if (!updatedTechnicians[`Pipeline_${i}`]) {  // Ensure not overwriting existing data
                        updatedTechnicians[`Pipeline_${i}`] = {
                            Pipeline_Number: i,
                            Senior_technicians: { Quantity: 0, Day_rate: 0, Day_revenue: 0 },
                            Lead_technicians: { Quantity: 0, Day_rate: 0, Day_revenue: 0 },
                            Welding_crews: { Quantity: 0, Day_rate: 0, Day_revenue: 0 }
                        };
                    }
                }
            } else if (newCount < currentCount) {
                // Remove extra pipelines
                for (let i = currentCount; i > newCount; i--) {
                    delete updatedTechnicians[`Pipeline_${i}`];
                }
            }

            return {
                ...prevData,
                Pipelines_count: newCount,
                Technicians: updatedTechnicians
            };
        });
    };

    // useEffect(() => {
    //    console.log('PipelineData',formData.Technicians); // Convert object to array
    // }, [formData]);

    const getCategoryValue1 = (type) => {
        if (formData.hasOwnProperty(type)) { // Check if the category exists in selectedOptions
            return formData[type]; // Return the value for the category
        }
    };
    const addItem = async (type, newItem) => {
        if (newItem) {
            setCountries_List((prevData) => [
                ...prevData,  // Preserve previous country objects
                { country: newItem } // Append new country as an object
            ]);

            const obj = {
                tableName: type,
                data: [{ country: newItem }]
            };

            setNewItem('');  // Clear input field
            //  console.log(obj);

            // Uncomment this to save data to the database
            await AddOrUpdateMasterData(obj);
        }
    };


    const setAdddnewItem = (event) => {
        setNewItem(event.target.value);
        // console.log(categoryType)
    };
    const removeItem = (e) => {
        e.preventDefault();
        setNewItem('');
    }

    const CashFlowAutomation = () => {
        return (formData &&
            <div style={{ padding: "0px", display: "flex", flexDirection: "column", gap: "0px" }} className='pipeline-modal trackrecord-modal date-picker'>
                {/* <header style={{ textAlign: "center" }}>
                    <h1 style={{ fontSize: "24px", fontWeight: "bold" }}>Cash Flow Automation</h1>
                    <p style={{ color: "gray" }}>Input details below to calculate cash flow</p>
                    <Progress percent={50} style={{ maxWidth: "600px", margin: "16px auto" }} />
                </header> */}
                <Form
                    name="project_form"
                    layout="vertical"
                    autoComplete="off"
                    getcontainer={false}
                    //onValuesChange={onValuesChange}
                    width={1200}
                    style={{ fontSize: '16px' }}
                >

                    <Tabs
                        className="custom-tab h-100" defaultActiveKey="1" tabPosition={'left'}
                        activeKey={activeKey}
                        onChange={onKeyChange}
                        style={{ borderRadius: '2px' }}
                    >
                        <TabPane tab="Project & Dates" key="1" >
                            <Row gutter={24}>
                                <Col span={24}>
                                    <Card className='Cashflow-custom-card'
                                        //scroll={{ y: 'calc(100vh - 400px)' }}

                                        headStyle={{ alignItems: 'start', display: 'flow', justifyContent: 'space-between', width: '100%', paddingTop: '10px' }}
                                        title={
                                            <div style={{ position: 'relative', display: 'flex', width: '100%' }}>
                                                <span style={{ marginLeft: '0px' }}>Project Details</span>
                                                {/* <Checkbox style={{ marginLeft: 'auto' }} checked={isArrayNotEmpty('PFM','CE')}
                                                            onChange={(e) => handleHeaderCheckboxChange('PFM', 'CE', e.target.checked, 'True')}>CE</Checkbox> */}
                                            </div>
                                        }
                                        style={{ height: "calc(100vh - 200px)", fontSize: '20px' }}>

                                        <Row gutter={24}>
                                            {/* Project Details Column */}
                                            <Col span={6}>
                                                <Form.Item label="Project Name" style={{ paddingTop: '0px' }} required>
                                                    <Input
                                                        style={{ borderRadius: '5px' }}
                                                        value={formData.Name}
                                                        onChange={(e) => handleInputChange("Name", e.target.value)}
                                                        size="large"
                                                        placeholder="Enter project name"
                                                    />
                                                </Form.Item>

                                            </Col>
                                            <Col span={6}>
                                                <Form.Item label="Location" style={{ paddingTop: '00px' }}>
                                                    <div>
                                                        <Select
                                                            className="bevel-select"
                                                            value={getCategoryValue1('Country')}
                                                            onChange={(value) => handleInputChange("Country", value)}
                                                            showSearch
                                                            size="large"
                                                            placeholder="Select location"
                                                            style={{ textAlign: 'left', borderRadius: '5px' }}

                                                            dropdownRender={menu => (
                                                                <>
                                                                    {menu}
                                                                    <Divider style={{ margin: '8px 0', borderTop: '1px solid lightgrey' }} />
                                                                    <Space style={{ display: 'flex', alignItems: 'center', padding: '0 8px 4px', width: '100%' }}>
                                                                        <Input
                                                                            style={{ width: '240px' }}
                                                                            placeholder="Add location"
                                                                            value={newItem}
                                                                            onChange={setAdddnewItem}
                                                                        />
                                                                        <div style={{ display: 'flex', gap: '5px' }}>
                                                                            <Typography.Link onClick={(e) => addItem('Countries_List', newItem)} style={{ color: "green" }}>
                                                                                <CheckOutlined />
                                                                            </Typography.Link>
                                                                            <Typography.Link onClick={removeItem} style={{ color: "red" }}>
                                                                                <CloseOutlined />
                                                                            </Typography.Link>
                                                                        </div>
                                                                    </Space>
                                                                </>
                                                            )}

                                                        >
                                                            {countries_List &&
                                                                countries_List
                                                                    // .sort((a, b) => a.country.localeCompare(b.country))
                                                                    .map((item, i) => (
                                                                        <Option key={i} value={item.country}>
                                                                            {item.country}
                                                                        </Option>
                                                                    ))}
                                                        </Select>
                                                    </div>
                                                </Form.Item>
                                            </Col>
                                            <Col span={6}>
                                                <Form.Item label="Number of Pipelines" required >
                                                    <Input
                                                        style={{ width: '100%', borderRadius: '5px' }}
                                                        type="number"
                                                        size="large"
                                                        min={1}
                                                        max={100}
                                                        placeholder="Enter pipeline count"
                                                        value={formData.Pipelines_count}
                                                        onChange={(e) => {
                                                            // handleInputChange("Pipelines_count", Number(e.target.value))
                                                            UpdatePipeline(Number(e.target.value))
                                                        }
                                                        }
                                                    />
                                                </Form.Item>
                                            </Col>
                                            <Col span={6}>
                                                <Form.Item label="Payment Terms" name="Payment_terms" required>
                                                    <div style={{ display: 'flex', alignItems: 'center' }}>
                                                        <Input
                                                            addonAfter="days"
                                                            className="Data360-addafter1"
                                                            onChange={(e) => handleInputChange('Payment_terms', Number(e.target.value))}
                                                            value={getCategoryValue1('Payment_terms')}
                                                            style={{ width: '100%', borderRadius: '5px' }}
                                                            min={0}
                                                            type="number"
                                                            size="large"
                                                        />
                                                    </div>
                                                </Form.Item>
                                            </Col>


                                            {['Expected_signing_date', 'Start_of_job', 'Leaves_port', "Arrives_port"].map((dateField) => (
                                                <Col span={6}>
                                                    <Form.Item
                                                        label={dateField.replace(/_/g, " ").replace(/\b\w/g, (char) => char.toUpperCase())}
                                                        key={dateField}
                                                        required={dateField == 'welding_wire_order' ? false : true}
                                                    >
                                                        <DatePicker
                                                            value={formData[dateField] ? moment(formData[dateField]) : null}
                                                            onChange={(date) => handleInputChange(dateField, date)}
                                                            format="DD-MMM-YYYY"
                                                            placeholder='DD-MM-YYYY'
                                                            style={{ width: "100%", borderRadius: '5px' }}
                                                            disabledDate={(current) => {
                                                                const signingDate = formData["Expected_signing_date"]
                                                                    ? moment(formData["Expected_signing_date"])
                                                                    : null;

                                                                if (!current) return true; // Disable invalid selections

                                                                // No restrictions for "Expected_signing_date"
                                                                if (dateField === "Expected_signing_date") {
                                                                    return false;
                                                                }

                                                                // Restrict "Production_start" and "welding_wire_order" to be >= "Expected_signing_date"
                                                                if (signingDate && (dateField === "Start_of_job" || dateField === 'Leaves_port')) {
                                                                    return current < signingDate;
                                                                }
                                                                else if (dateField === "Arrives_port" && formData["Leaves_port"]) {
                                                                    return current < moment(formData["Leaves_port"]);
                                                                }

                                                                return false; // Otherwise, allow selection
                                                            }}


                                                        />
                                                    </Form.Item>
                                                </Col>
                                            ))}


                                            {/* Pipeline & Contract Details Column */}


                                            {["Production_start", "Production_end"].map((dateField) => (
                                                <Col span={6}>
                                                    <Form.Item
                                                        label={dateField.replace(/_/g, " ").replace(/\b\w/g, (char) => char.toUpperCase())}
                                                        key={dateField}
                                                        required={dateField == 'Start_of_job' ? false : true}
                                                    >
                                                        <div>

                                                            <DatePicker className='date-picker'
                                                                value={formData[dateField] ? moment(formData[dateField]) : null}
                                                                onChange={(date) => handleInputChange(dateField, date)}
                                                                format="DD-MMM-YYYY"
                                                                placeholder='DD-MM-YYYY'
                                                                style={{ width: "100%", borderRadius: '5px' }}
                                                                allowClear={false}

                                                                disabledDate={(current) => {
                                                                    const signingDate = formData["Expected_signing_date"] ? moment(formData["Expected_signing_date"]) : null;

                                                                    if (!current) return true; // Disable invalid selections

                                                                    // Ensure no selection before "Expected_signing_date"
                                                                    if (signingDate && current < signingDate) {
                                                                        return true;
                                                                    }
                                                                    if (signingDate && (dateField === "Production_start")) {
                                                                        return current < signingDate;
                                                                    }
                                                                    else if (dateField === "Production_end" && formData["Production_start"]) {
                                                                        return current < moment(formData["Production_start"]);
                                                                    }



                                                                    return false; // Otherwise, allow selection
                                                                }}

                                                            />
                                                        </div>
                                                    </Form.Item>
                                                </Col>
                                            ))}



                                            {/* Project Dates Column */}

                                            {["welding_wire_order", 'Welding_wire_shipment'].map((dateField) => (
                                                <Col span={6}>
                                                    <Form.Item
                                                        label={dateField.replace(/_/g, " ").replace(/\b\w/g, (char) => char.toUpperCase())}
                                                        key={dateField}
                                                        required={dateField == 'welding_wire_order' ? false : true}
                                                    >
                                                        <DatePicker className='date-picker'
                                                            value={formData[dateField] ? moment(formData[dateField]) : null}
                                                            onChange={(date) => handleInputChange(dateField, date)}
                                                            format="DD-MMM-YYYY"
                                                            placeholder='DD-MM-YYYY'
                                                            style={{ width: "100%", borderRadius: '5px' }}
                                                            disabledDate={(current) => {

                                                                if (dateField === "welding_wire_order")
                                                                    return false
                                                                const signingDate = formData["Expected_signing_date"] ? moment(formData["Expected_signing_date"]) : null;

                                                                if (!current) return true; // Disable invalid selections

                                                                // Ensure no selection before "Expected_signing_date"
                                                                if (signingDate && current < signingDate) {
                                                                    return true;
                                                                }
                                                                // if (signingDate && (dateField === "welding_wire_order")) {
                                                                //     return current < signingDate;
                                                                // }
                                                                else if (dateField === "Welding_wire_shipment" && formData["welding_wire_order"]) {
                                                                    return current < moment(formData["welding_wire_order"]);
                                                                }



                                                                return false; // Otherwise, allow selection
                                                            }}
                                                        />
                                                    </Form.Item>
                                                </Col>
                                            ))}

                                            <Col span={6}>
                                                <Form.Item label="Commission" name="Commission" required>
                                                    <div style={{ display: 'flex', alignItems: 'center' }}>
                                                        <Input
                                                            addonAfter='%'
                                                            className='Data360-addafter1'
                                                            value={formData.Commission !== undefined ? Number((formData.Commission * 100).toFixed(2)) : ''}
                                                            onChange={(e) => {
                                                                const newValue = Number(e.target.value) / 100;
                                                                handleInputChange('Commission', parseFloat(newValue.toFixed(4))); // Store with 4 decimal places for precision
                                                            }}
                                                            min={1}
                                                            type='number'
                                                            style={{ width: '100%', textAlign: 'left', borderRadius: '5px' }}
                                                            size='large'
                                                            placeholder='Enter Commission'
                                                        />

                                                    </div>
                                                </Form.Item>
                                            </Col>
                                        </Row>

                                    </Card>
                                </Col>
                            </Row>
                        </TabPane>
                        <TabPane tab="Project Inputs" key="2">
                            <Card className='Cashflow-custom-card'
                                scroll={{ y: 'calc(100vh - 400px)' }}

                                headStyle={{ alignItems: 'start', display: 'flow', justifyContent: 'space-between', width: '100%', paddingTop: '10px' }}
                                title={
                                    <div style={{ position: 'relative', display: 'flex', width: '100%' }}>
                                        <span style={{ marginLeft: '0px' }}>Revenue & Cost Details</span>
                                        {/* <Checkbox style={{ marginLeft: 'auto' }} checked={isArrayNotEmpty('PFM','CE')}
                                                            onChange={(e) => handleHeaderCheckboxChange('PFM', 'CE', e.target.checked, 'True')}>CE</Checkbox> */}
                                    </div>
                                }
                                style={{ height: "calc(100vh - 200px)", fontSize: '20px' }}>
                                <div style={{ paddingLeft: '0px' }} >

                                    <ProjectsInputs
                                        setProjectDetails={setProjectDetails}
                                        projectDetails={projectDetails}
                                        inputData={inputData}
                                        setInputData={setInputData}
                                    // OnGenerateCashFlow={OnGenerateCashFlow}
                                    ></ProjectsInputs>

                                </div>
                            </Card>
                        </TabPane>


                        <TabPane tab="Payment Milestones" key="3">
                            <Card className='Cashflow-custom-card'
                                scroll={{ y: 'calc(100vh - 400px)' }}

                                headStyle={{ alignItems: 'start', display: 'flow', justifyContent: 'space-between', width: '100%', paddingTop: '10px' }}
                                title={
                                    <div style={{ display: 'flex', width: '100%' }}>
                                        <span style={{ marginLeft: '0px' }}>Payment Milestone Details</span>
                                        {/* <Checkbox style={{ marginLeft: 'auto' }} checked={isArrayNotEmpty('PFM','CE')}
                                                            onChange={(e) => handleHeaderCheckboxChange('PFM', 'CE', e.target.checked, 'True')}>CE</Checkbox> */}
                                    </div>
                                }
                                style={{ height: "calc(100vh - 200px)", fontSize: '20px' }}>
                                <div style={{ paddingLeft: '0px' }} >


                                    {GetInputRow()}
                                </div>
                            </Card>
                        </TabPane>
                        <TabPane tab="Technicians" key="4">
                            <Card className='Cashflow-custom-card'
                                scroll={{ y: 'calc(100vh - 400px)' }}

                                headStyle={{ alignItems: 'start', display: 'flow', justifyContent: 'space-between', width: '100%', paddingTop: '10px' }}
                                title={
                                    <div style={{ position: 'relative', display: 'flex', width: '100%' }}>
                                        <span style={{ marginLeft: '0px' }}>Technician Details</span>
                                        {/* <Checkbox style={{ marginLeft: 'auto' }} checked={isArrayNotEmpty('PFM','CE')}
                                                            onChange={(e) => handleHeaderCheckboxChange('PFM', 'CE', e.target.checked, 'True')}>CE</Checkbox> */}
                                    </div>
                                }
                                style={{ height: "calc(100vh - 200px)", fontSize: '20px' }}>

                                <TecniniciansPage
                                    formData={formData}
                                    setFormData={setFormData}
                                ></TecniniciansPage>
                            </Card>
                        </TabPane>
                        <TabPane tab="Others" key="5">


                            <Card className='Cashflow-custom-card'
                                scroll={{ y: 'calc(100vh - 400px)' }}

                                headStyle={{ alignItems: 'left', display: 'flow', justifyContent: 'space-between', width: '100%', paddingTop: '10px' }}
                                title={
                                    <div className='' style={{ position: 'relative', display: 'flex', width: '100%' }}>


                                        <span style={{ marginLeft: '0px' }}>Others Details</span>

                                    </div>
                                }
                                style={{ overflowY: "auto", overflowX: "hidden", height: "calc(100vh - 200px)", fontSize: '20px' }}>
                                {/* Performance Bond Section */}

                                <div>
                                    <Checkbox style={{ marginLeft: '0px', fontSize: '14px', display: 'flex' }} checked={formData.Performance_Bond?.Enabled == 'Yes' ? true : false || false}
                                        onChange={(e) => handleInputChange(`Performance_Bond.Enabled`, e.target.checked ? 'Yes' : 'No')}>Performance Bond</Checkbox>
                                    {formData.Performance_Bond?.Enabled == 'Yes' &&
                                        <div>
                                            <Row gutter={24} style={{ paddingLeft: '30px', paddingTop: '5px' }}>
                                                {/* <Col span={8}>
                                                    <Form.Item label="Name" required>
                                                        <Input
                                                            value={formData.Performance_Bond?.Performance_Bond_Text || ''}
                                                            onChange={(e) => handleInputChange(`Performance_Bond.Performance_Bond_Text`, e.target.value)}
                                                            size='large'
                                                            placeholder='Enter Performance Bond Name'
                                                            style={{ width: '100%', borderRadius: '5px' }}
                                                        />
                                                    </Form.Item>
                                                </Col> */}

                                                <Col span={8}>
                                                    <Row gutter={24} >

                                                        <Col span={10}>
                                                            <Form.Item label="At Signing">
                                                                <Radio.Group className='report-radio align-label-radio'
                                                                    value={formData.Performance_Bond?.At_signing}
                                                                    onChange={(e) => handleInputChange('Performance_Bond.At_signing', e.target.value)}>
                                                                    <Radio value={"Yes"} >Yes</Radio>
                                                                    <Radio value={"No"} >No</Radio>
                                                                </Radio.Group>
                                                            </Form.Item>
                                                        </Col>
                                                        <Col span={14}>
                                                            <Form.Item label="Invoice Date" required>
                                                                <div>
                                                                    <DatePicker
                                                                        value={formData.Performance_Bond?.Invoice_Date ? moment(formData.Performance_Bond?.Invoice_Date) : null}
                                                                        onChange={(date) => handleInputChange(`Performance_Bond.Invoice_Date`, date)}
                                                                        format="DD-MMM-YYYY"
                                                                        placeholder='DD-MM-YYYY'
                                                                        style={{ width: '100%', borderRadius: '5px' }}
                                                                        disabledDate={(current) => {
                                                                            const signingDate = formData["Expected_signing_date"] ? moment(formData["Expected_signing_date"]) : null;

                                                                            if (!current) return true; // Disable invalid selections

                                                                            // Ensure no selection before "Expected_signing_date"
                                                                            if (signingDate && current < signingDate) {
                                                                                return true;
                                                                            }



                                                                            return false; // Otherwise, allow selection
                                                                        }}
                                                                    />
                                                                </div>
                                                            </Form.Item>
                                                        </Col>
                                                    </Row>
                                                </Col>
                                                <Col span={8}>
                                                    <Form.Item label="Cost" style={{ paddingTop: '00px' }} required>
                                                        <Select
                                                            className="bevel-select"
                                                            value={formData.Performance_Bond?.Cost || null}
                                                            onChange={(value) => handleInputChange("Performance_Bond.Cost", value)}
                                                            showSearch
                                                            allowClear
                                                            size="large"
                                                            placeholder="Select Cost"
                                                            style={{ textAlign: 'left', borderRadius: '5px' }}

                                                        >
                                                            {['Financial', '100% Collateral']

                                                                .map((item, i) => (
                                                                    <Option key={i} value={item}>
                                                                        {item}
                                                                    </Option>
                                                                ))}
                                                        </Select>
                                                    </Form.Item>

                                                </Col>
                                                <Col span={8}>
                                                    <Form.Item label="Percentage" required>
                                                        <Input addonAfter='%' className='Data360-addafter1'
                                                            value={formData.Performance_Bond?.Percentage !== undefined ? Number((formData.Performance_Bond?.Percentage * 100).toFixed(2)) : ''}
                                                            onChange={(e) => {
                                                                const newValue = Number(e.target.value) / 100;
                                                                handleInputChange(`Performance_Bond.Percentage`, parseFloat(newValue.toFixed(4))) // Store with 4 decimal places for precision
                                                            }}
                                                            type='number'
                                                            size='large'
                                                            placeholder='Enter Percentage'
                                                            style={{ width: '100%', borderRadius: '5px' }}
                                                        />
                                                    </Form.Item>
                                                </Col>

                                            </Row>
                                            <Row gutter={24} style={{ paddingLeft: '30px' }}>
                                                <Col span={8}>
                                                    <Form.Item label="Techs Revenue Included">
                                                        <Radio.Group className='report-radio align-label-radio'
                                                            value={formData.Performance_Bond?.Techs_revenue_included}
                                                            onChange={(e) => handleInputChange('Performance_Bond.Techs_revenue_included', e.target.value)}>
                                                            <Radio value={"Yes"} >Yes</Radio>
                                                            <Radio value={"No"}>No</Radio>
                                                        </Radio.Group>
                                                    </Form.Item>
                                                </Col>


                                                <Col span={8}>
                                                    <Row gutter={24} >

                                                        <Col span={24}>
                                                            <Form.Item label="Money Back" style={{ paddingTop: '00px' }} required>
                                                                <Select
                                                                    className="bevel-select"
                                                                    value={formData.Performance_Bond?.Money_back || null}
                                                                    onChange={(value) => handleInputChange("Performance_Bond.Money_back", value)}
                                                                    showSearch
                                                                    allowClear
                                                                    size="large"
                                                                    placeholder="Select Money back"
                                                                    style={{ textAlign: 'left', borderRadius: '5px' }}

                                                                >
                                                                    {['At project end', 'At project end & No of months']

                                                                        .map((item, i) => (
                                                                            <Option key={i} value={item}>
                                                                                {item}
                                                                            </Option>
                                                                        ))}
                                                                </Select>
                                                            </Form.Item>
                                                        </Col>
                                                        {/* <Col span={8}>
                                                            {formData.Performance_Bond?.Money_back === 'At project end & No of months' &&
                                                                <Form.Item label="No. of Moths" name="Months" required>
                                                                    <div style={{ display: 'flex', alignItems: 'center' }}>
                                                                        <Input
                                                                            addonAfter="months"
                                                                            className="Data360-addafter1"
                                                                            onChange={(e) => handleInputChange('Performance_Bond.Months', Number(e.target.value))}
                                                                            value={formData.Performance_Bond?.Months || ''}
                                                                            style={{ width: '100%', borderRadius: '5px' }}
                                                                            min={0}
                                                                            placeholder='Enter month'
                                                                            type="number"
                                                                            size="large"
                                                                        />
                                                                    </div>
                                                                </Form.Item>}
                                                        </Col> */}
                                                    </Row>
                                                </Col>

                                                <Col span={8}>
                                                    {formData.Performance_Bond?.Money_back === 'At project end & No of months' &&
                                                        <Form.Item label="No. of Moths" name="Months" required>
                                                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                                                <Input
                                                                    addonAfter="months"
                                                                    className="Data360-addafter1"
                                                                    onChange={(e) => handleInputChange('Performance_Bond.Months', Number(e.target.value))}
                                                                    value={formData.Performance_Bond?.Months || ''}
                                                                    style={{ width: '100%', borderRadius: '5px' }}
                                                                    min={0}
                                                                    placeholder='Enter month'
                                                                    type="number"
                                                                    size="large"
                                                                />
                                                            </div>
                                                        </Form.Item>}
                                                </Col>
                                            </Row>

                                            <div className="custom-line" />
                                        </div>
                                    }
                                </div>
                                {/* Cash Collateral Section */}
                                <div>
                                    <Checkbox style={{ fontSize: '14px', display: 'flex', paddingTop: '10px' }} checked={formData.Cash_collateral?.Enabled == 'Yes' ? true : false || false}
                                        onChange={(e) => handleInputChange(`Cash_collateral.Enabled`, e.target.checked ? 'Yes' : 'No')}>Advance Payment Bond</Checkbox>
                                    {formData.Cash_collateral?.Enabled == 'Yes' &&
                                        <div style={{ paddingTop: '0px' }} >

                                            <Row gutter={24} style={{ paddingLeft: '30px', paddingTop: '5px' }}>
                                                {/* <Col span={8}>
                                                    <Form.Item label="Name" required>
                                                        <Input
                                                            value={formData.Cash_collateral?.Cash_collateral_Text || ''}
                                                            onChange={(e) => handleInputChange(`Cash_collateral.Cash_collateral_Text`, e.target.value)}
                                                            size='large'
                                                            placeholder='Enter Cash Collateral Name'
                                                            style={{ width: '100%', borderRadius: '5px' }}

                                                        />
                                                    </Form.Item>
                                                </Col> */}

                                                <Col span={8}>
                                                    <Row gutter={24} >

                                                        <Col span={10}>
                                                            <Form.Item label="At Signing">
                                                                <Radio.Group className='report-radio align-label-radio'
                                                                    value={formData.Cash_collateral?.At_signing}
                                                                    onChange={(e) => handleInputChange('Cash_collateral.At_signing', e.target.value)}>
                                                                    <Radio value={"Yes"} >Yes</Radio>
                                                                    <Radio value={"No"} >No</Radio>
                                                                </Radio.Group>
                                                            </Form.Item>
                                                        </Col>
                                                        <Col span={14}>
                                                            <Form.Item label="Invoice Date">
                                                                <div>
                                                                    <DatePicker
                                                                        value={formData.Cash_collateral?.Invoice_Date ? moment(formData.Cash_collateral?.Invoice_Date) : null}
                                                                        onChange={(date) => handleInputChange(`Cash_collateral.Invoice_Date`, date)}
                                                                        format="DD-MMM-YYYY"
                                                                        placeholder='DD-MM-YYYY'
                                                                        style={{ width: '100%', borderRadius: '5px' }}
                                                                        disabledDate={(current) => {
                                                                            const signingDate = formData["Expected_signing_date"] ? moment(formData["Expected_signing_date"]) : null;

                                                                            if (!current) return true; // Disable invalid selections

                                                                            // Ensure no selection before "Expected_signing_date"
                                                                            if (signingDate && current < signingDate) {
                                                                                return true;
                                                                            }




                                                                            return false; // Otherwise, allow selection
                                                                        }}
                                                                    />
                                                                </div>
                                                            </Form.Item>
                                                        </Col>
                                                    </Row>
                                                </Col>

                                                {/* <Col span={8}>
                                                <Form.Item label="Cost" style={{ paddingTop: '00px' }}>
                                                    <Select
                                                        className="bevel-select"
                                                        value={formData.Cash_collateral?.Cost || null}
                                                        onChange={(value) => handleInputChange("Cash_collateral.Cost", value)}
                                                        showSearch
                                                        allowClear
                                                        size="large"
                                                        placeholder="Select Cash Collateral Cost"
                                                        style={{ textAlign: 'left', borderRadius: '5px' }}

                                                    >
                                                        {['Financial', '100% Collateral']

                                                            .map((item, i) => (
                                                                <Option key={i} value={item}>
                                                                    {item}
                                                                </Option>
                                                            ))}
                                                    </Select>
                                                </Form.Item>

                                            </Col> */}
                                                <Col span={8}>
                                                    <Form.Item label="Money Back Date" required>
                                                        <div>
                                                            <DatePicker
                                                                value={formData.Cash_collateral?.Money_back_Date ? moment(formData.Cash_collateral?.Money_back_Date) : null}
                                                                onChange={(date) => handleInputChange(`Cash_collateral.Money_back_Date`, date)}
                                                                format="DD-MMM-YYYY"
                                                                placeholder='DD-MMM-YYYY'

                                                                style={{ width: '100%', borderRadius: '5px' }}
                                                            />
                                                        </div>
                                                    </Form.Item>
                                                </Col>

                                                <Col span={8}>
                                                    <Form.Item label="Percentage" required>
                                                        <Input addonAfter='%' className='Data360-addafter1'

                                                            value={formData.Cash_collateral?.Percentage !== undefined ? Number((formData.Cash_collateral?.Percentage * 100).toFixed(2)) : ''}
                                                            onChange={(e) => {
                                                                const newValue = Number(e.target.value) / 100;
                                                                handleInputChange(`Cash_collateral.Percentage`, parseFloat(newValue.toFixed(4))) // Store with 4 decimal places for precision
                                                            }}
                                                            type='number'
                                                            size='large'
                                                            placeholder='Enter Percentage'
                                                            style={{ width: '100%', borderRadius: '5px' }}
                                                        />
                                                    </Form.Item>
                                                </Col>

                                            </Row>
                                            <Row gutter={24} style={{ paddingLeft: '30px' }}>
                                                <Col span={8}>
                                                    <Form.Item label="Techs Revenue Included">
                                                        <Radio.Group className='report-radio align-label-radio'
                                                            value={formData.Cash_collateral?.Techs_revenue_included}
                                                            onChange={(e) => handleInputChange('Cash_collateral.Techs_revenue_included', e.target.value)}>
                                                            <Radio value={"Yes"} >Yes</Radio>
                                                            <Radio value={"No"}>No</Radio>
                                                        </Radio.Group>
                                                    </Form.Item>
                                                </Col>



                                            </Row>
                                            <div className="custom-line" />
                                        </div>}

                                </div>
                                {/* WHT Section */}
                                <div>
                                    <Checkbox style={{ fontSize: '14px', display: 'flex', paddingTop: '10px' }} checked={formData.WHT?.Enabled == 'Yes' ? true : false || false}
                                        onChange={(e) => handleInputChange(`WHT.Enabled`, e.target.checked ? 'Yes' : 'No')}>WHT</Checkbox>
                                    {formData.WHT?.Enabled == 'Yes' &&
                                        <div style={{ paddingTop: '00px' }} >

                                            <Row gutter={24} style={{ paddingLeft: '30px', paddingTop: '5px' }}>
                                                {/* <Col span={8}>
                                                    <Form.Item label="Name">
                                                        <Input
                                                            value={formData.WHT?.WHT_Text || ''}
                                                            onChange={(e) => handleInputChange(`WHT.WHT_Text`, e.target.value)}
                                                            size='large'
                                                            placeholder='Enter WHT Name'
                                                            style={{ width: '100%', borderRadius: '5px' }}
                                                        />
                                                    </Form.Item>
                                                </Col> */}
                                                <Col span={8}>
                                                    <Form.Item label="Rate" required>
                                                        <Input addonAfter='%' className='Data360-addafter1'

                                                            value={formData.WHT?.WHT_rate !== undefined ? Number((formData.WHT?.WHT_rate * 100).toFixed(2)) : ''}
                                                            onChange={(e) => {
                                                                const newValue = Number(e.target.value) / 100;
                                                                handleInputChange(`WHT.WHT_rate`, parseFloat(newValue.toFixed(4))) // Store with 4 decimal places for precision
                                                            }}
                                                            type='number'
                                                            size='large'
                                                            style={{ width: '100%', borderRadius: '5px' }}
                                                            placeholder='Enter WHT Rate'

                                                        />
                                                    </Form.Item>
                                                </Col>

                                            </Row>
                                        </div>
                                    }
                                </div>
                            </Card>

                        </TabPane>



                    </Tabs>
                </Form>
            </div >
        );
    }

    const onKeyChange = (key) => {
        setActiveKey(key)

        setCount(Number(key))
        if (key == '2')
            UpdateInputs()
    }

    const UpdateInputs = async () => {
        if (inputData.length > 0) {

            const count = formData['Pipelines_count']; // Desired number of pipelines
            //console.log('Pipelines Count:', count);

            let updatedData = inputData.map((item) => {
                let updatedItem = { ...item };

                // Filter & Remove extra pipeline columns
                Object.keys(updatedItem).forEach((key) => {
                    if ((key.startsWith("Revenue_") || key.startsWith("Cost_")) && parseInt(key.split("_")[1]) > count) {
                        delete updatedItem[key]; // Remove extra keys beyond `count`
                    }
                });

                // Add missing pipeline columns if necessary
                const existingPipelineCount = Object.keys(updatedItem).filter(key => key.startsWith("Revenue_")).length;
                for (let index = existingPipelineCount; index < count; index++) {
                    updatedItem[`Revenue_${index + 1}`] = item[`Revenue_1`] || 0;
                    updatedItem[`Cost_${index + 1}`] = item[`Cost_1`] || 0;
                }

                return updatedItem;
            });

            setInputData(updatedData);
        }
    };


    const handleOk = async (v) => {
        v++;
        if (v <= 5) {
            setCount(v)
            setActiveKey(String(v))
        }
        else {
            let pipeLineCount = Number(activeKeyLine) + 1
            if (pipeLineCount > 5)
                pipeLineCount = 1;
            //  console.log(pipeLineCount)
            setActiveKeyLine(String(pipeLineCount))
            setActiveKey('1')
            setCount(1)
        }
        //console.log(v, activeKey)
    };


    const validateForm = async () => {
        // Define display names for specific fields
        const displayNames = {
            "Cash_collateral_Text": "Cash Collateral Name",
            "Performance_Bond_Text": "Performance Bond Name",
            "WHT_Text": "WHT Name",
            Milestone_Text: 'Milestone Name',
            Senior_technicians: 'Senior technicians hours',
            Lead_technicians: 'Lead technicians hours'
        };
        const skipVlaidation = ['welding_wire_order', 'Leaves_port', 'Arrives_port', 'Country']

        for (const key in formData) {

            if (key === 'Report_Url' || skipVlaidation.includes(key) || typeof formData[key] === "object" && formData[key]?.Enabled === "No") {
                continue; // Skip validation if "Enabled" is "No"
            }

            if (key === "Payment_Milestones") {
                // Validate dynamic Payment Milestones
                for (const milestoneKey in formData[key]) {
                    const milestone = formData[key][milestoneKey];

                    for (const subKey in milestone) {
                        if (milestone[subKey] === "" || milestone[subKey] === null) {
                            const fieldName = displayNames[subKey] || subKey; // Use friendly name if available
                            const msg = `Please enter ${fieldName} in Payment Milestone ${milestoneKey} to proceed!`;

                            notification.warning({
                                message: msg.replace(/_/g, " ").replace(/\b\w/g, (char) => char.toUpperCase())
                            });

                            return false;
                        }
                    }
                }
            } else if (key === "Technicians") {
                // Validate dynamic Technicians pipelines
                for (const pipelineKey in formData[key]) {
                    const pipeline = formData[key][pipelineKey];

                    for (const subKey in pipeline) {
                        if (typeof pipeline[subKey] === "object") {
                            // Check nested objects (Senior_technicians, Lead_technicians, Welding_crews)
                            for (const field in pipeline[subKey]) {
                                const value = pipeline[subKey][field];
                                if (value === "" || value === null || value === undefined || value === 0) {
                                    const fieldName = displayNames[field] || field; // Use friendly name if available
                                    const msg = `Please enter a valid ${fieldName} in ${subKey}.${pipelineKey} to proceed!`;

                                    notification.warning({
                                        message: msg.replace(/_/g, " ").replace(/\b\w/g, (char) => char.toUpperCase()),
                                    });

                                    return false;
                                }
                            }
                        }
                    }
                }
            }

            else if (typeof formData[key] === "object") {
                for (const subKey in formData[key]) {
                    if (subKey !== "Enabled" && (formData[key][subKey] === "" || formData[key][subKey] === null)) {
                        const fieldName = displayNames[subKey] || subKey;
                        const msg = `Please enter ${fieldName} in ${key} to proceed!`;

                        notification.warning({
                            message: msg.replace(/_/g, " ").replace(/\b\w/g, (char) => char.toUpperCase())
                        });

                        return false;
                    }
                }
            } else {
                if (["Start_of_job", "Leaves_port", "Arrives_port"].includes(key)) continue;

                if (formData[key] === "" || formData[key] === null || formData[key] === 0) {
                    const fieldName = displayNames[key] || key;
                    const msg = `Please enter ${fieldName} to proceed!`;

                    notification.warning({
                        message: msg.replace(/_/g, " ").replace(/\b\w/g, (char) => char.toUpperCase())
                    });

                    return false;
                }
            }
        }
        await OnGenerateCashFlow()

        return true;
    };



    return (

        <div className="h-100 overflow-y-hidden overflow-x-hidden">
            {CashFlowAutomation()}

            <div style={{ paddingTop: '15px' }} className='Generate-btn'>

                {/* <Button className="cancel-btn" type="default"
                    onClick={() => {

                        history.push('/CashFlow')
                    }
                    }
                >
                    Cancel
                </Button> */}


                {
                    <Button key="apply" type="primary" className='submit-btn'
                        onClick={() => {
                            validateForm()
                            // setShowPipelineModel(false)
                            // history.push('/SparePartsGenerator')
                        }
                        }
                        loading={isGenerateLoading}

                    >
                        {'Generate Cash flow'}
                    </Button>
                }

                {
                    <Button key="apply" type="primary" className='submit-btn'
                        onClick={() => handleOk(count)}

                    >
                        Next
                    </Button>
                }




            </div>

        </div>

    );


};

export default ProjectDetailsGrid