/* eslint-disable linebreak-style */

import { ArrowLeftOutlined, EditOutlined, EyeOutlined, DeleteOutlined } from '@ant-design/icons';
import { Button, DatePicker, Form, Input, Modal, Space, Table, Tooltip, Typography } from 'antd';
import { get } from 'lodash';
import React, { useState, useRef, useMemo, useEffect, useCallback } from 'react';
import { connect } from 'react-redux';
import history from '../../services/history.js';

import moment from 'moment';
import ProjectModal from './ProjectModal.js';
import ProjectsTaps from './ProjectsTaps.js';

import 'ag-grid-enterprise';
import { LicenseManager } from 'ag-grid-enterprise';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-quartz.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import 'ag-grid-community/styles/ag-theme-balham.css';
import 'ag-grid-community/styles/ag-theme-material.css';
//ag grid enterprise
import { ClientSideRowModelModule } from "@ag-grid-community/client-side-row-model";
import { ModuleRegistry } from "@ag-grid-community/core";
import { ColumnsToolPanelModule } from "@ag-grid-enterprise/column-tool-panel";
import { FiltersToolPanelModule } from "@ag-grid-enterprise/filter-tool-panel";
import { MenuModule } from "@ag-grid-enterprise/menu";
import { SetFilterModule } from "@ag-grid-enterprise/set-filter";
import { StatusBarModule } from "@ag-grid-enterprise/status-bar";
import { RowGroupingModule } from "@ag-grid-enterprise/row-grouping";
import { MultiFilterModule } from "@ag-grid-enterprise/multi-filter";
import { fetchCashflowList } from '../../redux/EMEAA/action.js';
import { DeleteDetails, getListFromDatabase } from '../../redux/EMEAA/saga.js';
import { CustomLoadingCellRenderer } from './Uitiles.js';

ModuleRegistry.registerModules([
    ClientSideRowModelModule,
    ColumnsToolPanelModule,
    FiltersToolPanelModule,
    MenuModule,
    SetFilterModule,
    StatusBarModule,
    RowGroupingModule,
    MultiFilterModule
]);

LicenseManager.setLicenseKey(process.env.REACT_APP_AG_GRID_ENTERPRISE_LICENSE)


const { Title, Text } = Typography;


const CashFlowMainPage = (props) => {


    const {
        onFetchRawList, rawDataList, isRawListLoading

    } = props
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [isEdit, setIsEdit] = useState(false);
    const [editRecord, seteditRecord] = useState('');
    const [projectDetailForm] = Form.useForm()
    const [showPipelineModel, setShowPipelineModel] = useState(false)
    const [projectDetails, setProjectDetails] = useState(null);

    const [theme, setTheme] = useState('ag-theme-material-dark'); // Default theme
    const gridRef = useRef();
    const [columnDefs, setColumnDefs] = useState([])
    const [layoutSave, setLayoutSave] = useState(false);
    const [layoutLoad, setLayoutLoad] = useState(false);
    const [isPivotMode, setIsPivotMode] = useState(false);
    const [rowData, setRowData] = useState([])
    const [gridApi, setGridApi] = useState(null);
    const [loadingProgress, setLoadingProgress] = useState(0);
    const [pageSize, setPageSize] = useState(100000);
    const [pageNumber, setPageNumber] = useState(1);
    const [loadingData, setloadingData] = useState(false);
    const [selectModel, setSelectModel] = useState([]);


    const [isDeleteModalVisible, setIsDeleteModalVisible] = useState(false);
    const [selectedData, setSelectedData] = useState('');
    const [isDeleteLoading, setIsDeleteLoading] = useState(false)


    const coloumList = {
        Name: "Project Name",
        Country: 'Country',
        Expected_signing_date: 'Expected Signing Date',
        Production_start: "Production Start",
        Production_end: 'Production End'

    }
    useEffect(() => {
        generateColumns(coloumList)

    }, [])
    useEffect(() => {
        if (rawDataList.length == 0) {
            onFetchRawList({ tableName: 'Project_Details', pageNumber: pageNumber, pageSize: pageSize });
        }
    }, [])
    useEffect(() => {
        if (projectDetails) {
            onFetchRawList({ tableName: 'Project_Details', pageNumber: pageNumber, pageSize: pageSize });
        }
    }, [projectDetails])

   


    const generateColumns = async (columnList) => {
        const columnDefs = Object.keys(columnList)
            .map((key, index) => ({
                field: key,
                headerName: columnList[key],

                valueFormatter: (params) => {
                    // Check if the column is a date column
                    const isDateColumn = ['Production_start', 'Production_end', 'Expected_signing_date'].includes(key);

                    if (isDateColumn) {
                        // Validate and format the date
                        const date = moment(params.value); // Parse date with moment.js
                        return date.isValid() ? date.format('DD-MMM-YYYY') : ''; // Format if valid, empty if invalid
                    }

                    // else {

                    //     const isDateColumn1 = ['Amount_USD', 'Amount_in_doc_curr', 'Amount_in_local_cur'].includes(key);

                    //     if (isDateColumn1) {
                    //         return Math.floor(Number(params.value)).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
                    //     }
                    // }
                    // Return value for non-date columns
                    return params.value;
                },


                cellStyle: {

                    textAlign: ['Amount_USD', 'Amount_in_doc_curr', 'Amount_in_local_cur'].includes(key) ? 'right' : 'left'

                }, // Cell alignment
                //editable: true, // Enable editing for cells
                //filter: 'agMultiColumnFilter', // Add filtering

                headerClass: {
                    display: 'flex',
                    justifyContent: 'center', // Center header content
                    alignItems: 'center', // Center vertically
                    textAlign: 'center', // Text alignment
                },
            }));


        // Add the actions column at the end
        columnDefs.push({
            headerName: 'Actions',
            field: 'action',
            pinned: 'right',
            cellRenderer: (params) => {
                const record = params.data;
                return (
                    <div>

                        {/* <Tooltip title="View Parts" >
                            <span className="icon-18 mr-2 cursor-pointer edit-hover" style={{ marginRight: '20px', }} >
                                <EyeOutlined style={{ fontSize: '18px' }}
                                    onClick={() => onViewClick(record)}
                                />
                            </span>
                        </Tooltip> */}

                        <Tooltip title="Update">
                            <span className="icon-18 mr-2 cursor-pointer edit-hover">
                                <EditOutlined style={{ paddingRight: '0px' }}
                                    onClick={() => onUpdateClick(record)}
                                />
                            </span>
                        </Tooltip>
                        <Tooltip title="Delete">
                            <span className="icon-18 mr-2 cursor-pointer edit-hover" style={{ marginLeft: '20px' }}>
                                <DeleteOutlined style={{ fontSize: '18px' }}
                                    onClick={() => showDeleteModal(record)}
                                />
                            </span>
                        </Tooltip>

                    </div>
                );
            },
            cellStyle: { textAlign: 'center' },
            filter: false, // Disable filtering for the Actions column
        });

        // Debugging output for generated columns
        console.log(columnList, columnDefs);

        // Set the column definitions in state
        setColumnDefs(columnDefs);
        return columnDefs;
    };


    // useEffect(() => {
    //     if (rawDataList.length == 0) {
    //         onFetchRawList({ tableName: 'ZPAL_GLOBAL', pageNumber: pageNumber, pageSize: pageSize });

    //     }
    // }, [])
    const fetchData = async (data) => {
        if (data.length > 0) {

            //generateColumns(data[0]);
            setRowData(data);
            setLayoutLoad(true);
            setLoadingProgress(100);
        }
    };

    /** Deleting Spare parts data on confirm */
    const handleDeleteOk = async () => {
        {
            setIsDeleteLoading(true)
            const obj = {
                columnName: 'Project_Details',
                tableName: 'Project_Details',
                data: selectedData
            };
            console.log(obj);
            await DeleteDetails(obj)


            setSelectedData('');
            onFetchRawList({ tableName: 'Project_Details', pageNumber: pageNumber, pageSize: pageSize });
            setIsDeleteLoading(false)
            setIsDeleteModalVisible(false);
        }

    }
    /** Display Confirmation while deleting */
    const showDeleteModal = (record) => {
        setIsDeleteModalVisible(true);
        console.log(record)
        setSelectedData(record)

    };


    /** Cancelling the deletion of spare parts data */
    const handleDeleteCancel = () => {
        setIsDeleteModalVisible(false);
    }



    const onUpdateClick = (record) => {


        projectDetailForm.setFieldsValue(record)
        setProjectDetails(record)
        //console.log(record)
        seteditRecord(record)
        setIsEdit(true)
        
        setShowPipelineModel(true)
        setIsModalVisible(true)

    }

    const onViewClick = (record) => {

        setIsEdit(true)
        setProjectDetails(record)
        //console.log(record)
        seteditRecord(record)
       
        setShowPipelineModel(true)
    }



    useEffect(() => {
        console.log('rawDataList updated:', rawDataList.length);

        if (rawDataList)
            fetchData(rawDataList);
    }, [rawDataList]);


    const onRowDragEnd = (event) => {
        const { node, overNode } = event;
        if (!overNode) return;

        const draggedRow = node.data;
        const fromIndex = node.rowIndex;
        const toIndex = overNode.rowIndex;

        if (fromIndex === toIndex) return;

        const newRowData = [...rowData];
        newRowData.splice(fromIndex, 1);
        newRowData.splice(toIndex, 0, draggedRow);

        setRowData(newRowData);
    };

    // useEffect(() => {
    //     if (layoutSave && gridRef) {
    //         (async () => {
    //             let layouts = {}
    //             await saveGridLayout(gridRef, currentTab, layouts, setLayoutSave);
    //         })();

    //     }


    // }, [layoutSave])

    // useEffect(() => {
    //     if (layoutLoad && gridRef) {
    //         (async () => {
    //             await restoreGridLayout(gridRef, currentTab, setIsPivotMode);
    //             setLayoutLoad(false)
    //         })();


    //     }
    // }, [layoutLoad])



    const gridStyle = useMemo(() => ({ height: "99%", width: "100%" }), []);

    const defaultColDef = useMemo(() => {
        return {
            flex: 1,
            minWidth: 200,
            floatingFilter: true,
            resizable: true,
            enableValue: true,
            enableRowGroup: true,
            enablePivot: true,
            filter: true,
        };
    }, []);

    const activeKeyLine = 'Cash Flow'
    const defaultExcelExportParams = useMemo(() => {
        return {
            fileName: `${activeKeyLine}.xlsx`, // Default file name
            allColumns: true, // Include all columns by default
            onlySelected: false, // Include all rows, not just selected ones
            sheetName: "Sheet1", // Default sheet name
        };
    }, [activeKeyLine]);
    const defaultCsvExportParams = useMemo(() => {
        return {
            fileName: `${activeKeyLine}.csv`, // Default file name
            allColumns: true, // Include all columns by default
            onlySelected: false, // Include all rows, not just selected ones
        };
    }, [activeKeyLine]);

    const rowSelection = useMemo(() => {
        return {
            mode: 'singleRow',
            checkboxes: false,
            enableClickSelection: true,
        };
    }, []);

    const onGridReady = useCallback((params) => {
        setGridApi(params.api)
        console.log("params", params.api)
        if (isRawListLoading) {
            params.api.showLoadingOverlay();
        } else {
            params.api.hideOverlay();
        }
    }, [isRawListLoading]);



    // useEffect(() => {
    //     if (gridRef.current) {
    //         if (loadingData) {
    //             gridRef.current.api.showLoadingOverlay();
    //         } else {
    //             gridRef.current.api.hideOverlay();
    //         }
    //     }
    // }, [loadingData])

    //

    useEffect(() => {
        if (isRawListLoading) {
            setLoadingProgress(0);

            const interval = setInterval(() => {
                setLoadingProgress((prevProgress) => {
                    const nextProgress = prevProgress + 10;
                    if (nextProgress >= 100) {
                        clearInterval(interval);
                        return 100;
                    }
                    return nextProgress;
                });
            }, 500);

            return () => clearInterval(interval); // cleanup on unmount
        }
    }, [isRawListLoading]);

    useEffect(() => {
        if (isRawListLoading && gridRef.current) {
            // Update the overlay with the current progress
            const loadingOverlayTemplate = `
                <div class="custom-loading-overlay">
                    <span>Loading... ${loadingProgress}%</span>
                </div>
            `;
            // gridRef.current.api.showLoadingOverlay();
            // gridRef.current.api.setOverlayLoadingTemplate(loadingOverlayTemplate);
        }
    }, [loadingProgress, isRawListLoading]);

    // Custom status panel component
    // const CustomStatusPanel = ({ refreshInfo }) => {
    //     return <div style={{ paddingTop: '20px' }}>{refreshInfo}</div>;
    // };

    const statusBar = {
        statusPanels: [
            {
                statusPanel: 'agTotalRowCountComponent',
                align: 'left',
            },
            {
                statusPanel: 'agSelectedRowCountComponent',
                align: 'left',
            },
            {
                statusPanel: 'agFilteredRowCountComponent',
                align: 'left',
            },
            // {
            //     statusPanel: () => <CustomStatusPanel refreshInfo={refreshInfo} />, // React component
            //     align: 'right',
            // },
        ],
    };
    const autoGroupColumnDef = useMemo(() => {
        return {
            minWidth: 200,
            pinned: "left",
        };
    }, []);

    const onSelectionChanged = () => {
        const selectedRows = gridRef.current.api.getSelectedRows();
        console.log('Selected Row Details:', selectedRows);
        setSelectModel(selectedRows)
        // You can do further processing with selectedRows here, e.g., updating state
    };

    function onCellDoubleClicked(event) {

        console.log(
            event
        );
        if(event)
            onViewClick(event.data)

    }



    const getPipelineTabpanes = () => {

        return (
            <div className="custom-tab h-100" style={{ paddingTop: '0px' }}>

                <div style={{ height: 'calc(100vh - 120px)', overflow: 'auto', position: 'relative' }}>
                    <div className={`ag-grid ${theme} custom-grid-theme`} style={gridStyle}>
                        <AgGridReact
                            ref={gridRef}
                            rowData={rowData}
                            columnDefs={columnDefs}
                            rowDragManaged={true}
                            onRowDragEnd={onRowDragEnd}
                            defaultColDef={defaultColDef}
                            rowSelection={rowSelection}
                            onGridReady={onGridReady}
                            sideBar={false}
                            pivotMode={isPivotMode}
                            //
                            pivotPanelShow={"always"}
                            autoGroupColumnDef={autoGroupColumnDef}
                            animateRows={true}
                            onSelectionChanged={onSelectionChanged}
                            // enableFilter={true}
                            statusBar={statusBar}
                            rowHeight={40}
                            headerHeight={50}
                            defaultExcelExportParams={defaultExcelExportParams}
                            defaultCsvExportParams={defaultCsvExportParams}
                            loadingOverlayComponent={CustomLoadingCellRenderer}
                            loadingOverlayComponentParams={{
                                loadingMessage: "Loading all rows...",
                            }}
                            onCellDoubleClicked={onCellDoubleClicked}
                        />
                    </div>
                </div>

            </div>
        );
    };


    return (
        <div className="d-flex flex-column h-100">

            {!showPipelineModel &&
                <div className="flex-auto">

                    <div className="flex-auto">
                        <div className="d-flex mb-3 align-items-center tab-content-title">


                            <div className='back_equip' >
                                <Tooltip title={'Back'} >
                                    <ArrowLeftOutlined onClick={(e) => {
                                        history.push('/EMEAA');
                                    }} />
                                </Tooltip>
                            </div>

                            <Title className="mb-0 add-new-calibration" level={3} style={{ paddingLeft: '20px', paddingTop: '5px' }}>
                                Cash Flow
                            </Title>

                            <div className="new-calibration">
                                <Text onClick={() => {
                                   setIsEdit(false); 
                                    setProjectDetails(null)
                                    setShowPipelineModel(true);
                                    setIsModalVisible(true)

                                }
                                } className="ps-3 add-new-calibration" style={{}}>
                                    <span className="icon-22" style={{ scale: '20px' }}>
                                        <svg>
                                            <use xlinkHref="#add" />
                                        </svg>
                                    </span>
                                    Generate New Cashflow Report
                                </Text>

                            </div>


                        </div>


                        <div className="custom-line" />


                        {getPipelineTabpanes()}



                    </div>


                </div>
            }

            {/* {isModalVisible &&
                <ProjectModal
                    isModalVisible={isModalVisible}
                    setIsModalVisible={setIsModalVisible}

                    projectDetailForm={projectDetailForm}
                    isEdit={isEdit}
                    setIsEdit={setIsEdit}
                    editRecord={editRecord}
                    setShowPipelineModel={setShowPipelineModel}
                    setProjectDetails={setProjectDetails}

                />
            } */}
            {showPipelineModel &&
                <ProjectsTaps
                    setShowPipelineModel={setShowPipelineModel}
                    showPipelineModel={showPipelineModel}

                    setProjectDetails={setProjectDetails}
                    projectDetails={projectDetails}
                    isEdit={isEdit}
                    setIsEdit={setIsEdit}
                    editRecord={editRecord}
                    projectDetailForm={projectDetailForm}

                >
                </ProjectsTaps>

            }
            <Modal
                width="45vw"
                className="pipeline-modal"
                title="Delete Details"
                centered
                visible={isDeleteModalVisible}
                footer={[
                    <Button key="cancel" onClick={handleDeleteCancel}>
                        Cancel
                    </Button>,
                    <Button key="apply" type="primary" onClick={handleDeleteOk} loading={isDeleteLoading}>
                        {isDeleteLoading ? 'Deleting' : 'Delete'}
                    </Button>
                ]}
            >
                <div>
                    <Text style={{ fontSize: '16px' }}>
                        <p>Deleting all the corresponding and related information.</p>
                        Are you sure you want to delete  ?</Text>
                </div>
            </Modal>


        </div>

    )
};
const mapStateToProps = ({ EMEAAMappingReducer }) => {
    const isRawListLoading = get(EMEAAMappingReducer, 'isFetchLoading', false);
    const tableName = get(EMEAAMappingReducer, 'tableName', '');
    const rawDataList = get(EMEAAMappingReducer, `rawDataList`, []); // Fetch specific table data
    return {
        isRawListLoading,
        rawDataList,
    }
}

const mapDispatchToProps = {
    onFetchRawList: fetchCashflowList,
}

export default connect(mapStateToProps, mapDispatchToProps)(CashFlowMainPage);
