/* eslint-disable linebreak-style */

import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { get, set } from 'lodash';
import { Typography, Table, Tooltip, Button, Input, DatePicker, Row, Col, Form, Select, Image, Avatar, Badge } from 'antd';
import { FilePdfOutlined, EditOutlined, ShareAltOutlined, ArrowLeftOutlined, RightCircleTwoTone, DownCircleTwoTone } from '@ant-design/icons'
import GenerateLicenseForm from '../components/LicenseForm.js';
import moment from 'moment';
import ShareLicenseForm from '../components/ShareLicenseForm.js';
import { fetchGroupLicenseList } from '../redux/LicenseManager/action.js';
import history from '../services/history.js';
import DownloadForm from '../components/DownloadForm.js';
import { authenticateCurrentUser } from '../services/auth.js';

const { Option } = Select;
const { Title, Text } = Typography;

const GenerateLicense = (props) => {

    const {
        licenseData,
        isLicenseLoading,
        isUploadLicsense,
        licenseTotalCount,
        onFetchLicenseData
    } = props
    const [licenseForm] = Form.useForm()
    const [shareForm] = Form.useForm()
    const [generateLiceseVisible, setGenerateLiceseVisible] = useState(false);
    const [showShareModel, setShowShareModel] = useState(false);
    const [searchText, setSearchText] = useState('')
    const [expiryDateSearch, setExpiryDateSearch] = useState('');
    const [paginationClear, setPaginationClear] = useState(false);
    const [editRecord, seteditRecord] = useState('');
    const [isEdit, setIsEdit] = useState(false);
    const [showDownloadModel, setShowDownloadModel] = useState(false);
    const [downloadDataList, setDownloadDataList] = useState([]);
    const [uniqueIdData, setuniqueIdData] = useState([]);
    const [licenseUniqueIdList, setLicenseUniqueIdList] = useState([]);
    const [currentPageNumber, setCurrentPageNumber] = useState(1);
    const [currentPagesize, setCurrentPagesize] = useState(10);
    const [perpetualDate, setPerpetualDate] = useState(null);
    const [selectedSearchBy, setSelectedSearchBy] = useState('')
    const statuslist = ['Identifier', 'Unique ID', 'Machine Type', 'Location', 'Customer', "Feature Type", 'Expiration Date', "Create Date"]
    const [userName, setUserName] = useState('');
    const [userGroup, setUserGroup] = useState([]);
    /** get user Access */
    useEffect(() => {
        authenticateCurrentUser()
            .then(async (data) => {
                if (data.signInUserSession.accessToken.payload["cognito:groups"]) {

                    let response = data.signInUserSession.accessToken.payload["cognito:groups"] //await getA(data.username)
                    //const userAccess = response.map(item => item?.GroupName)
                    //console.log(response)
                    //setUserName(data.username);
                    setUserGroup(response);
                }
            })
            .catch((error) => {
                console.log('error', error);
            });
    }, [history]);
    useEffect(() => {
        if (showShareModel)
            return;
        var obj = {
            pageSize: 10,
            pageNumber: 1
        }

        onFetchLicenseData(obj);
        // console.log(licenseData)
    }, []);


    useEffect(() => {

        if (showShareModel)
            return;
        var obj = {
            pageSize: currentPagesize,
            pageNumber: currentPageNumber,
            searchKey: searchText
        }

        onFetchLicenseData(obj)
        //console.log(searchText)
    }, [searchText]);

    useEffect(() => {
        if (!isEdit) {
            seteditRecord('')
            licenseForm.resetFields();
        }
    }, [isEdit])

    const featureAvatarMap = {
        "Basic Function": { label: "B", color: "#F3E5F5", textColor: "#6A1B9A", tooltip: "Basic Function" },
        "Solid Wire": { label: "S", color: "#E3F2FD", textColor: "#1565C0", tooltip: "Solid Wire" },
        "Horizontal Tracking": { label: "H", color: "#E8F5E9", textColor: "#2E7D32", tooltip: "Horizontal Tracking" },
        "Fronius PS": { label: "F", color: "#FFF3E0", textColor: "#E65100", tooltip: "Fronius PS" },
        "Lincoln PS": { label: "L", color: "#FFEBEE", textColor: "#C62828", tooltip: "Laser PS" }
    };
    
    const featureP625AvatarMap = {
        "Basic Function": { label: "B", color: "#E3F2FD", textColor: "#1565C0", tooltip: "Basic Function" },
        "360 Degree Welding": { label: "W", color: "#E8EAF6", textColor: "#283593", tooltip: "360 Degree Welding" },
        "Zone to Zone Ramping": { label: "R", color: "#E8F5E9", textColor: "#2E7D32", tooltip: "Zone to Zone Ramping" },
        "Touch and Ramp": { label: "T", color: "#FCE4EC", textColor: "#880E4F", tooltip: "Touch and Ramp" },
        "Fronius TPSi": { label: "F", color: "#E1F5FE", textColor: "#0277BD", tooltip: "Fronius TPSi" }
    };
    
    const MachineAvatarMap = {
        "M500": { label: "M500", color: "#DCEDC8", textColor: "#33691E", tooltip: "M500" },
        "P625": { label: "P625", color: "#FFECB3", textColor: "#FF8F00", tooltip: "P625" }
    };
    

    const columns = [

        {
            title: 'Unique Id',
            dataIndex: 'Unique_Id',
            key: 'Unique_Id',
            align: 'left',
            width: '250px',
            // sorter: (a, b) => a.Unique_Id.localeCompare(b.Unique_Id),
            render: (text) => <a>{text}</a>,
        },



        // {
        //     title: 'User Identifier',
        //     dataIndex: 'License_File_Name',
        //     key: 'License_File_Name',
        //     filterSearch: true,
        //     width: '150px',
        //     sorter: (a, b) =>  (a.License_File_Name.localeCompare(b.License_File_Name)),
        //     render: (text) => <a style={{ paddingLeft: '2px' }}>{text}</a>,
        // },
        {
            title: 'Machine Type',
            dataIndex: 'Type_Of_Device',
            key: 'Type_Of_Device',
            align: 'left',
            width: '10%',
            // sorter: (a, b) => a.Type_Of_Device.localeCompare(b.Type_Of_Device)
            render: (text, record) => {

                const avatarData = MachineAvatarMap[record.Type_Of_Device];
                if (!avatarData) {
                    return null;
                }

                
                return (

                    <Tooltip title={avatarData.tooltip} key={text}>

                        <Avatar
                            style={{
                                backgroundColor: avatarData.color, width: '100px',   // Rectangle width
                                height: '30px',   // Rectangle height
                                borderRadius: '5px',  // Rounded corners
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                fontSize: '14px', // Adjust text size
                                fontWeight: 'bold',
                                color:avatarData.textColor
                            }}
                            shape='square'

                        >
                            {avatarData.label}
                        </Avatar>

                    </Tooltip>
                )
            }

        },
        {
            title: 'Board Type',
            dataIndex: 'Board_Type',
            key: 'Board_Type',
            align: 'left',
            width: '20%'
            // sorter: (a, b) => (a.Board_Type.localeCompare(b.Board_Type))
        },
        {
            title: 'Feature Type',
            dataIndex: 'Feature_Type',
            key: 'Feature_Type',
            align: 'left',
            render: (text, record) => {
                console.log(record);

                // Ensure LicenceInfo_History exists and is an array
                if (!record?.LicenceInfo_data || !Array.isArray(record.LicenceInfo_data)) {
                    return null;
                }
                
               

                return (
                    <div>
                        <Avatar.Group>                              
                            {record.LicenceInfo_data.map((item, index) => {
                            const featureType = item.Feature_Type;
                            const avatarData = record.Type_Of_Device == 'M500' ? featureAvatarMap[featureType] : featureP625AvatarMap[featureType];

                            return (
                                avatarData && (

                                    <Tooltip title={avatarData.tooltip} key={index}>

                                        <Avatar className="member-avatar"
                                            // style={{ backgroundColor: avatarData.color,  }}
                                            onClick={() => showdownloaddModal(item)}
                                            style={{ 
                                                backgroundColor: avatarData.color, 
                                                color: avatarData.textColor, 
                                                fontWeight: "bold" 
                                            }}
                                            
                                        >
                                            {avatarData.label}
                                        </Avatar>

                                    </Tooltip>
                                )
                            );
                        })}
                        </Avatar.Group>
 
                    </div>
                );
            }
        }


        // {
        //     title: 'Customer Name',
        //     dataIndex: 'Customer_Name',
        //     key: 'Customer_Name',
        //     align: 'center',
        //     render: (text) => <div style={{  placeContent: 'center' }}>{text ? text : '-'}</div>,
        //     sorter: (a, b) =>  (a.Customer_Name.localeCompare(b.Customer_Name))
        // },
        // {
        //     title: 'Machine Serial Number',
        //     dataIndex: 'Serial_Number',
        //     key: 'Serial_Number',
        //     align: 'center',
        //     width: '160px',
        //     render: (text) => <div style={{ placeContent: 'center' }}>{text ? text : '-'}</div>,
        //     sorter: (a, b) =>  (a.Serial_Number.localeCompare(b.Serial_Number))
        // },
        // {
        //     title: 'Create Date',
        //     dataIndex: 'File_Creation_Date',
        //     key: 'File_Creation_Date',
        //     align: 'center',
        //     render: (text) => {
        //         return (
        //             <div>{text ? moment(text).format('DD MMM YYYY') : '-'}</div>
        //         );
        //     },
        //     sorter: (a, b) => moment(a.File_Creation_Date).valueOf() - moment(b.File_Creation_Date).valueOf()
        // },
        // {
        //     title: 'Expiration Date',
        //     dataIndex: 'Expiration_Date',
        //     key: 'Expiration_Date',
        //     align: 'center',
        //     render: (text) => {
        //         return (
        //             <div>{text ? moment(text).format('DD MMM YYYY') : '-'}</div>
        //         );
        //     },
        //     // sorter: (a, b) => a.Expiration_Date - b.Expiration_Date,
        //     sorter: (a, b) => moment(a.Expiration_Date).valueOf() - moment(b.Expiration_Date).valueOf()
        // },

        // {
        //     title: 'Edit / Download',
        //     dataIndex: 'File_Link',
        //     key: 'File_Link',
        //     render: (text, record) => {
        //         return (
        //             <>
        //                 <Tooltip title="Update">
        //                     <span className="icon-18 mr-2 cursor-pointer edit-hover"
        //                     // style={{ cursor: 'not-allowed' }}
        //                     >
        //                         <EditOutlined style={{ fontSize: '18px' }}
        //                             onClick={() => {
        //                                 showModal(record);
        //                             }}
        //                         />
        //                     </span>
        //                 </Tooltip>


        //               {
        //               get(record, 'LicenceInfo_History', []).length > 1?

        //                 <Tooltip title="Download license file" >
        //                     <span className="icon-24 mr-2 cursor-pointer edit-hover"  >

        //                         <FilePdfOutlined onClick={() => {

        //                             showdownloaddModal(record);
        //                         }} />

        //                     </span>
        //                     <span className="icon-24 mr-2 cursor-pointer edit-hover"  >({get(record, 'LicenceInfo_History', []).length})</span>
        //                 </Tooltip> :
        //                 <Tooltip title="Download license file" >

        //                     <span className="icon-24 mr-2 cursor-pointer edit-hover"  >
        //                         <Button href={get(record, 'LicenceInfo_History', [])[0].File_Link} className='p-0 border-0' size='small' target='_blank'>
        //                             <FilePdfOutlined>sella</FilePdfOutlined>
        //                         </Button>
        //                     </span>

        //                 </Tooltip>
        //     }

        //             </>
        //         )
        //     },
        //     align: 'center'
        // },

    ];
    const columns_Feture_type = [
        {
            title: 'Feature Type',
            dataIndex: 'Feature_Type',
            key: 'Feature_Type',
            filterSearch: true,
            render: (text) => <a style={{ paddingLeft: '0px' }}>{text}</a>,
        },
        {
            title: 'Details',
            dataIndex: 'LicenceInfo_History_data',
            key: 'LicenceInfo_History_data',
            render: (text, record) => {
                return (
                    <>



                        <Tooltip title="Details" >
                            <span className="icon-24 mr-2 cursor-pointer edit-hover"  >

                                <FilePdfOutlined onClick={() => {

                                    showdownloaddModal(record);
                                }} />

                            </span>
                            <span className="icon-24 mr-2 cursor-pointer edit-hover"  >({getlengthOfData(record)})</span>
                        </Tooltip>
                        {/* <Tooltip title="Download license file" >

                            <span className="icon-24 mr-2 cursor-pointer edit-hover"  >
                                <Button className='p-0 border-0' size='small' target='_blank'>

                                </Button>
                            </span>

                        </Tooltip> */}


                    </>
                )
            },
            align: 'center'
        },
    ];
    const getlengthOfData = (record) => {
        const item = get(record, 'LicenceInfo_History', [])

        return item ? item.length : 0
    }
    const columns_feild = [

        // {
        //     title: 'Version',
        //     dataIndex: 'version',
        //     key: 'version',
        //     filterSearch: true,
        //     render: (text) => <a style={{ paddingLeft: '2px' }}>{text}</a>,
        // },

        {
            title: 'Feature Type',
            dataIndex: 'Feature_Type',
            key: 'Feature_Type',
            filterSearch: true,
            render: (text) => <a style={{ paddingLeft: '0px' }}>{text}</a>,
        },

        {
            title: 'User Identifier',
            dataIndex: 'License_File_Name',
            key: 'License_File_Name',
            filterSearch: true,
            width: '150px',
            sorter: (a, b) => (a.License_File_Name.localeCompare(b.License_File_Name)),
            render: (text) => <a style={{ paddingLeft: '2px' }}>{text}</a>,
        },
        {
            title: 'Customer Name',
            dataIndex: 'Customer_Name',
            key: 'Customer_Name',
            align: 'center',
            render: (text) => <div style={{ placeContent: 'center' }}>{text ? text : '-'}</div>,
            sorter: (a, b) => (a.Customer_Name.localeCompare(b.Customer_Name))
        },
        {
            title: 'Machine Serial Number',
            dataIndex: 'Serial_Number',
            key: 'Serial_Number',
            align: 'center',
            width: '160px',
            render: (text) => <div style={{ placeContent: 'center' }}>{text ? text : '-'}</div>,
            sorter: (a, b) => (a.Serial_Number.localeCompare(b.Serial_Number))
        },
        {
            title: 'Created Date',
            dataIndex: 'File_Creation_Date',
            key: 'File_Creation_Date',
            align: 'center',
            render: (text) => {
                return (
                    <div>{text ? moment(text).format('DD MMM YYYY') : '-'}</div>
                );
            },
            //sorter: (a, b) => moment(a.File_Creation_Date).valueOf() - moment(b.File_Creation_Date).valueOf()
        },
        {
            title: 'Expiration Date',
            dataIndex: 'Expiration_Date',
            key: 'Expiration_Date',
            align: 'center',
            render: (text) => {
                return (
                    <div>{text ? moment(text).format('DD MMM YYYY') : '-'}</div>
                );
            },
            // sorter: (a, b) => a.Expiration_Date - b.Expiration_Date,
            // sorter: (a, b) => moment(a.Expiration_Date).valueOf() - moment(b.Expiration_Date).valueOf()
        },
        {
            title: 'Edit / Download',
            dataIndex: 'File_Link',
            key: 'File_Link',
            render: (text, record) => {
                return (
                    <>
                        <Tooltip title="Update">
                            <span className="icon-18 mr-2 cursor-pointer edit-hover"
                            // style={{ cursor: 'not-allowed' }}
                            >
                                <EditOutlined style={{ fontSize: '18px' }}
                                    onClick={() => {
                                        showdownloaddModal(record);
                                    }}
                                />
                            </span>
                        </Tooltip>




                    </>
                )
            },
            align: 'center'
        },
    ];


    const showModal = (record) => {
        let editData = null;
        if (record != null) {

            const name = record.License_File_Name.split('_')
            editData = {
                uniqueId: record.Unique_Id,
                fileName: name[0],

                featureType: record.Feature_Type,
                application_type: record.Type_Of_Device,
                customerName: record.Customer_Name,
                serialNumber: record.Serial_Number,
                location: record.Location,
                board_type: record.Board_Type,
                Board_Serial_Number: record.Board_Serial_Number ?? ''
            };
            licenseForm.setFieldsValue(editData)
            setIsEdit(true)
            seteditRecord(record)
            setGenerateLiceseVisible(true)
        }
    }
    const showdownloaddModal = (record) => {

        if (record != null) {
            //   const val = get(record, 'LicenceInfo_History', []);
            setDownloadDataList(record.LicenceInfo_History);
            setShowDownloadModel(true);
        }
    }

    const onSearchLicense = async (event) => {
        //console.log(event)
        if (event.target.value) {
            setSearchText(event.target.value);
        }
        else
            setSearchText('')
    }
    const handleDateSelect = async (date, dateString) => {
        if (date)
            setSearchText(moment(date).format('YYYY-MM-DD'))
        else
            setSearchText('')
    }
    const handleCreatedDateSelect = async (date, dateString) => {
        if (date)
            setSearchText(moment(date).format('YYYY-MM-DD'))
        else
            setSearchText('')
    }
    const handleLicenseVisibe = () => {

        setGenerateLiceseVisible(true)
    }

    const onshowShareLicenseFrom = () => {
        shareForm.resetFields();
        setuniqueIdData([])
        setLicenseUniqueIdList([])
        setShowShareModel(true)
    }
    useEffect(() => {
        if (!showShareModel) {
            setLicenseUniqueIdList([])
        }
    }, [showShareModel])
    // const columns_feild = [

    //     {
    //         title: 'Version',
    //         dataIndex: 'version',
    //         key: 'version',
    //         filterSearch: true,
    //         render: (text) => <a style={{ paddingLeft: '2px' }}>{text}</a>,
    //     },

    //     {
    //         title: 'Feature Type',
    //         dataIndex: 'Feature_Type',
    //         key: 'Feature_Type',
    //         filterSearch: true,
    //         render: (text) => <a style={{ paddingLeft: '0px' }}>{text}</a>,
    //     },
    //     {
    //         title: 'Created Date',
    //         dataIndex: 'File_Creation_Date',
    //         key: 'File_Creation_Date',
    //         align: 'center',
    //         render: (text) => {
    //             return (
    //                 <div>{text ? moment(text).format('DD MMM YYYY') : '-'}</div>
    //             );
    //         },
    //         //sorter: (a, b) => moment(a.File_Creation_Date).valueOf() - moment(b.File_Creation_Date).valueOf()
    //     },
    //     {
    //         title: 'Expiration Date',
    //         dataIndex: 'Expiration_Date',
    //         key: 'Expiration_Date',
    //         align: 'center',
    //         render: (text) => {
    //             return (
    //                 <div>{text ? moment(text).format('DD MMM YYYY') : '-'}</div>
    //             );
    //         },
    //         // sorter: (a, b) => a.Expiration_Date - b.Expiration_Date,
    //         // sorter: (a, b) => moment(a.Expiration_Date).valueOf() - moment(b.Expiration_Date).valueOf()
    //     },
    // ];
    const expandedRowRender = (record) => {
        // Customize the content inside the expanded row (nested group)

        return (
            record.LicenceInfo_data ?
                <div className='addTeamDetails3'>
                    <Table showHeader={true}
                        className="h-100 pipeline-table equip_table equipPaginationTable"

                        rowKey={'id'}
                        columns={columns_Feture_type} // Exclude the first two columns (Category and Subcategory)
                        dataSource={[...record.LicenceInfo_data]}
                        pagination={false}
                        rowClassName={(nestedRecord, nestedIndex) =>
                            nestedIndex % 2 === 0 ? 'even-row' : 'odd-row'
                        }
                    />
                </div>
                : null
        );
    };
    const rowClassName = (record, index) => {
        return index % 2 === 0 ? 'even-row' : 'odd-row';
    };
    const handlePerpetualDateSelect = async (date, dateString) => {
        if (date)
            setPerpetualDate(moment(date).format('YYYY-MM-DD'))
        else
            setPerpetualDate('')
    }
    return (
        // <div className="d-flex flex-column h-100">
        <div className="flex-auto">
            <div className="d-flex mb-3 align-items-center tab-content-title">


                <div className='back_equip' >
                    <Tooltip title={'Back'} >
                        <ArrowLeftOutlined onClick={() => {
                            history.push('/SmartLync-HomePage');
                        }} />
                    </Tooltip>
                </div>

                <Title className="mb-0 add-new-calibration" level={3} style={{ paddingLeft: '30px' }}>
                    License Manager
                </Title>


                <div className="new-calibration">
                    {/* {userGroup &&  userGroup.includes('license-manager-admin') &&
                        <>
                            <Text style={{ marginTop: '10px', fontSize: '15px', marginRight: '10px' }}> Perpetual </Text>
                            <DatePicker className='date-picker'
                                allowClear={true}
                                style={{ height: '35px', border: 'line', width: '25%', marginLeft: '0px' }}
                                format="DD-MM-YYYY"
                                placeholder='Select date'
                                onChange={(date, dateString) => handlePerpetualDateSelect(date, dateString)}
                                bordered={true}
                                disabledDate={
                                    current => !current
                                        || current < moment().subtract(1, "days")

                                        || current > moment('2128-01-01', 'YYYY-MM-DD')

                                }
                            />
                        </>
                    } */}

                    <Tooltip title="Share license">

                        {/* <Button size='large' onClick={() => onshowShareLicenseFrom()} className="ps-3 add-new-calibration" >
                            <ShareAltOutlined style={{ fontSize: '18px' }} />
                            Share License
                        </Button> */}


                        <Button shape="round" key="apply" type="primary" onClick={() => onshowShareLicenseFrom()} style={{ marginLeft: '10px' }}>
                            <ShareAltOutlined style={{ fontSize: '20px' }}> </ShareAltOutlined>  Share License
                        </Button>

                    </Tooltip>
                    <Text onClick={() => handleLicenseVisibe()} className="ps-3 add-new-calibration"   >
                        <span className="icon-22">
                            <svg>
                                <use xlinkHref="#add" />
                            </svg>
                        </span>
                        Generate License
                    </Text>
                </div>
            </div>
            <div className="custom-line" />
            <div style={{ paddingTop: '20px' }}>

                <Row gutter={2} style={{ paddingTop: "0px", width: '100%' }} >
                    <Col lg={3}>
                        <div className="searchEquip">
                            <Input
                                placeholder='Search By User Identifier'
                                //placeholder={`Search By ${selectedSearchBy}`}
                                onChange={onSearchLicense}
                                allowClear 
                                style={{ borderRadius: '5px' }}
                            />
                        </div>

                    </Col>
                    <Col lg={3}>
                        <div className="searchEquip">
                            <Input
                                placeholder='Search By Unique ID'
                                onChange={onSearchLicense}
                                allowClear
                                style={{ borderRadius: '5px' }}
                            />
                        </div>
                    </Col>
                    <Col lg={3}>
                        <div className="searchEquip">
                            <Input
                                //style={{ width: '190px' }}
                                placeholder='Search By Machine Type'
                                onChange={onSearchLicense}
                                allowClear
                                style={{ borderRadius: '5px' }}
                            />
                        </div>
                    </Col>
                    <Col lg={3}>
                        <div className="searchEquip">
                            <Input
                                //style={{ width: '190px' }}
                                placeholder='Search By Location'
                                onChange={onSearchLicense}
                                allowClear
                                style={{ borderRadius: '5px' }}
                            />
                        </div>
                    </Col>

                    <Col lg={3}>
                        <div className="searchEquip">
                            <Input
                                //style={{ width: '190px' }}
                                placeholder='Search By Customer'
                                onChange={onSearchLicense}
                                allowClear
                                style={{ borderRadius: '5px' }}
                            />
                        </div>
                    </Col>
                    <Col lg={3}>
                        <div className="searchEquip">
                            <Input
                                //style={{ width: '190px' }}
                                placeholder='Search By Feature Type'
                                onChange={onSearchLicense}
                                allowClear
                                style={{ borderRadius: '5px' }}
                            />
                        </div>
                    </Col>

                    <Col lg={3}>

                        <div className='date-picker'>
                            <DatePicker
                                allowClear={true}
                                style={{ height: '28px', border: 'line' ,borderRadius: '5px' }}
                                format="DD-MM-YYYY"
                                placeholder='Search By Create Date'
                                onChange={(date, dateString) => handleCreatedDateSelect(date, dateString)}
                                bordered={true}
                            />
                        </div>
                    </Col>
                    <Col lg={3}>
                        <div className='date-picker'>
                            <DatePicker
                                allowClear={true}
                                style={{ height: '28px' , border: 'line' ,borderRadius: '5px'  }}
                                format="DD-MM-YYYY"
                                placeholder='Search By Expiration Date'
                                onChange={(date, dateString) => handleDateSelect(date, dateString)}
                            />
                        </div>
                    </Col>
                </Row>
            </div>
            <div className='addTeamDetailsLicense'>
                <Table columns={columns}

                    className="h-100 pipeline-table equip_table equipPaginationTable dashBoard-table"
                    scroll={{ y: 'calc(100vh - 230px)' }}
                    // expandable={{
                    //     expandedRowRender,
                    //     className: 'h-100 pipeline-table equip_table equipPaginationTable',
                    //     expandIcon: ({ expanded, onExpand, record }) =>
                    //         expanded ? (
                    //             <DownCircleTwoTone twoToneColor={'transparent'} style={{ fontSize: '150%' }} onClick={(e) => onExpand(record, e)} />
                    //         ) : (
                    //             <RightCircleTwoTone twoToneColor={'transparent'} style={{ fontSize: '150%' }} onClick={(e) => onExpand(record, e)} />
                    //         ),
                    // }}
                    //rowClassName={rowClassName}
                    rowClassName={(record, index) => (index % 2 === 0 ? 'even-row' : 'odd-row')}
                    rowKey={'id'}
                    pagination={
                        licenseTotalCount > 10 ? {
                            position: ['bottomRight'],
                            className: 'fixed-pagination',
                            showSizeChanger: true,
                            total: licenseTotalCount,
                            defaultPageSize: 10,
                            defaultCurrent: 1,

                            onChange: async (pageNumber, pageSize) => {
                                // console.log(licenseTotalCount, pageNumber, pageSize)
                                setCurrentPageNumber(pageNumber);
                                setCurrentPagesize(pageSize);
                                var obj = {
                                    pageSize: pageSize,
                                    pageNumber: pageNumber,
                                    searchKey: searchText
                                }
                                await onFetchLicenseData(obj)

                            }
                        }
                            : false
                    }


                    dataSource={[...licenseData]}
                    loading={isLicenseLoading}
                />
            </div>
            {
                generateLiceseVisible &&
                <GenerateLicenseForm
                    setGenerateLiceseVisible={setGenerateLiceseVisible}
                    generateLiceseVisible={generateLiceseVisible}
                    record={editRecord}
                    licenseForm={licenseForm}
                    isEdit={isEdit}
                    setIsEdit={setIsEdit}
                    setPerpetualDate={setPerpetualDate}
                    perpetualDate={perpetualDate}
                />
            }
            <div>
                {
                    showShareModel && <ShareLicenseForm

                        setShowShareModel={setShowShareModel}
                        showShareModel={showShareModel}
                        shareForm={shareForm}
                        uniqueIdData={uniqueIdData}
                        setuniqueIdData={setuniqueIdData}
                        licenseUniqueIdList={licenseUniqueIdList}
                        setLicenseUniqueIdList={setLicenseUniqueIdList}
                    >

                    </ShareLicenseForm>
                }
            </div>
            <div>
                {
                    showDownloadModel && <DownloadForm

                        setShowDownloadModel={setShowDownloadModel}
                        showDownloadModel={showDownloadModel}
                        downloadDataList={downloadDataList}
                    >

                    </DownloadForm>
                }
            </div>

        </div >
        // </div>
    )
};
const mapStateToProps = ({ licenseMangerReducer }) => {
    const licenseData = get(licenseMangerReducer, 'LicenseData', []);
    const isLicenseLoading = get(licenseMangerReducer, 'isLicenseLoading', false);
    //const isUploadLicsense = get(licenseMangerReducer, 'isUploadLicsense', false);
    const licenseTotalCount = get(licenseMangerReducer, 'LicenseListCount', null);
    return {
        licenseData,
        isLicenseLoading,
        // isUploadLicsense,
        licenseTotalCount
    }
}

const mapDispatchToProps = {
    onFetchLicenseData: fetchGroupLicenseList,

}

export default connect(mapStateToProps, mapDispatchToProps)(GenerateLicense);
