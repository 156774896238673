import React, { useState, useRef, useMemo, useEffect, useCallback } from 'react';
import { Pagination, Spin, Typography, Tooltip, Tabs } from 'antd';
import { Modal, Form, Input, Select, Checkbox, Button, Row, Col, Radio, notification, DatePicker, Divider, Space } from 'antd';
import { ArrowLeftOutlined, CloudUploadOutlined, DeleteOutlined, EditOutlined, CheckOutlined, CloseOutlined, FullscreenOutlined, ShrinkOutlined } from '@ant-design/icons';
import 'ag-grid-enterprise';
import { LicenseManager } from 'ag-grid-enterprise';
import { AgGridReact } from 'ag-grid-react';
import * as XLSX from 'xlsx';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-quartz.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import 'ag-grid-community/styles/ag-theme-balham.css';
import 'ag-grid-community/styles/ag-theme-material.css';


import get from 'lodash/get';
import debounce from 'lodash/debounce';
import { connect } from 'react-redux';
import history from '../../services/history.js';
//ag grid enterprise
import { ClientSideRowModelModule } from "@ag-grid-community/client-side-row-model";
import { ModuleRegistry } from "@ag-grid-community/core";
import { ColumnsToolPanelModule } from "@ag-grid-enterprise/column-tool-panel";
import { FiltersToolPanelModule } from "@ag-grid-enterprise/filter-tool-panel";
import { MenuModule } from "@ag-grid-enterprise/menu";
import { SetFilterModule } from "@ag-grid-enterprise/set-filter";
import { StatusBarModule } from "@ag-grid-enterprise/status-bar";
import { RowGroupingModule } from "@ag-grid-enterprise/row-grouping";
import { MultiFilterModule } from "@ag-grid-enterprise/multi-filter";
import moment from 'moment';
import { filter } from 'lodash';

ModuleRegistry.registerModules([
    ClientSideRowModelModule,
    ColumnsToolPanelModule,
    FiltersToolPanelModule,
    MenuModule,
    SetFilterModule,
    StatusBarModule,
    RowGroupingModule,
    MultiFilterModule
]);

LicenseManager.setLicenseKey(process.env.REACT_APP_AG_GRID_ENTERPRISE_LICENSE)

const { Option } = Select;
const { Title, Text } = Typography;
const { TabPane } = Tabs;
const TecniniciansPage = (props) => {

    const { formData, setFormData } = props;

    const [theme, setTheme] = useState('ag-theme-material-dark'); // Default theme
    const gridRef = useRef();
    const [currentSection, setCurrentSection] = useState('1');
    const [rowData, setRowData] = useState(null);
    const [pageSize, setPageSize] = useState(100000);
    const [pageNumber, setPageNumber] = useState(1);
    const [selectModel, setSelectModel] = useState([]);
    const [gridApi, setGridApi] = useState(null);
    const [loadingProgress, setLoadingProgress] = useState(0);
    const [columnDefs, setColumnDefs] = useState([])
    const [layoutSave, setLayoutSave] = useState(false);
    const [layoutLoad, setLayoutLoad] = useState(false);
    const [isPivotMode, setIsPivotMode] = useState(false);
    const [isRawListLoading, setisRawListLoading] = useState(false);


    const [activeKeyLine, setActiveKeyLine] = useState('INPUT')
    const [loading, setloading] = useState(false);
    const [data_List, setData_List] = useState([]);
    const [refreshInfo, setRefreshInfo] = useState(null);
    const [loadingData, setloadingData] = useState(false);
    const [cashReceipts, setCashReceipts] = useState([])
    const [expenditureData, setExpenditureData] = useState([])
    const [newItem, setNewItem] = useState('');




    useEffect(() => {
        console.log(formData.Technicians)
        setRowData(Object.values(formData.Technicians)); // Convert object to array for AG Grid
        console.log(formData.Technicians)
        generateInputColumns()
        //UpdatePipeline(formData.Pipelines_count)
    }, [])

    useEffect(() => {
        setRowData(Object.values(formData.Technicians)); // Convert object to array for AG Grid
        console.log(formData)
    }, [formData]);

    const valueFormatter = (params) => {
        if (!params.value) return "$0"; // Handle empty values
        const formattedValue = Math.floor(Number(params.value))
            .toString()
            .replace(/\B(?=(\d{3})+(?!\d))/g, ',');
        return `$${formattedValue}`;
    };
    


    const generateInputColumns = () => {
        const columnDefs = [
            {
                headerName: "Pipeline",
                field: "Pipeline_Number",
                //width: 50,
                minWidth:50,
                editable: false, // Prevent user from editing
                filter: false,
                enablePivot: false,
                suppressMenu: true

            },
            {
                headerName: "Senior Technicians",
                children: [
                    { field: "Senior_technicians.Quantity", headerName: "Quantity", width: 80, editable: true, filter: false , suppressMenu: true},
                    {
                        field: "Senior_technicians.Day_rate", headerName: "Rate/Day", width: 100, editable: true, filter: false, suppressMenu: true,

                        valueFormatter: valueFormatter


                    },
                    { field: "Senior_technicians.Day_revenue", headerName: "Revenue/Day", width: 160, editable: true, filter: false, valueFormatter: valueFormatter, suppressMenu: true },
                ],
            },
            {
                headerName: "Lead Technicians",
                children: [
                    { field: "Lead_technicians.Quantity", headerName: "Quantity", width: 80, editable: true, filter: false , suppressMenu: true},
                    { field: "Lead_technicians.Day_rate", headerName: "Rate/Day", width: 100, editable: true, filter: false, valueFormatter: valueFormatter , suppressMenu: true},
                    { field: "Lead_technicians.Day_revenue", headerName: "Revenue/Day", width: 160, editable: true, filter: false, valueFormatter: valueFormatter, suppressMenu: true },
                ],
            },
            {
                headerName: "Welding Crews",
                children: [
                    { field: "Welding_crews.Quantity", headerName: "Quantity", width: 80, editable: true, filter: false, suppressMenu: true },
                    { field: "Welding_crews.Day_rate", headerName: "Rate/Day", width: 100, editable: true, filter: false, valueFormatter: valueFormatter, suppressMenu: true },
                    { field: "Welding_crews.Day_revenue", headerName: "Revenue/Day", width: 160, editable: true, filter: false, valueFormatter: valueFormatter, suppressMenu: true },
                ],
            },
        ];

        setColumnDefs(columnDefs);
        return columnDefs;
    };






    const onRowDragEnd = (event) => {
        const { node, overNode } = event;
        if (!overNode) return;

        const draggedRow = node.data;
        const fromIndex = node.rowIndex;
        const toIndex = overNode.rowIndex;

        if (fromIndex === toIndex) return;

        const newRowData = [...rowData];
        newRowData.splice(fromIndex, 1);
        newRowData.splice(toIndex, 0, draggedRow);

        setRowData(newRowData);
    };





    const gridStyle = useMemo(() => ({ height: "99%", width: "100%" }), []);

    const defaultColDef = useMemo(() => {
        return {
            flex: 1,
            minWidth: 50,
           // floatingFilter: true,
            resizable: true,
            enableValue: false,
            enableRowGroup: false,
            enablePivot: false,
            filter: false,
            sortable: false,
            editable: true,
            
            
        };
    }, []);




    const rowSelection = useMemo(() => {
        return {
            mode: 'singleRow',
            checkboxes: false,
            enableClickSelection: false,
        };
    }, []);

    const onGridReady = useCallback((params) => {
        console.log("params", params.api)
        setGridApi(params.api)
        console.log("params", params.api)
        if (loadingData) {
            params.api.showLoadingOverlay();
        } else {
            params.api.hideOverlay();
        }
    }, [loadingData, activeKeyLine]);



    // useEffect(() => {
    //     //console.log(activeKeyLine)
    //     if (gridRef.current) {
    //         console.log(activeKeyLine)
    //         if (loadingData) {
    //             gridRef.current.api.showLoadingOverlay();
    //         } else {
    //             gridRef.current.api.hideOverlay();
    //         }
    //     }
    // }, [loadingData, activeKeyLine])

    //

    useEffect(() => {
        if (loadingData) {
            setLoadingProgress(0);

            const interval = setInterval(() => {
                setLoadingProgress((prevProgress) => {
                    const nextProgress = prevProgress + 10;
                    if (nextProgress >= 100) {
                        clearInterval(interval);
                        return 100;
                    }
                    return nextProgress;
                });
            }, 500);

            return () => clearInterval(interval); // cleanup on unmount
        }
    }, [isRawListLoading]);

    useEffect(() => {
        if (loadingData && gridRef.current) {
            // Update the overlay with the current progress
            const loadingOverlayTemplate = `
                <div class="custom-loading-overlay">
                    <span>Loading... ${loadingProgress}%</span>
                </div>
            `;
            // gridRef.current.api.showLoadingOverlay();
            // gridRef.current.api.setOverlayLoadingTemplate(loadingOverlayTemplate);
        }
    }, [loadingProgress, isRawListLoading]);





    const statusBar = {
        statusPanels: [
            {
                statusPanel: 'agTotalRowCountComponent',
                align: 'left',
            },
            {
                statusPanel: 'agSelectedRowCountComponent',
                align: 'left',
            },
            {
                statusPanel: 'agFilteredRowCountComponent',
                align: 'left',
            },

        ],
    };
    const autoGroupColumnDef = useMemo(() => {
        return {
            minWidth: 200,
            pinned: "left",
        };
    }, []);




    const onSelectionChanged = () => {
        const selectedRows = gridRef.current.api.getSelectedRows();
       // console.log('Selected Row Details:', selectedRows);
        setSelectModel(selectedRows)
        // You can do further processing with selectedRows here, e.g., updating state
    };

    const onChange = (key) => {
        setCurrentSection(key);
    };




    const onCellValueChanged = (params) => {
        const { colDef, newValue, data } = params;
        let field = colDef.field;

        setFormData((prevData) => {
            const updatedTechnicians = { ...prevData.Technicians };

            // Convert value to number if applicable
            const parsedValue = !isNaN(newValue) && newValue !== "" ? Number(newValue) : newValue;
            const fieldParts = field.split(".");
            if (fieldParts.length === 2) {
                const [category, key] = fieldParts;

                // Ensure we update the correct pipeline
                updatedTechnicians[`Pipeline_${data.Pipeline_Number}`] = {
                    ...updatedTechnicians[`Pipeline_${data.Pipeline_Number}`],
                    [category]: {
                        ...updatedTechnicians[`Pipeline_${data.Pipeline_Number}`][category],
                        [key]: parsedValue,
                    },
                };
            }

            const updatedFormData = {
                ...prevData,
                Technicians: updatedTechnicians,
            };

            return updatedFormData;
        });
    };

    // // ✅ Ensure AG Grid updates when `formData` changes
    // useEffect(() => {
    //     setRowData(Object.values(formData.Technicians)); // Convert object to array
    // }, [formData]);






    /** Deleting Spare parts data on confirm */

    const gridOptions = {
        suppressMovableColumns: true, // Prevent column reordering
        suppressMenuHide: true, // Hide menu options
        suppressSorting: true, // Disable sorting globally
        suppressFilterRow: true, // Disable filter rows
        enablePivot: false, // Disable pivot mode
        suppressDragLeaveHidesColumns: true, // Prevent dragging columns out
        rowSelection: "none", // Disable row selection
        pagination: false, // Remove pagination if needed
    };




    return (

        <div>


            <div style={{ height: 'calc(100vh - 270px)', overflow: 'auto', position: 'relative' }}>
                <div className={`ag-grid ${theme} custom-grid-theme`} style={gridStyle}>
                    <AgGridReact
                        ref={gridRef}
                        rowData={rowData}
                        columnDefs={columnDefs}
                        rowDragManaged={false}
                        onRowDragEnd={onRowDragEnd}
                        defaultColDef={defaultColDef}
                        rowSelection={rowSelection}
                        onGridReady={onGridReady}
                        sideBar={false}
                        hiddenByDefault={true}
                        pivotMode={false}
                        //
                        pivotPanelShow={"never"}
                        autoGroupColumnDef={autoGroupColumnDef}
                        animateRows={true}
                        onSelectionChanged={onSelectionChanged}
                        // enableFilter={true}
                        // statusBar={statusBar}
                        rowHeight={40}
                        headerHeight={50}
                        gridOptions={gridOptions}
                        // loadingOverlayComponent={CustomLoadingCellRenderer}
                        // loadingOverlayComponentParams={{
                        //     loadingMessage: "Loading all rows...",
                        // }}
                        onCellValueChanged={onCellValueChanged}
                    //onRowValueChanged={onRowValueChanged}


                    />

                </div>
            </div>



        </div>

    );


};
const mapStateToProps = ({ }) => {

    return {

    };
};

const mapDispatchToProps = {
}

export default connect(mapStateToProps, mapDispatchToProps)(TecniniciansPage);