/* eslint-disable no-param-reassign */
/* eslint-disable react/no-unescaped-entities */
import {
    Button,
    Checkbox,
    Col,
    DatePicker,
    Form, Input,
    Modal,
    Radio,
    Row,
    Select,
    Typography,
    notification, Divider, Space
} from 'antd';
import { CheckOutlined, CloseOutlined } from '@ant-design/icons';
import { get } from 'lodash';
import moment from 'moment';

import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { fetchLicenseList, fetchUniqueId, generateLicenseaction } from '../redux/LicenseManager/action';
import { getCategoryListFromDatabse, GetUniqueId, UpdateStatus } from '../redux/LicenseManager/saga';
import { M500_APPLICATION, M500_MOTOR, P625M4_APPLICATION, UNIQUEID_PATTERN } from '../utils/constants';
import axios from 'axios';

const { Option } = Select;
const { Text } = Typography;


//const { Option } = Select;
const GenerateLicenseForm = (props) => {

    const {
        setGenerateLiceseVisible,
        generateLiceseVisible,
        ongeneratelicense,
        onFetchLicenseData,
        onFetchUniqueId,
        licenseData,
        licblk,
        record,
        licenseForm,
        isEdit,
        setIsEdit,
        setShowDownloadModel,
        isLicenseLoading,
        isFetchLincenseData,
        setPerpetualDate,
        perpetualDate
    } = props;
    const defaultPerpetualDate = '2099-12-31';
    const referenceUUID = '0123456789ABCDEFGHJKLMNPQRSTUVWX';
    const generate = "Generate";
    const update = "Update"
    const boardType_List = [{ value: 'Motor Board' }, { value: 'Application Board' }]
    const boardType625_List = [{ value: 'Application Board (Before Version 8.8.17)' }, { value: 'Application Board (Version 8.8.17 and Later)' }]
    const motorApplicationType = ["Basic Function"];
    const M500_application_features = ["Basic Function", "Solid Wire", "Horizontal Tracking", 'Fronius PS', 'Lincoln PS']; //TIG welding removed as discussed by vinay
    const P625_application_features = ["Basic Function", "360 Degree Welding", "Zone to Zone Ramping", "Touch and Ramp", 'Fronius TPSi'];
    // const M500_application_features = [{ value: "Solid Wire",id:"1" }, { value: "Horizontal Tracking",id:"2" }, { value: "TIG Welding",id:"3" }];
    //const P625_application_features = [{ value: "360 Degree Welding" }, { value: "Zone to Zone Ramping" }, { value: "Touch and Ramp" }, { value: "Fronius TPSi" }];
    const application_types = [{ value: "M500" }, { value: "P625" }]
    const [fileName, setfileName] = useState("")
    const [customerName, setCustomerName] = useState("")
    const [serialNo, setSerialNo] = useState("")
    const [uniqueId, setUniqueId] = useState('');
    const [location, setLocation] = useState('')
    // const [m500Setected, setM500Selected] = useState('');
    const [applicationType, setApplicationType] = useState('M500');
    const [boardType, setBoardType] = useState('Motor Board');


    // const [p625Selected, setp625Selected] = useState('');
    //const [expirationDate, setexpirationDate] = useState(null);
    const [chxList, setChxList] = useState([]);
    const [selectedchxList, setSelectedChxList] = useState([]);
    //const [featureExpireDate, setFeatureExpireDate] = useState([]);
    const [selectedFeatureTypeExpairDate, setselectedFeatureTypeExpairDate] = useState(['', '', '', '']);
    const [featureTypeAndDate, setFeatureTypeAndDate] = useState([]);

    const [usbDiviceType, setUsbDiviceType] = useState([]);
    const [selectedDeviceId, setSelectedDeviceId] = useState('')
    const [customerNameTypes, setCustomerNameTypes] = useState([]);
    const [LocationList, setLocationList] = useState([]);
    const [newItem, setNewItem] = useState('');

    const [value1, setValue1] = useState(1);
    const [value2, setValue2] = useState(1);
    const [value3, setValue3] = useState(1);
    const [value4, setValue4] = useState(99);
    const [value5, setValue5] = useState(1);
    const [value6, setValue6] = useState(1);
    const [expireDate1, setExpireDate1] = useState(null);
    const [expireDate2, setExpireDate2] = useState(null);
    const [expireDate3, setExpireDate3] = useState(null);
    const [expireDate4, setExpireDate4] = useState(null);
    const [expireDate5, setExpireDate5] = useState(null);
    const [expireDate6, setExpireDate6] = useState(null);
    const [firstValue, setFirstValue] = useState(false)
    const [secondValue, setSecondValue] = useState(false)
    const [thirdValue, setThirdValue] = useState(false)
    const [forthValue, setforthValue] = useState(true)
    const [fithValue, setFithValue] = useState(false)
    const [sixthValue, setSixthValue] = useState(false)
    //const [todayDate, setTodaydate] = useState(moment().format("DD-MM-YYYY"));
    const [isAvialableUniqueId, setIsAvialableUniqueId] = useState(false)
    const [enableSendDeviceButton, setEnableSendDeviceButton] = useState(false)
    const [deviceDbyteArray, setDeviceDbyteArray] = useState([]);
    const [confirmLoading, setConfirmLoading] = useState(false);
    const [ipAddress, setIpAddress] = useState('');
    const [emailInternal, setEmailInternal] = useState('');
    const [emailExternal, setEmailExternal] = useState('');
    const [reminderList, setReminderList] = useState([]);
    const [Contractor, setContractor] = useState('Internal');
    const [Board_Serial_Number, setBoard_Serial_Number] = useState('');
    const [SendAllLoading, setSendAllLoading] = useState(false);
    ///const [port, setPort] = useState(undefined)
    let isWriteLicense = false;
    let isReadUniqueId = false;
    const delay = async (ms) => new Promise(
        resolve => setTimeout(resolve, ms)
    );
    useEffect(() => {
        if (isEdit)
            return;
        if (confirmLoading && !isLicenseLoading) {
            setEnableSendDeviceButton(true)
            if (usbDiviceType.length === 0) {
                setIsEdit(false)
                setGenerateLiceseVisible(false);
            }
        }
        else {
            setEnableSendDeviceButton(false)

        }
    }, [isLicenseLoading])

    const [ip, setIp] = useState('');

    useEffect(() => {
        const fetchIp = async () => {
            try {
                const response = await axios.get('https://api.ipify.org/?format=json');
                if (response && response.data)
                    setIpAddress(response.data.ip);

                if (customerNameTypes.length == 0 || LocationList.length == 0) {
                    const typeList = await getCategoryListFromDatabse()
                   

                    if (typeList) {


                        const Customer_Name_list = typeList.Customer_Name
                        setCustomerNameTypes(
                            Customer_Name_list
                                .filter(item => item.Customer_Name !== '') // Filter out empty customer names
                                .sort((a, b) => a.Customer_Name.localeCompare(b.Customer_Name)) // Sort alphabetically
                        );

                        const Location_list = typeList.Location
                        setLocationList(
                            Location_list
                                .filter(item => item.Location !== '') // Filter out empty customer names
                                .sort((a, b) => a.Location.localeCompare(b.Location)) // Sort alphabetically
                        );
                    }

                }




                // console.log(response)
            } catch (error) {
                console.error("Error fetching the IP address:", error);
            }
        };

        fetchIp();
    }, []);


    useEffect(() => {
        if (isEdit)
            return;
        if (licblk && usbDiviceType && selectedDeviceId) {
            var data = usbDiviceType.find(function (ele) {
                return ele.value === selectedDeviceId;
            });
            const arra = Object.values(licblk);
            setDeviceDbyteArray([...arra])
            isWriteLicense = false;

            // if (data)
            //     (async () => {
            //         await getDeviceid(data);
            //     })();
        }
        //if (!fetchUniqueId)
        //onFetchUniqueId();

        //console.log('called one time', licblk)
    }, [licblk]);

    useEffect(() => {
        if (isEdit)
            return;
        if (deviceDbyteArray.length > 0 && usbDiviceType && selectedDeviceId) {
            var data = usbDiviceType.find(function (ele) {
                return ele.value === selectedDeviceId;
            });
            if (data && deviceDbyteArray.length > 0)
                (async () => {
                    await getLicenseByteArray(data, deviceDbyteArray);
                })();
        }
        //if (!fetchUniqueId)
        //onFetchUniqueId();

        //console.log('called one time', licblk)
    }, [deviceDbyteArray]);

    useEffect(() => {
        if (!isEdit && uniqueId.length === 20 && licenseData.length > 0) {
            const data = licenseData[0]
            if (uniqueId == data.Unique_Id) {
                setApplicationType(data.Type_Of_Device)
                let type = data.Board_Type;
                if (data.Type_Of_Device === 'P625' && type == 'Application Board') {
                    type = boardType625_List[0].value
                }
                setBoardType(type)
                setIsAvialableUniqueId(true)
                const editData = {

                    application_type: data.Type_Of_Device,

                    board_type: type,
                };
                licenseForm.setFieldsValue(editData)
            }

        }

    }, [licenseData])
    useEffect(() => {

        // console.log(uniqueId,uniqueId.length,usbDiviceType)
        if (isEdit || !uniqueId)
            return;
        if (uniqueId.length === 20 && usbDiviceType.length === 0) {
            (async () => {
                var obj = {
                    searchKey: uniqueId
                }
                //   await onFetchLicenseData(obj)
                await onFetchLicenseData(obj)
            })();

        }
        else {
            setIsAvialableUniqueId(false)
            //licenseForm.resetFields();
        }
        //console.log(searchText)
        /// console.log(uniqueId,uniqueId.length)

    }, [uniqueId]);

    useEffect(() => {
        if (!isEdit) {
            onchangechxBox(true, motorApplicationType[0])
            const listObj = [];
            const type = featureTypeAndDate.find((obj) => obj.Type === motorApplicationType[0])
            listObj.push({
                Type: motorApplicationType[0],
                //ExpireDate: type ? type.ExpireDate : moment(defaultPerpetualDate).format('YYYY-MM-DD')
                ExpireDate: type ? type.ExpireDate : moment(perpetualDate && perpetualDate !== '' ? perpetualDate : defaultPerpetualDate).format('YYYY-MM-DD')
                //moment().add(99, 'years').format('YYYY-MM-DD')
            })

            setFeatureTypeAndDate(listObj)
        }
    }, [])

    useEffect(() => {

        if (!isEdit) {
            if (applicationType === 'M500') {
                setChxList(M500_application_features)
            }
            else {
                setChxList(P625_application_features)
                setBoardType(boardType625_List[0].value)
                const editData = {
                    board_type: boardType625_List[0].value,

                };
                licenseForm.setFieldsValue(editData)
            }
        }

    }, [applicationType])
    useEffect(() => {

        if (!isEdit) {

            if (applicationType === 'M500' && boardType === 'Motor Board') {
                setChxList(motorApplicationType)
            }
            else if (applicationType === 'M500' && boardType === 'Application Board') {
                setChxList(M500_application_features)
            }
            else if (applicationType === 'P625' && boardType.includes('Application Board')) {
                setChxList(P625_application_features)
            }

        }
    }, [boardType])

    useEffect(() => {
        if (isEdit && record) {
            // console.log(record)
            setfileName(record.License_File_Name)
            setUniqueId(record.Unique_Id)
            setCustomerName(record.Customer_Name)
            setLocation(record.Location)
            setApplicationType(record.Type_Of_Device)
            //setSelectedChxList([record.Feature_Type])
            selectedchxList[0] = record.Feature_Type
            setSerialNo(record.Serial_Number)
            setChxList([record.Feature_Type])
            onChangeCheckBox(selectedchxList)
            chxList[0] = record.Feature_Type;
            // setSelectedChxList([])
            if (record.Reminder)
                setReminderList(record.Reminder.split(','))
            if (record.Internal_Emails)
                setEmailInternal(record.Internal_Emails)
            if (record.External_Emails)
                setEmailExternal(record.External_Emails)
            if (record.Contractor)
                setContractor(record.Contractor)
            if (record.Board_Serial_Number)
                setBoard_Serial_Number(record.Board_Serial_Number)

            setBoardType(record.Board_Type)
            featureTypeAndDate[0] = record.Feature_Type;
            //onchangechxBox(true, record.Feature_Type)
            const years = moment(record.Expiration_Date).from(record.File_Creation_Date, 'days', 'month', 'year').split(' ')
            let isvalidDate = false;
            // console.log(years, record.Expiration_Date)
            if (years && years.includes('years') || years.includes('year') && years.length > 1) {
                years[0] = years[0] == 'a' ? '1' : years[0]
                const dates = moment(record.File_Creation_Date).add(years[0], 'years').format('YYYY-MM-DD');

                if (record.Expiration_Date === dates) {
                    setValue1(parseInt(years[0]))
                    setValue2(parseInt(years[0]))
                    setValue3(parseInt(years[0]))
                    setValue4(parseInt(years[0]))
                    setValue6(parseInt(years[0]))

                    isvalidDate = true;
                    // const listObj = [];
                    // listObj.push({
                    //     Type: record.Feature_Type,
                    //     ExpireDate: moment().add(years[0], 'years').format('YYYY-MM-DD')
                    // })
                   

                    // setFeatureTypeAndDate(listObj);

                }
            }

            const listObj = [];
            listObj.push({
                Type: record.Feature_Type,
                ExpireDate:record.Expiration_Date
            })

            setFeatureTypeAndDate(listObj);
            
            if (!isvalidDate) {
                setValue1(0)
                setValue2(0)
                setValue3(0)
                setValue4(0)
                setValue5(0)
                setValue6(0)

                setExpireDate1(moment(record.Expiration_Date))
                setExpireDate2(moment(record.Expiration_Date))
                setExpireDate3(moment(record.Expiration_Date))
                setExpireDate4(moment(record.Expiration_Date))
                setExpireDate5(moment(record.Expiration_Date))
                setExpireDate6(moment(record.Expiration_Date))
            }

        }

    }, [isEdit])

    useEffect(() => {
        if (!isEdit) {
            licenseForm.resetFields();
            setfileName('')
            setUniqueId('')
        }


    }, [isEdit])

    useEffect(() => {
        if (!isEdit) {
            var data = usbDiviceType.find(function (ele) {
                return ele.value === selectedDeviceId;
            });
            if (data) {

                (async () => {
                    await getDeviceid(data);
                })();
                // (async () => {
                //     await getLicenseByteArray(data);
                // })();
            }
        }


    }, [selectedDeviceId])



    useEffect(() => {
        if (isEdit)
            return;
        if (generateLiceseVisible) {
            if (navigator.usb) {
                navigator.usb.addEventListener('connect', event => {
                    //  console.log(event)
                    setSelectedDeviceId('');
                    setUsbDiviceType([])
                    const editData = {
                        device_in: '',
                        uniqueId: ''
                    };
                    licenseForm.setFieldsValue(editData)
                    delay(1000)
                    handleUSB();
                });

                navigator.usb.addEventListener('disconnect', event => {
                    // Remove |event.device| from the UI.
                    setSelectedDeviceId('');
                    setUsbDiviceType([])
                    const editData = {
                        device_in: '',
                        uniqueId: ''
                    };
                    licenseForm.setFieldsValue(editData)
                    delay(1000);
                    handleUSB();
                })

                //handleUSB();
                handleUSB();
            } else {
                console.error("WebUSB not enabled (chrome://flags/#new-usb-backend)");
            }
        }
        else {
            navigator.usb.removeEventListener('connect');
            navigator.usb.addEventListener('disconnect');

        }


    }, [generateLiceseVisible])

    const getBaudRate = (prodcutName) => {
        let baudRate = 3000000
        if (prodcutName === 'P625M4_APPLICATION')
            baudRate = 115200;
        return baudRate;
    }
    const setBoarsSelectedTypes = (data) => {
        let machineType = 'M500', baordType = 'Motor Board'
        if (data.productName === 'P625M4_APPLICATION') {
            machineType = 'P625'
            baordType = boardType625_List[0].value // default value  after fetch unique id 
        }
        else if (data.productName === 'M500_APPLICATION') {
            machineType = 'M500'
            baordType = 'Application Board'
        }
        else if (data.productName === 'M500_MOTOR') {
            machineType = 'M500'
            baordType = 'Motor Board'
        }
        setApplicationType(machineType)
        setBoardType(baordType)
        setIsAvialableUniqueId(true)
        const editData = {

            application_type: machineType,

            board_type: baordType,
        };
        licenseForm.setFieldsValue(editData)

    }
    const handleUSB = async () => {
        let devices = navigator.usb.getDevices()
        let deviceName = []
        if (devices && (await devices).length > 0) {
            (await devices).forEach(device => {
                //  console.log('deviceList',d);
                //UpdateUsbConnection(d)
                if (device.productName) {
                    if (device.productName === M500_APPLICATION || device.productName === M500_MOTOR || device.productName === P625M4_APPLICATION) {
                        var obj = {
                            'value': device.productName + ' (' + device.serialNumber + ')',
                            'id': device.productId,
                            'vendorId': device.vendorId,
                            'baudRate': getBaudRate(device.productName),
                            'productName': device.productName,
                            "serialNumber": device.serialNumber
                        };

                        deviceName.push(obj);
                        setUsbDiviceType(deviceName);
                        return

                    }
                }

            });


        }
    }
    const onRequestNewPort = async () => {
        await requestNewPort();
        handleUSB()
    }

    const requestNewPort = async () => {
        try {
            const device = await navigator.usb.requestDevice({
                filters: [{
                    vendorId: '1027',
                    productId: '24577'

                }]
            });
            console.log(' Paired device', device)

        } catch (err) {
            // notification.warning({
            //     message: 'Please connect or power on usb device!'
            // })
            // No device was selected.
            return;
        }

    }
    // const UpdateUsbConnection = (device) => {
    //   let deviceName =[];
    //      console.log(usbDiviceType);
    //     if (device.productName) {
    //         // var data = usbDiviceType.find(function (ele) {
    //         //     return ele.value === device.productName;
    //         // });
    //       //  if (!data) 
    //         {
    //             var obj = {
    //                 'value': device.productName + ' (' + device.serialNumber + ')',
    //                 'id': device.productId,
    //                 'vendorId': device.vendorId,
    //                 'baudRate': getBaudRate(device.productName),
    //                 'productName': device.productName,
    //                 "serialNumber": device.serialNumber
    //             };

    //             deviceName.push(obj);
    //         }
    //     }
    //     console.log('device list', deviceName);
    //     // console.log(device.manufacturerName);
    //     //  console.log(device.productId);


    // }

    const IsValidUUID = async (inputString) => {
        // Ensure the input string has exactly 20 characters
        if (inputString.length !== 20) {
            return false;
        }

        // Check if every character in the input string exists in the reference string
        for (let char of inputString) {

            if (!referenceUUID.includes(char) || char === ' ' || char !== char.toUpperCase()) {
                console.log('inValid char from Device ', char)
                return false;
            }
        }
        return true;
    }



    const getDeviceid = async (data) => {
        console.log('Device data', data)

        let port
        const ports = await navigator.serial.getPorts();
        let validBoard = false;
        let uni = ''
        ports.forEach(p => {

            const { usbProductId, usbVendorId } = p.getInfo();
            console.log('Port Info(product id and Vender Id)', usbProductId, usbVendorId)
            if (usbVendorId === data.vendorId && usbProductId === data.id) {
                port = p;
                console.log('selected port', port)
            }
        })


        if (!port) {
            try {
                const filters = [
                    { usbVendorId: data.vendorId, usbProductId: data.id },
                    // {usbProductName: data }

                ];
                port = await navigator.serial.requestPort({ filters })
            }
            catch {
                console.log('No selected port')
                notification.warning({
                    message: 'Please connect or power on usb device!'
                })
                return
            }
        }

        if (port) {
            // Wait for the serial port to open.
            try {

                const isOpen = await port.open({ baudRate: data.baudRate });
                console.log('Port opened')
                UpdateStatus({
                    "SuccessMsg": "Port  opened Successfully"

                });

            }
            catch (error) {
                console.log('Port not opened')
                notification.warning({
                    message: 'Please connect or power on usb device!'
                })
                UpdateStatus({
                    "SuccessMsg": "Port not opened" + error

                });
                //await port.close();
                return;
                // TODO: Handle non-fatal read error.
            }
            try {
                //const writer = port.writable.getWriter();

                const textEncoder = new TextEncoderStream();
                const writableStreamClosed = textEncoder.readable.pipeTo(port.writable);

                const writer = textEncoder.writable.getWriter();


                const textDecoder = new TextDecoderStream();
                const readableStreamClosed = port.readable.pipeTo(textDecoder.writable);
                const reader = textDecoder.readable.getReader();




                if (data.productName === 'M500_MOTOR') {
                    validBoard = true;
                }
                else {
                    try {
                        let sy = ''


                        console.log('write ~ symbol')
                        await writer.write("\x1B~");
                        delay(1000)
                        // const ch = await ReadData(reader, 1);
                        const timer = setTimeout(async () => {
                            //reader.releaseLock();
                            reader.cancel()
                            console.log('reader time out')
                        }, 500);
                        for (let index = 0; index < 2; index++) {
                            //while (true) 
                            {
                                const { value, done } = await reader.read();
                                console.log('read ~ symbol', value)
                                sy = value
                                if (done) {

                                    reader.releaseLock();
                                    // break;
                                }
                                if (sy) {

                                    if (sy === '~' || sy.includes('~')) {

                                        console.log('Response from device:', sy, sy.length)
                                        validBoard = true;
                                        break;
                                    }
                                }
                            }

                        }
                    }
                    catch (error) {
                        console.log('Reading device Error', error)
                        //return;
                        // TODO: Handle non-fatal read error.
                    }

                }


                console.log('valid for read', validBoard)
                if (validBoard) {

                    let isValidBaudRate = false
                    let bh = ''
                    for (let index = 0; index < 2; index++) {
                        console.log('write  baud_check')
                        await writer.write('baud_check\r');
                        while (true) {

                            bh += await ReadData(reader, 1);
                            if (bh.endsWith('\n')) {
                                console.log('Device response:', bh)
                                isValidBaudRate = true;
                                break;
                            }

                        }
                        if (isValidBaudRate)
                            break;
                    }

                    isReadUniqueId = false;
                    let id = ''
                    for (let index = 0; index < 1; index++) {

                        let isValidId = false;
                        await writer.write("get_id_string\r");
                        console.log('Write successfully')
                        if (data.productName === 'M500_MOTOR') {
                            const timer = setTimeout(async () => {
                                //reader.releaseLock();
                                reader.cancel()
                                console.log('reader time out')
                            }, 500);
                        }

                        for (let index = 0; index < 3; index++) {

                            id += await ReadData(reader, 21);
                            if (id) {
                                if (id.endsWith('\n')) {
                                    const validId = id.replace(/[^\w @]/g, '')
                                    // Validate the  UUID String from Device
                                    //const validId= validId1.replace(/Q/g, 'O');
                                    if (await IsValidUUID(validId)) {
                                        setUniqueId(validId)
                                        const editData = { uniqueId: validId };
                                        licenseForm.setFieldsValue(editData)
                                        setBoarsSelectedTypes(data)
                                        console.log('Unique id from device:', validId, validId.length)
                                        isValidId = true;
                                        break;
                                    }
                                    else {
                                        console.log('Invalid Unique id from device:', validId, validId.length)
                                        notification.warning({
                                            message: 'Invalid Unique id from device ' + validId
                                        })
                                        break;
                                    }
                                }
                            }
                        }
                        if (isValidId)
                            break;
                    }
                }

                writer.close();
                await writableStreamClosed;

                if (reader.readable)
                    reader.cancel();
                await readableStreamClosed.catch(() => { });
            }
            catch (error) {
                console.log('Reading device Error', error)
                console.log('Reading device Error1', port.readable)

                // await readableStreamClosed.catch(() => { });
                // await port.close();
                return;
                // TODO: Handle non-fatal read error.
            }


            //delay(1000)
            //console.log('Closed successfully')
            await port.close();

            setDeviceDbyteArray([]);
            if (!validBoard) {
                notification.warning({
                    message: 'Unable to establish connection'
                })
                UpdateStatus({
                    "SuccessMsg": 'Unable to establish connection\n selected device is :' + JSON.stringify(data)

                });
            }
            else {
                UpdateStatus({
                    "SuccessMsg": 'Unique Id readed from device :' + uni + '\n'

                });
            }
            UpdateStatus({
                "SuccessMsg": 'Port closed successfully'

            });
            //await port.close();
            console.log('Closed successfully')
        }
    }


    const getLicenseByteArray = async (data, byteArray) => {
        // console.log('Device data', data)
        let port
        let validBoard = false;
        let res = '';
        const ports = await navigator.serial.getPorts();


        ports.forEach(p => {
            console.log(p)
            const { usbProductId, usbVendorId } = p.getInfo();
            console.log('Port Info(product id and Vender Id)', usbProductId, usbVendorId)
            if (usbVendorId === data.vendorId && usbProductId === data.id) {
                port = p;
                //console.log('selected port', port)
            }
        })


        if (port) {
            // Wait for the serial port to open.
            try {

                const isOpen = await port.open({ baudRate: data.baudRate });
                console.log('Port opened')
                UpdateStatus({
                    "SuccessMsg": "Port  opened Successfully"

                });
                // console.log(port, port.isOpen)
            }
            catch (error) {
                console.log('Port not opened')
                notification.warning({
                    message: 'Please connect or power on usb device!'
                })
                UpdateStatus({
                    "SuccessMsg": "Port not opened" + error

                });
                // await port.close();
                return;
                // TODO: Handle non-fatal read error.
            }
            try {
                //const writer = port.writable.getWriter();
                //console.log('try to get id-1')
                const textEncoder = new TextEncoderStream();
                const writableStreamClosed = textEncoder.readable.pipeTo(port.writable);

                const writer = textEncoder.writable.getWriter();

                const textDecoder = new TextDecoderStream();
                const readableStreamClosed = port.readable.pipeTo(textDecoder.writable);
                const reader = textDecoder.readable.getReader();
                //console.log('try to get id-2')

                //  console.log(data.productName === 'M500_MOTOR')
                if (data.productName === 'M500_MOTOR') {
                    validBoard = true;
                    //console.log(data.productName)
                }
                else {

                    for (let index = 0; index < 4; index++) {

                        console.log('write ~ symbol')
                        await writer.write("\x1B~");
                        delay(100) //100 ms wait
                        const ch = await ReadData(reader, 1);
                        if (ch === '~') {

                            console.log('Device from response:', ch, ch.length)
                            validBoard = true;
                            break;
                        }
                        else //0x81 hex from device
                        {
                            const charCode = ch.charCodeAt(0);
                            const hexValue = charCode.toString(16);
                            if (hexValue === '0x81') {
                                console.log('Device from response:', hexValue)

                                writer.close();
                                reader.cancel();
                                await port.close();
                                notification.warning({
                                    message: 'Please send again'
                                })
                                UpdateStatus({
                                    "SuccessMsg": 'device not in cli mode.\n  response :' + hexValue

                                });
                                return
                            }
                        }
                    }
                }
                delay(10) //10 ms wait
                // let isValidBaudRate = false
                // let bh = ''
                // for (let index = 0; index < 2; index++) {
                //     console.log('try to write  baud_check')
                //     await writer.write('baud_check\r');
                //     delay(1000)
                //     console.log('try to read baud_check')
                //     // while (true)
                //     for (let index = 0; index < 2; index++) {

                //         {
                //             bh += await ReadData(reader, 1);
                //             console.log(bh)
                //             if (bh.includes('BAUD_CHECK')) {
                //                 console.log('Device response:', bh)
                //                 isValidBaudRate = true;
                //                 break;
                //             }
                //         }
                //     }
                //     if (isValidBaudRate)
                //         break;
                // }

                let isValidRTC = false
                let rtc = ''
                for (let index = 0; index < 2; index++) {

                    const now = new Date();
                    const formattedString = `rtc ${now.getFullYear()}/${(now.getMonth() + 1).toString().padStart(2, '0')}/${now.getDate().toString().padStart(2, '0')} ${now.getHours().toString().padStart(2, '0')}:${now.getMinutes().toString().padStart(2, '0')}:${now.getSeconds().toString().padStart(2, '0')}\r`;
                    console.log('write  RTC', formattedString)
                    await writer.write(formattedString);
                    delay(1000)

                    while (true) {
                        rtc += await ReadData(reader, 1);
                        if (rtc.endsWith('\n')) {
                            console.log('Device response:', rtc)
                            isValidRTC = true;
                            break;
                        }

                    }
                    if (isValidRTC)
                        break;
                }


                //console.log('license Data  licblk array:', deviceDbyteArray, deviceDbyteArray.length)
                if (validBoard && byteArray.length > 0) {
                    isWriteLicense = false;
                    const arra = byteArray;//Object.values(licblk);
                    if (arra.length > 0) {

                        try {
                            const uint8Array = new Uint8Array(128)
                            for (let i = 0; i < arra.length; i++) {
                                uint8Array[i] = arra[i]  // Use 0 if the index is not present in the object
                            }
                            // console.log(uint8Array);
                            // console.log('License Data  licblk array:', uint8Array, uint8Array.length)
                            const str = 'write_lic_block 0080\r'
                            await writer.write(str);
                            console.log('Write command success')

                            writer.close();
                            await writableStreamClosed;
                            //await delay(10)


                            const writer1 = port.writable.getWriter();
                            await writer1.write(uint8Array);
                            writer.releaseLock();
                            writer1.close();

                            console.log('Write Data Array success')
                            await delay(50) //dealy  ms

                            while (true)
                            // for (let index = 0; index < 2; index++)
                            {
                                const { value, done } = await reader.read();
                                if (done) {
                                    reader.releaseLock();
                                    break;
                                }
                                //console.log('Response from device:', value)
                                res += value
                                if (res.endsWith('\n'))
                                    break;

                            }

                            if (res.includes('License successfully installed')) {
                                notification.success({
                                    message: res
                                })

                            }
                            else if (res.length > 30) {
                                notification.warning({
                                    message: res
                                })
                            }


                            console.log('Response from device:', res)
                        }
                        catch (error) {
                            console.log('Device writing error:', error)
                            writer.close();
                            await writableStreamClosed;
                            await port.close();
                            UpdateStatus({
                                "SuccessMsg": 'Not installed license :\n ' + data

                            });
                            return;

                        }

                    }



                }
                else {
                    writer.close();
                    await writableStreamClosed;
                    notification.info({
                        message: "File not available.Please Generate License file"
                    })
                    UpdateStatus({
                        "SuccessMsg": 'File not available.Please Generate License file'

                    });
                }

                reader.cancel();
                await readableStreamClosed.catch(() => { });
            }
            catch (error) {
                console.log('Device writing error:', error)
                setDeviceDbyteArray([]);
                await port.close();
                return;
            }
            await port.close();

            setDeviceDbyteArray([]);
            //await port.close();
            console.log('Closed successfully', validBoard)
            if (validBoard) {
                UpdateStatus({
                    "SuccessMsg": res + '\nPort closed successfully'

                });

            }
        }
        else {
            notification.info({
                message: "Unable to establish connection."
            })
            UpdateStatus({
                "SuccessMsg": 'Unable to establish connection\n selected device' + data

            });
        }
    }



    const ReadData = async (reader, byteLength) => {


        const { value, done } = await reader.read();

        if (done) {

            reader.releaseLock();
            // break;
        }
        return value;
    }

    const onChangeFileName = (event) => {
        // console.log(event.target.value)
        setfileName(event.target.value)

    }
    const onChangeUniqueId = (event) => {

        setUniqueId(event.target.value)

    }
    const onChangeCustomerName = (event) => {
        // console.log(event.target.value)
        setCustomerName(event.target.value)

    }
    const onChangeLocation = (event) => {
        // console.log(event.target.value)
        setLocation(event.target.value)

    }
    const onChangeSerialNo = (event) => {
        // console.log(event.target.value)
        setSerialNo(event.target.value)

    }
    /** Function call for getting data on applying filter */
    const generateLicenseApi = async (obj) => {
        // console.log(obj)
        await ongeneratelicense({ data: obj });
    }
    const validateEmail = (email) => {
        const regexp = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;
        return regexp.test(email);
    }
    const handleOk = async () => {
        licenseForm
            .validateFields()
            .then(async (data) => {
                let valid = false
                if (!data.fileName && !data.uniqueId && !data.m500_type && !data.featureType && !featureTypeAndDate) {
                    notification.warning({
                        message: 'Select All Required Fields to proceed!'
                    });
                } else if (!data.fileName) {
                    notification.warning({
                        message: 'Enter the  File Name  to proceed!'
                    });
                } else if (!data.uniqueId || data.uniqueId.length !== 20) {
                    notification.warning({
                        message: 'Enter the Unique Id exactly 20 characters to proceed!'
                    });
                }
                else if (!boardType) {
                    notification.warning({
                        message: 'Select Board Type to proceed!'
                    });
                }
                else if (featureTypeAndDate.length <= 0) {
                    notification.warning({
                        message: 'Select Features Type to proceed!'
                    });
                } else if (!featureTypeAndDate.map(obj => obj.ExpireDate)) {

                    notification.warning({
                        message: 'Select Exparation Date to proceed!'
                    });

                }
                else {
                    valid = true
                }

                if (reminderList.length > 0) {
                    valid = false;
                    // If both emails are empty, show a warning
                    if (emailInternal === '' && emailExternal === '') {
                        notification.warning({
                            message: 'Enter either an internal or external email ID to proceed!'
                        });
                    }
                    // Validate emailInternal only if it's not empty
                    else if (emailInternal !== '' && !validateEmail(emailInternal)) {
                        notification.warning({
                            message: 'Please enter a valid internal email ID!'
                        });
                    }
                    // Validate emailExternal only if it's not empty
                    else if (emailExternal !== '' && !validateEmail(emailExternal)) {
                        notification.warning({
                            message: 'Please enter a valid external email ID!'
                        });
                    }
                    // If either email is valid or empty, skip the remaining checks
                    else {
                        valid = true
                    }
                }

                if (valid) {

                    var obj = {
                        "fileName": data.fileName,
                        "uniqueId": data.uniqueId.toUpperCase(),
                        "typeOfDevice": applicationType,
                        "boardType": boardType,
                        "featureType": featureTypeAndDate.map(obj => obj.Type).toString(),
                        "expirationDate": featureTypeAndDate.map(obj => obj.ExpireDate).toString(),
                        "customerName": data.customerName,
                        "serialNumber": data.serialNumber,
                        "location": data.location,
                        'systemAddress': ipAddress,
                        'Board_Serial_Number': Board_Serial_Number,
                        'Contractor': Contractor,
                        'Internal_Emails': reminderList.length > 0 ? emailInternal : '',
                        'External_Emails': reminderList.length > 0 ? emailExternal : '',
                        'Reminder': reminderList.toString(),
                        'id': isEdit ? record.index : 0,
                        'isUpdate': isEdit
                    }
                    console.log(obj)
                    //return
                    await generateLicenseApi(obj)

                    // setEnableSendDeviceButton(true)
                    setConfirmLoading(true)

                    //setIsEdit(false)
                    //setGenerateLiceseVisible(false);
                    if (isEdit && setShowDownloadModel)
                        setShowDownloadModel(false)
                }

            });

    };
    const handleCancel = async () => {

        licenseForm.resetFields();
        setDeviceDbyteArray([])
        setIsEdit(false);
        navigator.usb.removeEventListener('connect', event => { });
        navigator.usb.removeEventListener('disconnect', event => { });

        setGenerateLiceseVisible(false);
        var obj = {
            //   pageSize: 10,
            //  pageNumber: 1,
            searchKey: ''
        }

        await onFetchLicenseData(obj)
    };
    const onchangechxBox = (isChecked, featuretype) => {

        let selectedArray = []
        if (isChecked) {
            if (!selectedchxList.includes(featuretype))
                selectedchxList.push(featuretype)
            if (featuretype === chxList[1])
                setFirstValue(true)
            else if (featuretype === chxList[2])
                setSecondValue(true)
            else if (featuretype === chxList[3])
                setThirdValue(true)
            else if (featuretype === chxList[0])
                setforthValue(true)
            else if (featuretype === chxList[4])
                setFithValue(true)
            else if (featuretype === chxList[5])
                setSixthValue(true)
            selectedArray = selectedchxList;
        }
        else {
            selectedArray = selectedchxList.filter((item) => {
                return item !== featuretype
            })
            if (featuretype === chxList[1])
                setFirstValue(false)
            else if (featuretype === chxList[2])
                setSecondValue(false)
            else if (featuretype === chxList[3])
                setThirdValue(false)
            else if (featuretype === chxList[0])
                setforthValue(false)
            else if (featuretype === chxList[4])
                setFithValue(false)
            else if (featuretype === chxList[5])
                setSixthValue(false)
        }
        if (selectedArray)
            onChangeCheckBox(selectedArray)
        //console.log(selectedchxList, firstValue)
    }
    const onChangeCheckBox = (list) => {
        if (list && featureTypeAndDate && featureTypeAndDate.length > 0) {

            const listObj = [];
            for (let index = 0; index < list.length; index++) {
                const type = featureTypeAndDate.find((obj) => obj.Type === list[index])

                listObj.push({
                    Type: list[index],
                    ExpireDate: type ? type.ExpireDate : moment().add(1, 'years').format('YYYY-MM-DD')
                })
            }

            setSelectedChxList(list)
            setFeatureTypeAndDate(listObj)
        }
    };

    // Rejeev and sella discussed regarding with Expire date
    // 1. when Perpetual selected, Smart Lync will sent 2127-12-31 - done
    // 2. the manual date selection is restricted to 31-Dec-2126. - done
    // 3. Also in the year 2126 if someone requests for 5 years license  .Smart Lync will sent 2127-12-31 .-  done
    // 4. The implementation is replicated for M-500 also.- done 
    const onChangeFeatureExpireDate = (value, featuretype) => {

        // console.log('selected value :', value, featuretype, chxList)
        const type = featureTypeAndDate.find((obj) => obj.Type === featuretype)
        if (type) {
            type.ExpireDate = (value === 99) ? moment(perpetualDate && perpetualDate !== '' ? perpetualDate : defaultPerpetualDate).format('YYYY-MM-DD') :
                moment().add(value, 'years').format('YYYY-MM-DD');

            if (moment(type.ExpireDate, 'YYYY-MM-DD') > moment(defaultPerpetualDate, 'YYYY-MM-DD')) {
                type.ExpireDate = moment(defaultPerpetualDate).format('YYYY-MM-DD')
            }
            // console.log('selected dated :', type.ExpireDate)
        }

        if (featuretype === chxList[1]) {
            //setratioBtnsolidWire(e.target.value)
            selectedFeatureTypeExpairDate[1] = value;
            setValue1(value)
            setExpireDate1(null)

        }
        else if (featuretype === chxList[2]) {
            selectedFeatureTypeExpairDate[2] = value;
            setValue2(value)
            setExpireDate2(null)
        }
        else if (featuretype === chxList[3]) {
            selectedFeatureTypeExpairDate[3] = value;
            setValue3(value)
            setExpireDate3(null)
        }
        else if (featuretype === chxList[4]) {

            selectedFeatureTypeExpairDate[4] = value;
            setValue5(value)
            setExpireDate5(null)
        }
        else if (featuretype === chxList[5]) {

            selectedFeatureTypeExpairDate[5] = value;
            setValue6(value)
            setExpireDate6(null)
        }
        else if (featuretype === chxList[0]) {


            selectedFeatureTypeExpairDate[0] = value;
            setValue4(value)
            setExpireDate4(null)
        }

        setselectedFeatureTypeExpairDate(selectedFeatureTypeExpairDate);
        //console.log('radio checked list', featureTypeAndDate);
    };
    const onChangeFeatureDates = (featuretypes, date) => {
        //console.log('radio checked list', featuretypes, date, chxList);

        if (!date) {
            if (featuretypes === chxList[1]) {
                selectedFeatureTypeExpairDate[1] = "1";
                setValue1(1)
                setExpireDate1('')
            }
            else if (featuretypes === chxList[2]) {
                selectedFeatureTypeExpairDate[2] = "1";
                setValue2(1)
                setExpireDate2('')
            }
            if (featuretypes === chxList[3]) {
                selectedFeatureTypeExpairDate[3] = "1";
                setValue3(1)
                setExpireDate3('')
            }
            if (featuretypes === chxList[4]) {
                selectedFeatureTypeExpairDate[4] = "1";
                setFithValue(1)
                setExpireDate5('')
            }
            if (featuretypes === chxList[5]) {
                selectedFeatureTypeExpairDate[5] = "1";
                setSixthValue(1)
                setExpireDate6('')
            }
            if (featuretypes === chxList[0]) {
                selectedFeatureTypeExpairDate[0] = "1";
                setValue4(1)
                setExpireDate4('')
            }
            onChangeFeatureExpireDate(1, featuretypes)
            //setselectedFeatureTypeExpairDate(selectedFeatureTypeExpairDate);
            return;
        }
        const type = featureTypeAndDate.find((obj) => obj.Type === featuretypes)
        if (type && date) {
            type.ExpireDate = moment(date).format('YYYY-MM-DD');
        }

        if (featuretypes === chxList[1]) {
            selectedFeatureTypeExpairDate[1] = "";
            setValue1(0)
            setExpireDate1(moment(date))
        }
        else if (featuretypes === chxList[2]) {
            selectedFeatureTypeExpairDate[2] = "";
            setValue2(0)
            setExpireDate2(moment(date))
        }
        if (featuretypes === chxList[3]) {
            selectedFeatureTypeExpairDate[3] = "";
            setValue3(0)
            setExpireDate3(moment(date))
        }
        if (featuretypes === chxList[4]) {


            selectedFeatureTypeExpairDate[4] = "";
            setValue5(0)
            setExpireDate5(moment(date))
        }
        if (featuretypes === chxList[5]) {


            selectedFeatureTypeExpairDate[5] = "";
            setValue6(0)
            setExpireDate6(moment(date))
        }
        if (featuretypes === chxList[0]) {
            selectedFeatureTypeExpairDate[0] = "";
            setValue4(0)
            setExpireDate4(moment(date))
        }
        setselectedFeatureTypeExpairDate(selectedFeatureTypeExpairDate);
    };

    const onValidateBoard = async (typeFuture, value, expireData) => {
        //await validate_Board();

        // console.log('selected paramaters :', fileName, uniqueId, applicationType, boardType, typeFuture, value, featureTypeAndDate);
        if (fileName && uniqueId && applicationType && boardType && typeFuture && featureTypeAndDate) {
            const type = featureTypeAndDate.find((obj) => obj.Type === typeFuture)
            if (type) {
                const expireDate = type.ExpireDate;// moment().add(value, 'years').format('YYYY-MM-DD');
                const data1 = {
                    "fileName": fileName,
                    "uniqueId": uniqueId.toUpperCase(),
                    "typeOfDevice": applicationType,
                    "boardType": boardType,
                    "featureType": typeFuture,
                    "expirationDate": expireDate,
                    "id": 0,
                    "isUpdate": false
                }
                const deviceDbyteArray = await GetUniqueId(data1)
                // console.log('ByteArray', deviceDbyteArray)
                const arra = Object.values(deviceDbyteArray);
                const byteValue = [...arra]
                if (byteValue.length > 0 && usbDiviceType && selectedDeviceId) {
                    var data = usbDiviceType.find(function (ele) {
                        return ele.value === selectedDeviceId;
                    });
                    if (data && byteValue.length > 0)

                        await getLicenseByteArray(data, byteValue);

                }
                // console.log(data);
                //await onFetchUniqueId(data);
            }
        }
        else {
            notification.warning({
                message: 'Please send valid parameters'
            })
        }


    }
    const onSendtoAllDevice = async () => {
        console.log(featureTypeAndDate)
        if (featureTypeAndDate) {
            setSendAllLoading(true)
            for (let index = 0; index < featureTypeAndDate.length; index++) {
                const element = featureTypeAndDate[index];
                await onValidateBoard(element.Type)
            }
            setSendAllLoading(false)
        }
    }
    const onChangesUsbDevice = (value) => {
        isReadUniqueId = true
        setSelectedDeviceId(value)
    }
    const getTitle = (title) => {
        if (title == 'Lincoln PS')
            return 'Lincoln Power supply'
        else if (title == 'Fronius PS') {
            return 'Fronius Power supply'
        }
        else
            return title
    }
    const onchangeRemiderchxBox = (isChecked, types) => {
        if (isChecked)
            setReminderList(types)
        else
            setReminderList([])
    }

    const setAdddnewItem = (event) => {
        setNewItem(event.target.value);
        // console.log(categoryType)
    };
    const addItem = () => {
        if (newItem) {
            setCustomerNameTypes(prevOptions => [...prevOptions, { Customer_Name: newItem }]);
            setNewItem('')

        }
    };

    const addLocationItem = () => {
        if (newItem) {
            setLocationList(prevOptions => [...prevOptions, { Location: newItem }]);
            setNewItem('')

        }
    };

    const removeItem = (e) => {
        e.preventDefault();
        setNewItem('');
    }

    return (

        <div>
            <Modal
                width="70%"
                className="pipeline-modal"
                title="Generate License"
                centered
                visible={generateLiceseVisible}
                footer={[
                    <div style={{ paddingTop: '20px', paddingRight: '10px' }} className='pipeline-btn'>

                        <Button key="cancel"
                            onClick={handleCancel}
                        >
                            Cancel
                        </Button>
                        {enableSendDeviceButton && usbDiviceType && selectedDeviceId !== '' &&
                            <div style={{ paddingLeft: '10px', paddingRight: '10px' }}>

                                <Button key="BoardStatus" type="primary" loading={SendAllLoading}
                                    onClick={async () => await onSendtoAllDevice()}
                                >
                                    Send to All Device
                                </Button>

                            </div>
                        }
                        {
                            !isEdit ?

                                <Button key="submit" type="primary" disabled={fileName === '' || uniqueId.length !== 20 || featureTypeAndDate.length <= 0 || enableSendDeviceButton || (reminderList.length > 0 && emailInternal == '' && emailExternal == '')}
                                    onClick={handleOk}
                                    style={{
                                        backgroundColor: fileName === '' || uniqueId.length !== 20 || enableSendDeviceButton || (reminderList.length > 0 && emailInternal == '' && emailExternal == '')
                                            || featureTypeAndDate.length <= 0 ? "gray" : "#ef314c",
                                    }}
                                    loading={isLicenseLoading}
                                >
                                    {isEdit ? update : generate}
                                </Button> :
                                <Button key="submit" type="primary"
                                    onClick={handleOk}
                                    style={{
                                        backgroundColor: fileName === '' || uniqueId.length !== 20
                                            || featureTypeAndDate.length <= 0 ? "gray" : "#ef314c",
                                    }}
                                    loading={isLicenseLoading}
                                >
                                    {isEdit ? update : generate}
                                </Button>
                        }
                    </div>
                ]}
            >
                <div>
                    <Form
                        form={licenseForm}
                        name="basic"
                        layout="vertical"
                        autoComplete="off"
                    >
                        <Row gutter={16}>
                            <Col lg={6}>
                                <Form.Item
                                    name="fileName"
                                    label="User Identifier"
                                    required
                                    rules={[
                                        {
                                            type: 'string',
                                            min: 1,
                                            max: 50,
                                            message: 'User Identifier must be less than 50 characters'
                                        }
                                    ]}
                                >
                                    <Input placeholder="User Identifier " style={{ height: '35px' }} value={fileName} onChange={(e) => onChangeFileName(e)} />
                                </Form.Item>
                            </Col>
                            {/* <Col lg={8}>
                                <Form.Item
                                    name="customerName"
                                    label="Customer Name"

                                >

                                    <Input placeholder="Customer Name" style={{ height: '35px' }} value={customerName} onChange={onChangeCustomerName} />
                                </Form.Item>
                            </Col> */}
                            <Col lg={6}>
                                <Form.Item name="customerName" label="Customer Name">

                                    <Select className='client-select' placeholder={'Select Customer Name'}
                                        onChange={() => onChangeCustomerName}
                                        showSearch
                                        allowClear
                                        style={{
                                            height: '30px',
                                            position: 'relative',
                                            // width: '90%',
                                        }}
                                        value={customerName}

                                        dropdownRender={menu => (
                                            <>
                                                {menu}
                                                <Divider style={{ margin: '8px 0', borderTop: '1px solid lightgrey' }} />
                                                <Space align="center" style={{ padding: '0 8px 4px' }}>
                                                    <Input placeholder="Add Customer Name" value={newItem} onChange={setAdddnewItem} style={{ width: '200px' }} />
                                                    <Typography.Link onClick={(e) => addItem(newItem)} style={{ whiteSpace: '', color: "green" }}>
                                                        <CheckOutlined />
                                                    </Typography.Link>
                                                    <Typography.Link onClick={removeItem} style={{ whiteSpace: '', color: "red" }}>
                                                        <CloseOutlined />
                                                    </Typography.Link>
                                                </Space>
                                            </>
                                        )}


                                    >

                                        {customerNameTypes &&
                                            customerNameTypes
                                                //.sort((a, b) => a.material.localeCompare(b.Contractor)) // Sort by category
                                                .map((item, i) => (
                                                    <Option key={i} value={item.Customer_Name}> {/* Use item.category as value */}
                                                        {item.Customer_Name}
                                                    </Option>
                                                ))
                                        }
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col lg={6}>
                                <Form.Item
                                    name="serialNumber"
                                    label="Machine Serial Number"
                                >
                                    <Input disabled={isEdit} placeholder="Machine Serial Number " style={{ height: '35px' }} value={serialNo} onChange={onChangeSerialNo} />
                                </Form.Item>
                            </Col>

                            <Col lg={6}>
                                <Form.Item
                                    name="Board_Serial_Number"
                                    label="Board Serial Number"
                                >
                                    <Input disabled={isEdit} placeholder="Board Serial Number" style={{ height: '35px' }}
                                        value={Board_Serial_Number} onChange={(e) => setBoard_Serial_Number(e.target.value)} />
                                </Form.Item>
                            </Col>

                            <Col lg={6}>
                                <Form.Item
                                    name="location"
                                    label="Location"

                                >

                                    {/* <Input placeholder="Location" style={{ height: '35px' }}
                                        alue={'location'} onChange={(e) => onChangeLocation(e)} /> */}


                                    <Select className='client-select' placeholder={'Select Location'}
                                        onChange={() => onChangeLocation}
                                        showSearch
                                        allowClear
                                        style={{
                                            height: '30px',
                                            position: 'relative',
                                            // width: '90%',
                                        }}
                                        value={customerName}

                                        dropdownRender={menu => (
                                            <>
                                                {menu}
                                                <Divider style={{ margin: '8px 0', borderTop: '1px solid lightgrey' }} />
                                                <Space align="center" style={{ padding: '0 8px 4px' }}>
                                                    <Input placeholder="Add Customer Name" value={newItem} onChange={setAdddnewItem} style={{ width: '200px' }} />
                                                    <Typography.Link onClick={(e) => addLocationItem(newItem)} style={{ whiteSpace: '', color: "green" }}>
                                                        <CheckOutlined />
                                                    </Typography.Link>
                                                    <Typography.Link onClick={removeItem} style={{ whiteSpace: '', color: "red" }}>
                                                        <CloseOutlined />
                                                    </Typography.Link>
                                                </Space>
                                            </>
                                        )}


                                    >

                                        {LocationList &&
                                            LocationList
                                                //.sort((a, b) => a.material.localeCompare(b.Contractor)) // Sort by category
                                                .map((item, i) => (
                                                    <Option key={i} value={item.Location}> {/* Use item.category as value */}
                                                        {item.Location}
                                                    </Option>
                                                ))
                                        }
                                    </Select>

                                </Form.Item>
                            </Col>

                            <Col lg={6}>
                                <Form.Item name="Contractor" label="Contract" className='report-radio'>
                                    <div>
                                        <Radio.Group

                                            value={Contractor}
                                            onChange={async (e) => {
                                                setContractor(e.target.value)

                                            }}>
                                            <Radio value={"Internal"}>Internal</Radio>
                                            <Radio value={"Rent"} style={{ paddingTop: '5px' }}>Rent</Radio>
                                            <Radio value={"Sales"}>Sales</Radio>

                                        </Radio.Group>
                                    </div>

                                </Form.Item>
                            </Col>


                            <Col lg={12} >
                                <div style={{ paddingTop: '0px' }}>


                                    <Checkbox style={{ fontSize: '14px' }} checked={reminderList.length > 0}
                                        onChange={(e) => onchangeRemiderchxBox(e.target.checked, ['90', '60', '30', '15'])}
                                    >Reminders </Checkbox>
                                    <Checkbox.Group style={{ display: 'block', paddingTop: '5px' }}
                                        onChange={(checkedValues) => setReminderList(checkedValues)}
                                        value={reminderList}>
                                        <Checkbox style={{ marginLeft: '20px' }} value='90'>90 Days</Checkbox>
                                        <Checkbox style={{ marginLeft: '20px' }} value='60'>60 Days</Checkbox>
                                        <Checkbox style={{ marginLeft: '20px' }} value='30'>30 days</Checkbox>
                                        <Checkbox style={{ marginLeft: '20px' }} value='15'>15 days</Checkbox>

                                    </Checkbox.Group>
                                </div>
                            </Col>

                            { reminderList.length > 0 &&


                                <Col lg={6} >
                                    <Form.Item
                                        label="Internal Emails"
                                        name='Internal_Emails'
                                        required={reminderList.length > 0 && emailExternal == '' ? true : false}
                                        style={{ marginBottom: 0 }}

                                    >
                                        <Input
                                            // disabled
                                            value={emailInternal}
                                            onChange={(e) => setEmailInternal(e.target.value.toLowerCase())}
                                            size="large"
                                            placeholder='Enter emails followed by comma (,)'
                                        // onKeyDown={onKeyDown}
                                        />

                                    </Form.Item>
                                    <span style={{ fontSize: '12px', position: 'relative', left: '10px', color: 'gray' }}>eg: 1234@mail.com, 234@email.com</span>
                                </Col>
                            }
                            {Contractor != 'Internal' && reminderList.length > 0 && 
                                <Col lg={6} >
                                    <Form.Item
                                        label="External Emails"
                                        name='External_Emails'
                                        required={reminderList.length > 0 && emailInternal == '' ? true : false}
                                        style={{ marginBottom: 0 }}

                                    >
                                        <Input
                                            // disabled
                                            value={emailExternal}
                                            onChange={(e) => setEmailExternal(e.target.value.toLowerCase())}
                                            size="large"
                                            placeholder='Enter emails followed by comma (,)'
                                        // onKeyDown={onKeyDown}
                                        />

                                    </Form.Item>
                                    <span style={{ fontSize: '12px', position: 'relative', left: '10px', color: 'gray' }}>eg: 1234@mail.com, 234@email.com</span>
                                </Col>
                            }

                            <Col lg={24}>
                                <div style={{ marginBottom: '10px' }}>
                                    <div className='heatp-bottom-border'>
                                        &nbsp;
                                    </div>
                                </div>
                            </Col>

                            <Col lg={6}>
                                <Form.Item
                                    name="uniqueId"
                                    label="Unique Id"
                                    required
                                    rules={[{
                                        pattern: true
                                            ? UNIQUEID_PATTERN
                                            : undefined,
                                        message: 'Please Provide Alpha and Numeric Letters to proceed!',
                                    },
                                    {
                                        type: 'string',
                                        min: 20,
                                        max: 20,
                                        message: 'ID must be exactly 20 characters'
                                    }
                                    ]}
                                >
                                    <Input disabled={isEdit} placeholder="Enter Board Unique Id" style={{ height: '35px' }} value={uniqueId} onChange={(e) => onChangeUniqueId(e)} />
                                </Form.Item>
                            </Col>
                            <Col lg={4}>
                                <Form.Item
                                    name="application_type"
                                    label="Machine Type"
                                    required
                                >
                                    <Select
                                        disabled={isEdit || isAvialableUniqueId}
                                        className='client-select'
                                        placeholder='Select Machine Type'
                                        defaultValue={applicationType}
                                        //  allowClear
                                        optionFilterProp="children"
                                        required
                                        style={{
                                            height: '30px',
                                            position: 'relative',
                                            // width: '90%',
                                        }}
                                        value={applicationType}
                                        options={application_types}
                                        onChange={(value) => setApplicationType(value)}

                                    >

                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col lg={6}>
                                <Form.Item
                                    name="board_type"
                                    label="Board Type"
                                    required
                                >
                                    <Select
                                        disabled={isEdit || isAvialableUniqueId && applicationType != 'P625'}
                                        className='client-select'
                                        placeholder='Select Board Type'
                                        defaultValue={boardType}
                                        //  allowClear
                                        optionFilterProp="children"
                                        required
                                        style={{
                                            height: '30px',
                                            position: 'relative',
                                            // width: '90%',
                                        }}
                                        value={boardType}
                                        options={applicationType === 'P625' ? boardType625_List : boardType_List}
                                        onChange={(value) => setBoardType(value)}

                                    >

                                    </Select>
                                </Form.Item>
                            </Col>

                            <Col lg={8}>
                                <Form.Item
                                    name="device_in"
                                    label="Connected devices"

                                >

                                    <div style={{
                                        border: '1px solid #d9d9d9',
                                        padding: '5px',
                                        borderRadius: '5px',
                                        display: 'flex',
                                        alignItems: 'center',  // Center vertically
                                        marginTop: '-8px'
                                    }}>

                                        <Select
                                            disabled={isEdit}
                                            className='client-select'
                                            placeholder='Select device'

                                            optionFilterProp="children"
                                            required
                                            style={{
                                                height: '30px',
                                                position: 'relative',
                                                marginBottom: '10px'
                                                //width: '250px',
                                                // width: '270px',
                                            }}
                                            value={selectedDeviceId}
                                            options={usbDiviceType}
                                            onChange={(value) => onChangesUsbDevice(value)}

                                        >
                                            {usbDiviceType &&
                                                usbDiviceType.map((item, i) =>
                                                    <Select.Option key={i} value={item && item.value}>{item && item.value}</Select.Option>)
                                            }

                                        </Select>
                                        <div className='pipeline-btn' style={{ flex: '0 0 30%', paddingLeft: '5px' }}>
                                            <Button key="submit" type="primary" style={{ borderRadius: '20px', height: '35px' }}
                                                onClick={onRequestNewPort}
                                            >

                                                Connect Device
                                            </Button>
                                        </div>
                                    </div>

                                </Form.Item>
                            </Col>
                            {/* <Col lg={8}>
                                <Form.Item
                                    name="Adddevice_in"

                                    style={{ paddingTop: '32px', paddingLeft: '10px' }}
                                >
                                    <div className='pipeline-btn'>
                                        <Button key="submit" type="primary" style={{ borderRadius: '20px', height: '35px' }}
                                            onClick={onRequestNewPort}
                                        >

                                            Connect Device
                                        </Button>
                                    </div>
                                </Form.Item>
                            </Col> */}
                            <Col lg={24}>
                                <div style={{ marginTop: '-35px' }}>
                                    <div className='heatp-bottom-border'>
                                        &nbsp;
                                    </div>
                                </div>
                            </Col>

                        </Row>
                        <div>
                            <Form.Item style={{ width: '100%' }}
                                name="featureType"
                                label="Feature Types"
                                required
                            >

                                {chxList[0] &&
                                    <>
                                        <Checkbox style={{ border: '5px' }} name={chxList[0]} disabled={true} checked={forthValue}
                                            onChange={(e) => onchangechxBox(e.target.checked, chxList[0])} >

                                            <Text> {
                                                isEdit ? getTitle(chxList[0]) : chxList[0]

                                            }</Text>
                                        </Checkbox>
                                        {forthValue && enableSendDeviceButton && usbDiviceType && selectedDeviceId !== '' &&
                                            <Button type="primary" style={{ borderRadius: '20px', height: '35px', paddingLeft: '10px' }}
                                                onClick={(e) => onValidateBoard(chxList[0], value4, expireDate4)}>
                                                Send License to Device
                                            </Button>
                                        }
                                        <div style={{ paddingLeft: '15px' }} className='date-picker'>
                                            {forthValue &&
                                                <Form.Item style={{ paddingTop: '10px', cursor: 'default' }}
                                                    label='Expiration Date :'
                                                    className='report-radio'

                                                    name={chxList[0]}
                                                >
                                                    <div style={{ flexDirection: 'column' }}>


                                                        <>
                                                            <Radio.Group name={chxList[0]} style={{ display: "inline-flex" }}
                                                                onChange={(e) => onChangeFeatureExpireDate(e.target.value, chxList[0])}
                                                                value={value4}
                                                            >

                                                                {isEdit && chxList[0] !== motorApplicationType[0] &&
                                                                    <div>
                                                                        <Radio value={1} >1 year </Radio>
                                                                        <Radio value={2} >2 years</Radio>
                                                                        <Radio value={3} >3 years </Radio>
                                                                        <Radio value={5} >5 years </Radio>
                                                                    </div>
                                                                }
                                                                <Radio value={99}>Perpetual</Radio>

                                                            </Radio.Group>
                                                        </>

                                                        {isEdit && chxList[0] !== motorApplicationType[0] &&
                                                            <DatePicker name={chxList[0]} style={{ width: '250px' }}
                                                                format="DD MMM YYYY"
                                                                value={expireDate4}
                                                                onChange={(date, dateString) => onChangeFeatureDates(chxList[0], dateString)}
                                                                // disabledDate={current => expirationDate && current > moment(expirationDate, "DD-MM-YYYY")}
                                                                //disabledDate={current => current && current && current < moment().subtract(1, "days")}
                                                                disabledDate={
                                                                    current => !current
                                                                        || current < moment().subtract(1, "days")

                                                                        || current > moment('2099-01-01', 'YYYY-MM-DD')

                                                                }
                                                            />
                                                        }

                                                    </div>

                                                </Form.Item>

                                            }
                                        </div>
                                    </>
                                }


                                {chxList[1] &&
                                    <>
                                        <Checkbox style={{ border: '5px' }} name={chxList[1]} disabled={isEdit} checked={firstValue} onChange={(e) => onchangechxBox(e.target.checked, chxList[1])} >
                                            <Text> {chxList[1]}</Text>
                                        </Checkbox>
                                        {firstValue && enableSendDeviceButton && usbDiviceType && selectedDeviceId !== '' &&
                                            <Button type="primary" style={{ borderRadius: '20px', height: '35px', paddingLeft: '10px' }}
                                                onClick={(e) => onValidateBoard(chxList[1], value1, expireDate1)}>
                                                Send License to Device
                                            </Button>
                                        }
                                        <div style={{ paddingLeft: '15px' }} className='date-picker'>
                                            {firstValue &&
                                                <Form.Item style={{ paddingTop: '10px', cursor: 'default' }}
                                                    label='Expiration Date :'
                                                    className='report-radio'

                                                    name={chxList[1]}
                                                >
                                                    <div style={{ flexDirection: 'column' }}>

                                                        <>
                                                            <Radio.Group name={chxList[1]} style={{ display: "inline-flex" }}
                                                                onChange={(e) => onChangeFeatureExpireDate(e.target.value, chxList[1])}
                                                                value={value1}
                                                            >
                                                                <Radio value={1} >1 year </Radio>
                                                                <Radio value={2} >2 years</Radio>
                                                                <Radio value={3} >3 years </Radio>
                                                                <Radio value={5} >5 years </Radio>
                                                                <Radio value={99}>Perpetual</Radio>

                                                            </Radio.Group>
                                                        </>


                                                        <DatePicker name={chxList[1]} style={{ width: '250px' }}
                                                            format="DD MMM YYYY"
                                                            value={expireDate1}
                                                            onChange={(date, dateString) => onChangeFeatureDates(chxList[1], dateString)}
                                                            // disabledDate={current => expirationDate && current > moment(expirationDate, "DD-MM-YYYY")}
                                                            // disabledDate={current => current && current && current < moment().subtract(1, "days")}
                                                            disabledDate={
                                                                current => !current
                                                                    || current < moment().subtract(1, "days")

                                                                    || current >= moment('2099-01-01', 'YYYY-MM-DD')

                                                            }

                                                        />

                                                    </div>

                                                </Form.Item>

                                            }
                                        </div>
                                    </>
                                }
                                {chxList[2] &&
                                    <>
                                        <Checkbox name={chxList[2]} disabled={isEdit} onChange={(e) => onchangechxBox(e.target.checked, chxList[2])} >
                                            <Text> {chxList[2]}</Text>
                                        </Checkbox>
                                        {secondValue && enableSendDeviceButton && usbDiviceType && selectedDeviceId !== '' &&
                                            <Button type="primary" style={{ borderRadius: '20px', height: '35px', paddingLeft: '10px' }}
                                                onClick={(e) => onValidateBoard(chxList[2], value2, expireDate2)}>
                                                Send License to Device
                                            </Button>
                                        }
                                        <div style={{ paddingLeft: '15px' }} className='date-picker'>
                                            {
                                                secondValue &&
                                                <Form.Item style={{ paddingTop: '10px', cursor: 'default' }}
                                                    label='Expiration Date :'
                                                    className='report-radio'
                                                    name={chxList[2]}
                                                >
                                                    <div style={{ flexDirection: 'column' }}>

                                                        <>
                                                            <Radio.Group style={{ display: "inline-flex" }}
                                                                onChange={(e) => onChangeFeatureExpireDate(e.target.value, chxList[2])}
                                                                value={value2}
                                                            >
                                                                <Radio value={1} >1 year </Radio>
                                                                <Radio value={2} >2 years</Radio>
                                                                <Radio value={3} >3 years </Radio>
                                                                <Radio value={5} >5 years </Radio>
                                                                <Radio value={99}>Perpetual</Radio>


                                                            </Radio.Group>
                                                        </>


                                                        <DatePicker name={chxList[2]} style={{ width: '250px' }}
                                                            format="DD MMM YYYY"
                                                            value={expireDate2}
                                                            onChange={(date, dateString) => onChangeFeatureDates(chxList[2], dateString)}
                                                            // disabledDate={current => expirationDate && current > moment(expirationDate, "DD-MM-YYYY")}
                                                            // disabledDate={current => current && current && current < moment().subtract(1, "days")}
                                                            disabledDate={
                                                                current => !current
                                                                    || current < moment().subtract(1, "days")

                                                                    || current > moment('2099-01-01', 'YYYY-MM-DD')

                                                            }
                                                        />

                                                    </div>

                                                </Form.Item>

                                            }
                                        </div>
                                    </>
                                }
                                {chxList[3] &&
                                    <>
                                        <Checkbox name={chxList[3]} disabled={isEdit} onChange={(e) => onchangechxBox(e.target.checked, chxList[3])} >
                                            <Text> {chxList[3]}</Text>
                                        </Checkbox>
                                        {thirdValue && enableSendDeviceButton && usbDiviceType && selectedDeviceId !== '' &&
                                            <Button type="primary" style={{ borderRadius: '20px', height: '35px', paddingLeft: '10px' }}
                                                onClick={(e) => onValidateBoard(chxList[3], value3, expireDate3)}>
                                                Send License to Device
                                            </Button>
                                        }
                                        <div style={{ paddingLeft: '15px' }} className='date-picker'>
                                            {
                                                thirdValue &&
                                                <Form.Item style={{ paddingTop: '10px', cursor: 'default' }}
                                                    label='Expiration Date :'
                                                    className='report-radio'
                                                    name={chxList[3]}
                                                >
                                                    <div style={{ flexDirection: 'column' }}>

                                                        <>
                                                            <Radio.Group style={{ display: "inline-flex" }}
                                                                onChange={(e) => onChangeFeatureExpireDate(e.target.value, chxList[3])}
                                                                value={value3}
                                                            >
                                                                <Radio value={1} >1 year </Radio>
                                                                <Radio value={2} >2 years</Radio>
                                                                <Radio value={3} >3 years </Radio>
                                                                <Radio value={5} >5 years </Radio>
                                                                <Radio value={99}>Perpetual</Radio>

                                                            </Radio.Group>
                                                        </>


                                                        <DatePicker name={chxList[3]} style={{ width: '250px' }}
                                                            format="DD MMM YYYY"
                                                            value={expireDate3}
                                                            onChange={(date, dateString) => onChangeFeatureDates(chxList[3], dateString)}
                                                            // disabledDate={current => expirationDate && current > moment(expirationDate, "DD-MM-YYYY")}
                                                            //disabledDate={current => current && current && current < moment().subtract(1, "days")}
                                                            disabledDate={
                                                                current => !current
                                                                    || current < moment().subtract(1, "days")

                                                                    || current > moment('2099-01-01', 'YYYY-MM-DD')

                                                            }
                                                        />

                                                    </div>

                                                </Form.Item>
                                            }
                                        </div>
                                    </>
                                }
                                {chxList[4] &&
                                    <>
                                        <Checkbox name={chxList[4]} disabled={isEdit} onChange={(e) => onchangechxBox(e.target.checked, chxList[4])} >
                                            <Text> {chxList[4] == 'Fronius PS' ? "Fronius Power supply" : chxList[4]}</Text>
                                        </Checkbox>
                                        {fithValue && enableSendDeviceButton && usbDiviceType && selectedDeviceId !== '' &&
                                            <Button type="primary" style={{ borderRadius: '20px', height: '35px', paddingLeft: '10px' }}
                                                onClick={(e) => onValidateBoard(chxList[4], value5, expireDate5)}>
                                                Send License to Device
                                            </Button>
                                        }
                                        <div style={{ paddingLeft: '15px' }} className='date-picker'>
                                            {
                                                fithValue &&
                                                <Form.Item style={{ paddingTop: '10px', cursor: 'default' }}
                                                    label='Expiration Date :'
                                                    className='report-radio'
                                                    name={chxList[4]}
                                                >
                                                    <div style={{ flexDirection: 'column' }}>

                                                        <>
                                                            <Radio.Group style={{ display: "inline-flex" }}
                                                                onChange={(e) => onChangeFeatureExpireDate(e.target.value, chxList[4])}
                                                                value={value5}
                                                            >
                                                                <Radio value={1} >1 year </Radio>
                                                                <Radio value={2} >2 years</Radio>
                                                                <Radio value={3} >3 years </Radio>
                                                                <Radio value={5} >5 years </Radio>
                                                                <Radio value={99}>Perpetual</Radio>

                                                            </Radio.Group>
                                                        </>


                                                        <DatePicker name={chxList[4]} style={{ width: '250px' }}
                                                            format="DD MMM YYYY"
                                                            value={expireDate5}
                                                            onChange={(date, dateString) => onChangeFeatureDates(chxList[4], dateString)}

                                                            // disabledDate={current => expirationDate && current > moment(expirationDate, "DD-MM-YYYY")}
                                                            // disabledDate={ (current) =>
                                                            //      (current < moment().subtract(1, "days")
                                                            //     || current > moment('2028-12-30', 'YYYY-MM-DD'))


                                                            // }
                                                            disabledDate={
                                                                current => !current
                                                                    || current < moment().subtract(1, "days")

                                                                    || current > moment('2099-01-01', 'YYYY-MM-DD')

                                                            }


                                                        />
                                                    </div>

                                                </Form.Item>
                                            }
                                        </div>
                                    </>
                                }
                                {chxList[5] &&
                                    <>
                                        <Checkbox name={chxList[5]} disabled={isEdit} onChange={(e) => onchangechxBox(e.target.checked, chxList[5])} >
                                            <Text> {chxList[5] === 'Lincoln PS' ? 'Lincoln Power supply' : chxList[5]}</Text>
                                        </Checkbox>
                                        {sixthValue && enableSendDeviceButton && usbDiviceType && selectedDeviceId !== '' &&
                                            <Button type="primary" style={{ borderRadius: '20px', height: '35px', paddingLeft: '10px' }}
                                                onClick={(e) => onValidateBoard(chxList[5], value6, expireDate6)}>
                                                Send License to Device
                                            </Button>
                                        }
                                        <div style={{ paddingLeft: '15px' }} className='date-picker'>
                                            {
                                                sixthValue &&
                                                <Form.Item style={{ paddingTop: '10px', cursor: 'default' }}
                                                    label='Expiration Date :'
                                                    className='report-radio'
                                                    name={chxList[5]}
                                                >
                                                    <div style={{ flexDirection: 'column' }}>

                                                        <>
                                                            <Radio.Group style={{ display: "inline-flex" }}
                                                                onChange={(e) => onChangeFeatureExpireDate(e.target.value, chxList[5])}
                                                                value={value6}
                                                            >
                                                                <Radio value={1} >1 year </Radio>
                                                                <Radio value={2} >2 years</Radio>
                                                                <Radio value={3} >3 years </Radio>
                                                                <Radio value={5} >5 years </Radio>
                                                                <Radio value={99}>Perpetual</Radio>

                                                            </Radio.Group>
                                                        </>


                                                        <DatePicker name={chxList[5]} style={{ width: '250px' }}
                                                            format="DD MMM YYYY"
                                                            value={expireDate6}
                                                            onChange={(date, dateString) => onChangeFeatureDates(chxList[5], dateString)}

                                                            // disabledDate={current => expirationDate && current > moment(expirationDate, "DD-MM-YYYY")}
                                                            // disabledDate={ (current) =>
                                                            //      (current < moment().subtract(1, "days")
                                                            //     || current > moment('2028-12-30', 'YYYY-MM-DD'))


                                                            // }
                                                            disabledDate={
                                                                current => !current
                                                                    || current < moment().subtract(1, "days")

                                                                    || current > moment('2099-01-01', 'YYYY-MM-DD')

                                                            }


                                                        />
                                                    </div>

                                                </Form.Item>
                                            }
                                        </div>
                                    </>
                                }

                            </Form.Item>
                        </div>
                    </Form>
                </div>
            </Modal >
        </div >

    );
};

const mapStateToProps = ({ licenseMangerReducer }) => {
    const licenseData = get(licenseMangerReducer, 'LicenseDataUnikey', []);
    const isLicenseLoading = get(licenseMangerReducer, 'isGenerateLicense', false);
    const isFetchLincenseData = get(licenseMangerReducer, 'isFetchLincenseData', false);
    //const isUploadLicsense = get(licenseMangerReducer, 'isUploadLicsense', false);
    const licenseTotalCount = get(licenseMangerReducer, 'LicenseListCount', null);
    const licblk = get(licenseMangerReducer, 'FetchUniqueId', null);
    return {
        licenseData,
        isLicenseLoading,
        licblk,
        isFetchLincenseData,
        //isUploadLicsense,
        licenseTotalCount
    }
}


const mapDispatchToProps = {
    ongeneratelicense: generateLicenseaction,
    onFetchLicenseData: fetchLicenseList,
    onFetchUniqueId: fetchUniqueId

};

export default connect(mapStateToProps, mapDispatchToProps)(GenerateLicenseForm);

