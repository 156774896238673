/* eslint-disable no-param-reassign */
/* eslint-disable react/no-unescaped-entities */
import { ArrowLeftOutlined, CloudUploadOutlined, DeleteOutlined, EditOutlined, EyeOutlined, FilterOutlined, RightCircleTwoTone, FilePdfOutlined, DownCircleTwoTone } from '@ant-design/icons';
import {
    Button, Form,
    Input,
    Modal,
    Space,
    Table,
    Tabs,
    Tooltip,
    Typography, Popover,
    Upload, notification, DatePicker, Drawer, Select, Layout
} from 'antd';
import { IonIcon } from "@ionic/react";
import { closeOutline, cameraOutline, diamondOutline, chatbubbleOutline, alarmOutline, ellipsisHorizontal, ellipsisVertical, eyeOutline, createOutline, downloadOutline } from "ionicons/icons";
import { get } from 'lodash';
import moment from 'moment';
import React, { useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';
import ExcelJS from 'exceljs';
import * as XLSX from 'xlsx';
import history from '../../services/history';
import { authenticateCurrentUser } from '../../services/auth';
import ProjectModal from './ProjectModal';
import PricingUpdateForm from './PricingUpdateForm';
import { fetchPricingdList, fetchPricingListData } from '../../redux/Pricing/action';
import MasteForm from './MasteForm';
import { DeletePricingDetails, getCategoryListFromDatabse, getDiameterListFromDatabse, getFilterCategoryListFromDatabse } from '../../redux/Pricing/saga';
import { Content, Footer } from 'antd/lib/layout/layout';
// import NavigationMenu from './Navigationmenu';
import CollapsibleTable from './CollapsibleTable';


const { Option } = Select;
const { Title, Text } = Typography;
const { TabPane } = Tabs;
//const { TabPane } = Tabs;
//const { Column } = Table;
//const { Search } = Input;

//const { Option } = Select;
const PricingPage = (props) => {

    const {
        projectList,
        isLoading,
        listCount,
        listTotalCount,
        onPricingList,
        projectListData,
        isPricingDataLoading,
        listDataCount,
        listDataTotalCount,
        
    } = props;


    const [isModalVisible, setIsModalVisible] = useState(false);
    const [isMasterModalVisible, setIsMasterModalVisible] = useState(false);
    const [currentPageNumber, setCurrentPageNumber] = useState(1);
    const [currentPagesize, setCurrentPagesize] = useState(10);
    const [isEdit, setIsEdit] = useState(false);
    const [editRecord, seteditRecord] = useState('');
    const [projectDetailForm] = Form.useForm()
    const [searchCriteria, setSearchCriteria] = useState({});
    const [selectdTypeFilter, setSelectdFilterType] = useState({});
    const [searchFilterType, setSearchFilterType] = useState({});
    const [categoryList, setCategoryList] = useState({});
    const [isDeleteModalVisible, setIsDeleteModalVisible] = useState(false);
    const [selectedData, setSelectedData] = useState('');
    const [isDeleteLoading, setIsDeleteLoading] = useState(false)
    const [isDrawerVisible, setIsDrawerVisible] = useState(false);
    const [generateReportLoading, setGenerateReportLoading] = useState(false);
    const [projectNameCategory, setProjectNameCategory] = useState([]);
    const [exportLoading, setExportLoading] = useState(false);
    const [uploadLoading, setUploadLoading] = useState(false)
    const [bulkUplaodModal, setBulkUplaodModal] = useState(false);
    const [fileList, setFileList] = useState([]);
    const [disableFileUpload, setDisableFileUpload] = useState(false);
    const [filterCategoryList, setFilterCategoryList] = useState({

    });
    const [isfiltervisble, setfilisfiltervisble] = useState(false);
    const [isRead, setIsRead] = useState(false);
    const [prevPageNumber, setPrevPageNumber] = useState(1);
    const [prevPagesize, setPrevPagesize] = useState(10);
    const [searchType, setSearchType] = useState({});

    const [userName, setUserName] = useState('');
    const [userGroup, setUserGroup] = useState([]);

    const [activeKey, setActiveKey] = useState('1')
    const [activeKeyLine, setActiveKeyLine] = useState('1')
    const [selectedTap, setSelectedTap] = useState('Log');
    const [tapsList, setTaplist] = useState(['Log', 'Analysis']);
    const [activeRow, setActiveRow] = useState(null);

    const [showPricingPage, setShowPricingPage] = useState(false);
    const [projectDetails, setProjectDetails] = useState({});
    const [diameterList, setDiameterList] = useState([])
    const [equipmentType, setEquipmentType] = useState('IWM');

    const [categoryListdata, setCategoryListdata] = useState({
        Project_Name: [],
        Contractor: [],
        Spreads_List: [],
        Countries_List: [],
        diameterList: [],
        Pipe_Grade_List: [],
        Representative_List: [],
        Spread_Config_List: [],
       
    });

    // const [activeRow, setActiveRow] = useState(null);
    const popupRef = useRef(null);

    // Close the popup when clicking outside
    useEffect(() => {
        const handleClickOutside = (event) => {
            if (popupRef.current && !popupRef.current.contains(event.target)) {
                setActiveRow(null);
            }
        };

        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [popupRef]);

    useEffect(() => {
        if (ProjectModal) {
            (async () => {
                const category = await getCategoryListFromDatabse();
                if (category) {
                    setCategoryListdata(category)
                }
                const dia = await getDiameterListFromDatabse(equipmentType);
                if (dia) {
                    setDiameterList(dia.Size_Parts)
                }
            })();
        }
    }, [])

    // useEffect(()=>{
    //     onfetchPricingListData()
    // },[])


    const UpdateDiameterList = async (equipmentType) => {
        const dia = await getDiameterListFromDatabse(equipmentType);
        if (dia) {
            setDiameterList(dia.Size_Parts)
        }
    }

    const columns = [

        {
            title: 'Quote No.',
            dataIndex: 'Proposal_Number',
            key: 'Proposal_Number',
            align: 'center',
            render: (text) => <a>{text}</a>,
        },
        {
            title: 'Date',
            dataIndex: 'Created_Date',
            key: 'Created_Date',
            align: 'center',
            render: (text) => {
                return (
                    <div>{text ? moment(text).format('DD MMM YYYY') : '-'}</div>
                );
            },
        },

        {
            title: 'Project',
            dataIndex: 'Project_Name',
            key: 'Project_Name',
            align: 'center',

        },
        {
            title: 'Representative',
            dataIndex: 'Representative',
            key: 'Representative',
            align: 'center',
        },


    ];
    const formatCurrency = (value) => {
        return value ? `$${parseFloat(value).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}` : '';
    };

    const columns_Expands = [
        {
            title: '',
            dataIndex: 'edit_delete',
            key: 'edit_delete',
            align: 'left',
            fixed: 'left',
            width: '2%',
            render: (text, record) => {
                const popoverContent = (
                    <div className='NavigationMenu'>
                        <div className="icon-grid">
                            <Tooltip title="Update">
                                    <EditOutlined 
                                        style={{ fontSize: '20px',paddingRight:'6px' }} 
                                        onClick={() => onUpdateClick(record)} 
                                    />
                            </Tooltip>
                            {record.Url &&
                                <Tooltip title="Download report file">
                                    <Button 
                                        href={record.Url} 
                                        className='border-0' 
                                        size='small' 
                                        target='_blank'
                                    >
                                        <FilePdfOutlined style={{color:'#EF314C', fontSize: '18px',paddingRight:'6px'}}/>
                                    </Button>
                                </Tooltip>
                            }
                            <Tooltip title="Delete">
                                    <DeleteOutlined style={{ fontSize: '20px' }} />
                            </Tooltip>
                        </div>
                    </div>
                );
        
                return (
                    <Popover
                        content={popoverContent}
                        trigger={['click', 'hover']}
                        placement="right"
                        className='popover-column'
                    >
                        <IonIcon icon={ellipsisVertical} />
                    </Popover>
                );
            },
        },
        {
            title: 'Quote No.',
            dataIndex: 'Proposal_Number',
            key: 'Proposal_Number',
            align: 'center',
            render: (text, record) => <div style={{ cursor: 'pointer' }} onClick={() => onReadParts(record)}>{text}</div>,
        },
        {
            title: 'Project',
            dataIndex: 'Project_Name',
            key: 'Project_Name',
            align: 'center',
            width: '10%',
            render: (text, record) => <div style={{ cursor: 'pointer' }} onClick={() => onReadParts(record)}>{text}</div>,
        },
        {
            title: 'Representative',
            dataIndex: 'Representative',
            key: 'Representative',
            align: 'center',
            render: (text, record) => <div style={{ cursor: 'pointer' }} onClick={() => onReadParts(record)}>{text}</div>,
        },

        {
            title: 'Contractor',
            dataIndex: 'Contractor',
            key: 'Contractor',
            align: 'center',
            render: (text, record) => <div style={{ cursor: 'pointer' }} onClick={() => onReadParts(record)}>{text}</div>,
        },
        {
            title: 'Status',
            dataIndex: 'Status',
            key: 'Status',
            align: 'center',
            render: (text, record) => <div style={{ cursor: 'pointer' }} onClick={() => onReadParts(record)}>{text}</div>,
        },


        {
            title: 'Spread',
            dataIndex: 'Spreads',
            key: 'Spreads',
            align: 'center',
            render: (text, record) => <div style={{ cursor: 'pointer' }} onClick={() => onReadParts(record)}>{text}</div>,

        },
        {
            title: 'Equipment',
            dataIndex: 'Equipment_Type',
            key: 'Equipment_Type',
            align: 'center',
            render: (text, record) => <div style={{ cursor: 'pointer' }} onClick={() => onReadParts(record)}>{text}</div>,
        },

        {
            title: 'Length',
            dataIndex: 'Length_in_Kms',
            key: 'Length_in_Kms',
            align: 'center',
            render: (text, record) => <div style={{ cursor: 'pointer' }} onClick={() => onReadParts(record)}>{text}</div>,
        },
        {
            title: 'Welds',
            dataIndex: 'No_of_Joints',
            key: 'No_of_Joints',
            align: 'center',
            render: (text, record) => <div style={{ cursor: 'pointer' }} onClick={() => onReadParts(record)}>{text}</div>,
        },
        {
            title: 'Diameter',
            dataIndex: 'Pipe_OD',
            key: 'Pipe_OD',
            align: 'center',
            render: (text, record) => <div style={{ cursor: 'pointer' }} onClick={() => onReadParts(record)}>{text}</div>,
        },
        {
            title: 'Total Revenue',
            dataIndex: 'Total Revenue',
            key: 'Total Revenue',
            align: 'center',
            render: (text, record) => {
                const totalCost = record?.Summary?.Summary?.['Revenue']?.['Total Revenue'];
                // return totalCost !== undefined ? formatCurrency(totalCost) : '';
                <div style={{ cursor: 'pointer' }} onClick={() => onReadParts(record)}>{totalCost !== undefined ? formatCurrency(totalCost) : ''}</div>
            }

        },
        {
            title: 'Total Cost',
            dataIndex: 'Total Cost',
            key: 'Total Cost',
            align: 'center',
            render: (text, record) => {
                const totalCost = record?.Summary?.Summary?.['Cost']?.['Total Cost'];
                // return totalCost !== undefined ? formatCurrency(totalCost) : '';
                <div style={{ cursor: 'pointer' }} onClick={() => onReadParts(record)}>{totalCost !== undefined ? formatCurrency(totalCost) : ''}</div>
            }

        },
        {
            title: 'Gross Margin',
            dataIndex: 'margin',
            key: 'margin',
            align: 'center',
            render: (text, record) => {
                const totalMargin = record?.Summary?.Summary?.['Margin']?.['Total'];
                // return totalMargin !== undefined ? `${(totalMargin * 100).toFixed(2)}%` : '';
                <div style={{ cursor: 'pointer' }} onClick={() => onReadParts(record)}>{totalMargin !== undefined ? `${(totalMargin * 100).toFixed(2)}%` : ''}</div>
            }
        },

        {
            title: 'Estimated start',
            dataIndex: 'EstimatedStart',
            key: 'EstimatedStart',
            align: 'center',
            render: (text, record) => <div style={{ cursor: 'pointer' }} onClick={() => onReadParts(record)}>{text}</div>,
        },
        // {
        //     title: '',
        //     dataIndex: 'edit_delete',
        //     key: 'edit_delete',
        //     align: 'left',
        //     fixed: 'right',
        //     width: '2%',
        //     render: (text, record) => {
        //         // The content inside the Popover (navigation icons)
        //         const popoverContent = (
        //             <div className='NavigationMenu'>

        //                 <Tooltip title="View" >
        //                     <span className="icon-18 mr-2 cursor-pointer edit-hover" style={{ marginRight: '20px', }} >
        //                         <EyeOutlined style={{ fontSize: '18px' }}
        //                              onClick={() => onReadParts(record)}
        //                         />
        //                     </span>
        //                 </Tooltip>
        //                 <Tooltip title="Update">
        //                     <span className="icon-18 mr-2 cursor-pointer edit-hover">
        //                         <EditOutlined style={{ fontSize: '18px' }} onClick={() => onUpdateClick(record)} />
        //                     </span>
        //                 </Tooltip>
        //                 {record.Url &&
        //                     <Tooltip title="Download report file" >

        //                         <Button href={record.Url} className='p-0 border-0' size='small' target='_blank' style={{ marginLeft: '20px' }}>
        //                             <FilePdfOutlined  />
        //                         </Button>

        //                     </Tooltip>
        //                 }
        //                 <Tooltip title="Delete">
        //                     <span className="icon-18 mr-2 cursor-pointer edit-hover" style={{ marginLeft: '20px' }}>
        //                         <DeleteOutlined style={{ fontSize: '18px' }}/>
        //                     </span>
        //                 </Tooltip>

        //             </div>
        //         );

        //         return (
        //             <>
        //                 {/* Popover Component to show content on click or hover */}
        //                 <Popover
        //                     content={popoverContent}   // Navigation icons inside the popover
        //                     trigger={['click', 'hover']} // Show popover on click or hover
        //                     placement="top" // Popover appears below the button
        //                 >
        //                     {/* Button with ellipsis icon */}

        //                     <IonIcon icon={ellipsisVertical} />

        //                 </Popover>
        //             </>
        //         );
        //     },
        // },
    ];

    const onReadParts = async (record) => {

        if (record) {
            //console.log('read', record)
            setProjectDetails(record)
            seteditRecord(record)
            setIsEdit(true)
            setIsRead(true)
            setShowPricingPage(true)
        }

    }
    const onUpdateClick = (record) => {

        // projectDetailForm.resetFields()
        seteditRecord(record)
        setProjectDetails(record)
        projectDetailForm.setFieldsValue(record)
        setIsEdit(true)
        setIsModalVisible(true)
    }
    /** get user Access */
    useEffect(() => {
        authenticateCurrentUser()
            .then(async (data) => {
                if (data.signInUserSession.accessToken.payload["cognito:groups"]) {

                    let response = data.signInUserSession.accessToken.payload["cognito:groups"] //await getA(data.username)
                    //const userAccess = response.map(item => item?.GroupName)
                    //console.log(response)
                    const userEmail = JSON.parse(localStorage.getItem('user')).email
                    setUserName(userEmail);
                    setUserGroup(response);
                }
            })
            .catch((error) => {
                console.log('error', error);
            });
    }, [history]);

    useEffect(() => {
        const onfetchList = async () => {
            var obj = {
                pageSize: currentPagesize,
                pageNumber: currentPageNumber,
                searchKey: searchCriteria

            }
            await onPricingList(obj);
        }
        if (!showPricingPage)
            onfetchList();
    }, [showPricingPage])
    function hasNonEmptyTag(jsonObj) {
        for (const key in jsonObj) {
            if (jsonObj.hasOwnProperty(key)) {
                const value = jsonObj[key];


                if (Array.isArray(value) && value.length > 0) {
                    return true;
                } else if (typeof value === 'string' && value.trim() !== '') {
                    return true;
                }
            }
        }
        return false;
    }

    useEffect(() => {

        // var obj = {
        //     pageSize: currentPagesize,
        //     pageNumber: 1,
        //     searchKey: searchCriteria
        // }
        // setCurrent(1)
        // onProjectList(obj)


      //  console.log('seached ');

        (async () => {
            const isvalid = hasNonEmptyTag(searchCriteria)
            let obj = {
                pageSize: isvalid ? currentPagesize : prevPagesize,
                pageNumber: isvalid ? 1 : prevPageNumber,
                searchKey: searchCriteria
            };

          //  console.log(obj);

            // setSelectedMaterial([])
            if (isvalid) {
                setPrevPagesize(currentPagesize)
                setPrevPageNumber(currentPageNumber)
            }
            setCurrentPageNumber(obj.pageNumber)
            await await onPricingList(obj);
        })();


    }, [searchCriteria]);

    // useEffect(() => {
    
    //     if (!isModalVisible) {
           
    //     }

    // }, [isModalVisible])

    const onGeneratedProject = ()=>{
        var obj = {
            pageSize: currentPagesize,
            pageNumber: currentPageNumber,
            searchKey: searchCriteria

        }
       onPricingList(obj);
       onClearFilters();
     }

    const handleTabChange = (key) => {

        setActiveKeyLine(key);
        setSelectedTap(tapsList[Number(key) - 1])

    };
    const showModal = () => {
        projectDetailForm.resetFields();
        setIsModalVisible(true);
    };

    const handleCancel = async() => {
       await onClearFilters
        setIsModalVisible(false);
        setIsEdit(false)
    };
    const expandedRowRender = (record) => {
        // Customize the content inside the expanded row (nested group)

        return (
            record.project_History ?
                <div className='addTeamDetails'>
                    <Table showHeader={true}
                        className="h-100 pipeline-table equip_table equipPaginationTable"

                        rowKey={'ID'}
                        columns={columns_Expands} // Exclude the first two columns (Category and Subcategory)
                        dataSource={[...record.project_History]}
                        pagination={false}
                    // rowClassName={(nestedRecord, nestedIndex) =>
                    //     nestedIndex % 2 === 0 ? 'even-row' : 'odd-row'
                    // }
                    />
                </div>
                : null
        );
    };


    const showMasterModal = () => {
        setIsMasterModalVisible(true);
    };

    const filtermaping = [

        {
            title: 'Project',
            dataIndex: 'Project_Name',
            key: 'Project_Name',
            align: 'center',

        },
        {
            title: 'Contractor',
            dataIndex: 'Contractor',
            key: 'Contractor',
            align: 'center',
        },
        {
            title: 'Spreads',
            dataIndex: 'Spreads',
            key: 'Spreads',
            align: 'Spreads',
        },
        {
            title: 'Proposal Type',
            dataIndex: 'Source_Type',
            key: 'Source_Type',
            align: 'Source_Type',
        },
        {
            title: 'Quote No.',
            dataIndex: 'Proposal_Number',
            key: 'Proposal_Number',
            align: 'center',
        },

        {
            title: 'Representative',
            dataIndex: 'Representative',
            key: 'Representative',
            align: 'center',
        },
        {
            title: 'Equipment',
            dataIndex: 'Equipment_Type',
            key: 'Equipment_Type',
            align: 'center',

        },

        {
            title: 'Length',
            dataIndex: 'Length_in_Kms',
            key: 'Length_in_Kms',
            align: 'center',

        },
        {
            title: 'Welds',
            dataIndex: 'No_of_Joints',
            key: 'No_of_Joints',
            align: 'center',
        },
        {
            title: 'Diameter',
            dataIndex: 'Pipe_OD',
            key: 'Pipe_OD',
            align: 'center',
        },

        {
            title: 'Estimated start',
            dataIndex: 'EstimatedStart',
            key: 'EstimatedStart',
            align: 'center',
        },
        {
            title: 'Status',
            dataIndex: 'Status',
            key: 'Status',
            align: 'Status',
        },
    ]
    const onClearFilters = async () => {
       // console.log('selected typefilter',selectdTypeFilter)
        if (selectdTypeFilter)
            filtermaping.map(async column => {
                if (column.dataIndex !== 'edit_delete') {
                    if (selectdTypeFilter[column.dataIndex] && selectdTypeFilter[column.dataIndex]?.length > 0)
                        await handleComboboxChange(column.dataIndex, []);
                }
            });

    }
    const showDrawer = () => {
        setIsDrawerVisible(true);
    };

    const closeDrawer = () => {
        setIsDrawerVisible(false);
    };

    const handleSearchInputChange = (fieldName, value) => {
       
        setSearchCriteria(prevSearchCriteria => ({
            ...prevSearchCriteria,
            [fieldName]: value
        }));
    }
    const handleTypeSearchInputChange = (fieldName, value) => {
        setSearchType(prevSearchCriteria => ({
            ...prevSearchCriteria,
            [fieldName]: value
        }));
    }
    const handleComboboxChange = async (fieldName, value) => {

        setSearchFilterType(prevSearchtype => ({
            ...prevSearchtype,
            [fieldName]: ''
        }));
        setSelectdFilterType(prevSelectedtype => ({
            ...prevSelectedtype,
            [fieldName]: value
        }));


        await handleTypeSearchInputChange(fieldName, value)
        await handleSearchInputChange(fieldName, value)
    };



    const handleSearchComboboxChange = async (category, value) => {


        {
            //await handleSearchInputChange('Material', value)
            //setSelectedMaterial([value])
            setSearchFilterType(prevSearchtype => ({
                ...prevSearchtype,
                [category]: value
            }));
            //await handleTypeSearchInputChange('Material', value)
            await handleTypeSearchInputChange(category, value)
            await handleSearchInputChange(category, value)
            // handleSearchFilterInputChange('Material', value)
        }

    };

    const UpdateFilterCategoryList = async (fieldName, value) => {
        setFilterCategoryList(prevcategory => ({
            ...prevcategory,
            [fieldName]: value
        }));
    }

    
    const UpdateFilterCategory = async () => {
        const batchSize = 5; // Number of concurrent requests
        for (let i = 0; i < filtermaping.length; i += batchSize) {
            const batch = filtermaping.slice(i, i + batchSize).map(async column => {
                if (column.dataIndex !== 'edit_delete') {
                    if (!selectdTypeFilter[column.dataIndex] || selectdTypeFilter[column.dataIndex]?.length === 0) {
                        const categoryList = await getCategoryList(column.dataIndex, searchType);
                        await UpdateFilterCategoryList(column.dataIndex, categoryList);
                    }
                }
            });
            await Promise.all(batch); // Wait for the batch to finish before starting the next one
        }
    };

    useEffect(() => {
        (async () => {
            await UpdateFilterCategory();
        })();
    }, [searchType]);

    const getCategoryList = async (searchKeyExist, searchkey) => {
        try {
            const object = {
                header: searchKeyExist,
                searchKey: searchkey
            }
            //return;
            const response = await getFilterCategoryListFromDatabse(object);
            if (response) {

                return response;
            }
        } catch (error) {
            notification.error({
                message: 'Something went wrong. ' + searchKeyExist,
            });
            return []
        }
    };

    const tagRender = (props) => {
        return null

    };
    const handleDropdownVisibleChange = (open) => {
        if (!open) {



        }
    };

    const getLogDetails = () => {

        return (
            <>
                <div className="flex-auto">
                    {!isMasterModalVisible &&
                        <div className="flex-auto">

                            <div className='addTeamDetails'>
                                {/* <Table columns={columns_Expands}
                                    className="h-100 pipeline-table  equipPaginationTable table-action-column"
                                    //scroll={{ y: 'calc(100% - 230px)' }}
                                    scroll={{ x: 1915, y: '100%' }}
                                    rowKey={'ID'}
                                    pagination={
                                        listCount > 10 ?
                                            {
                                                position: ['bottomRight'],
                                                className: 'fixed-pagination',
                                                showSizeChanger: true,
                                                total: listCount,
                                                defaultPageSize: 10,
                                                defaultCurrent: 1,

                                                onChange: async (pageNumber, pageSize) => {
                                                    // console.log(licenseTotalCount, pageNumber, pageSize)
                                                    setCurrentPageNumber(pageNumber);
                                                    setCurrentPagesize(pageSize);
                                                    var obj = {
                                                        pageSize: pageSize,
                                                        pageNumber: pageNumber,
                                                        //searchKey: searchText
                                                    }


                                                }
                                            } : false

                                    }


                                    dataSource={[...projectList]}
                                    loading={isLoading}
                                /> */}
                                <CollapsibleTable  
                                projectPricingData={projectListData}
                                isPricingDataLoading={isPricingDataLoading}
                                onUpdateClick={onUpdateClick}    
                                setIsDeleteModalVisible={setIsDeleteModalVisible}
                                onReadParts={onReadParts}
                                showDeleteModal={showDeleteModal}
                                />
                            </div>
                            {isModalVisible &&
                                <ProjectModal
                                    isModalVisible={isModalVisible}
                                    setIsModalVisible={setIsModalVisible}
                                    onClose={handleCancel}
                                    projectDetailForm={projectDetailForm}
                                    isEdit={isEdit}
                                    setIsEdit={setIsEdit}
                                    editRecord={editRecord}
                                    userName={userName}
                                    setShowPricingPage={setShowPricingPage}
                                    setProjectDetails={setProjectDetails}
                                    categoryListdata={categoryListdata}
                                    setCategoryListdata={setCategoryListdata}
                                    diameterList={diameterList}
                                    setDiameterList={setDiameterList}
                                    equipmentType={equipmentType}
                                    setEquipmentType={setEquipmentType}
                                    UpdateDiameterList={UpdateDiameterList}
                                    filterCategoryList={filterCategoryList}
                                    handleComboboxChange={handleComboboxChange}
                                    onGeneratedProject ={onGeneratedProject}
                                />
                            }

                        </div>
                    }


                </div>


            </>

        )
    }

    const getPricingTabpanes = () => {
        return (
            <div className="custom-tab h-100" style={{ paddingTop: '0px' }}>
                <Tabs defaultActiveKey='1' activeKey={activeKeyLine} onChange={handleTabChange}  >
                    {
                        <TabPane tab='Log' key='1'>
                            {getLogDetails()}
                        </TabPane>
                    }
                    {
                        <TabPane tab='Analysis' key='2'>

                        </TabPane>
                    }

                </Tabs>
            </div>
        );
    }

    /** Display Confirmation while deleting */
    const showDeleteModal = (record) => {
        setIsDeleteModalVisible(true);
        setSelectedData(record)
    };

    /** Deleting Spare parts data on confirm */
    const handleDeleteOk = async () => {
        // console.log(selectedData)
        setIsDeleteLoading(true)
        await DeletePricingDetails(selectedData)
        setIsDeleteLoading(false)
        setIsDeleteModalVisible(false);
        setSelectedData('');

        setIsDeleteLoading(false)
        var obj = {
            pageSize: currentPagesize,
            pageNumber: currentPageNumber,
            searchKey: searchCriteria

        }
        await onPricingList(obj);
        //await onfetchPricingListData(obj);

    }
    /** Cancelling the deletion of spare parts data */
    const handleDeleteCancel = () => {
        setIsDeleteModalVisible(false);
        setSelectedData('');
    }

    return (

        <div className="d-flex flex-column h-100">
            {!showPricingPage && !isMasterModalVisible && <div>
                <div className="flex-auto">
                    <div className="d-flex mb-3 align-items-center tab-content-title">


                        <div className='back_equip' >
                            <Tooltip title={'Back'} >
                                <ArrowLeftOutlined onClick={(e) => {
                                    history.push('/SmartLync-HomePage');
                                }} />
                            </Tooltip>
                        </div>

                        <Title className="mb-0 add-new-calibration" level={3} style={{ paddingLeft: '30px' }}>
                            Pricing
                        </Title>



                        <div className="new-calibration">


                            <Text onClick={() => showModal()} className="ps-3 add-new-calibration" style={{ marginRight: '20px' }}>
                                <span className="icon-22" style={{ scale: '20px' }}>
                                    <svg>
                                        <use xlinkHref="#add" />
                                    </svg>
                                </span>
                                Add New Log
                            </Text>




                            {
                                userGroup && userGroup.includes('pricing-admin') &&
                                <Button shape="round" key="apply" type="primary" style={{ marginRight: '20px' }}
                                    onClick={showMasterModal}
                                >
                                    Configuration
                                </Button>
                            }
                            <Button shape="round" key="apply" type="primary"
                                onClick={showDrawer}
                                style={{ marginLeft: '10px' }}>
                                <FilterOutlined style={{ fontSize: '20px' }}> </FilterOutlined>
                                Filter
                            </Button>
                        </div>


                    </div>

                </div>


                {getPricingTabpanes()}
            </div>

            }


            {showPricingPage &&
                <PricingUpdateForm
                    setShowPricingPage={setShowPricingPage}
                    showPricingPage={showPricingPage}

                    projectDetails={projectDetails}
                    setProjectDetails={setProjectDetails}
                    isEdit={isEdit}
                    setIsEdit={setIsEdit}
                    editRecord={editRecord}
                    categoryListdata={categoryListdata}
                    setCategoryListdata={setCategoryListdata}
                    diameterList={diameterList}
                    setDiameterList={setDiameterList}
                    projectDetailForm={projectDetailForm}

                >
                </PricingUpdateForm>

            }

            <Modal
                width="45vw"
                className="pipeline-modal"
                title="Delete Master  Details"
                centered
                visible={isDeleteModalVisible}
                footer={[
                    <Button key="cancel" onClick={handleDeleteCancel}>
                        Cancel
                    </Button>,
                    <Button key="apply" type="primary" onClick={handleDeleteOk} loading={isDeleteLoading}>
                        {isDeleteLoading ? 'Deleting' : 'Delete'}
                    </Button>
                ]}
            >
                <div>
                    <Text style={{ fontSize: '16px' }}>
                        <p>Deleting Pricing  Details will delete all the corresponding and related information.</p>
                        Are you sure you want to delete <b style={{ color: "#EF314C" }}>{selectedData && selectedData.Proposal_Number}</b> Pricing project?</Text>
                </div>
            </Modal>


            {isMasterModalVisible &&
                <MasteForm
                    isMasterModalVisible={isMasterModalVisible}
                    setIsMasterModalVisible={setIsMasterModalVisible}
                    selectedTap={selectedTap}
                    userGroup={userGroup}

                />
            }
            { !isModalVisible &&               
            <Drawer
                    title="Filter Options"
                    placement="right"
                    onClose={closeDrawer}
                    visible={isDrawerVisible}
                    width='30%'
                    className='pipeline-modal Filter-Drawer'
                >

                    <div className="drawer-content">
                        <Layout className="content-scrollable">
                            <Content>
                                {filtermaping.map(column => {
                                    if (column.dataIndex !== 'edit_delete') {
                                        return (
                                            <Space style={{ display: 'flow', alignItems: 'center', }}>
                                                {column.title}
                                                {

                                                    <div className='maintenance-select1'>
                                                        <Select
                                                            className='maintenance-select1'
                                                            placeholder={'Select category'}
                                                            showSearch
                                                            allowClear
                                                            mode="multiple"
                                                            tagRender={tagRender}


                                                            //loading={isUserListLoading}
                                                            style={{
                                                                height: '35px',
                                                                position: 'relative',
                                                                top: '0px',
                                                                width: '100%'
                                                            }}
                                                            //optionFilterProp="children"
                                                            optionFilterProp={false}
                                                            onChange={(value) => handleComboboxChange(column.dataIndex, value)}
                                                            // onFocus={onGetAllUsers}
                                                            onDropdownVisibleChange={handleDropdownVisibleChange}
                                                            onSearch={(value) => handleSearchComboboxChange(column.dataIndex, value)}
                                                            searchValue={searchFilterType[column.dataIndex]}
                                                            //value={matrialSearchType == '' ? selectedMaterial : matrialSearchType}
                                                            value={selectdTypeFilter[column.dataIndex]}

                                                        // disabled={disableTypes}
                                                        >
                                                            {Object.keys(filterCategoryList).length > 0 && filterCategoryList[column.dataIndex] &&
                                                                filterCategoryList[column.dataIndex]
                                                                    //.sort((a, b) => a[column.dataIndex].localeCompare(b[column.dataIndex])) // Sort by category
                                                                    .map((item, i) => (
                                                                        <Option key={i} value={item[column.dataIndex]}> {/* Use item.category as value */}
                                                                            {item[column.dataIndex]}
                                                                        </Option>
                                                                    ))
                                                            }
                                                        </Select>
                                                        <div className="selected-tags">
                                                            {selectdTypeFilter &&  Array.isArray(selectdTypeFilter[column.dataIndex]) && 
                                                            selectdTypeFilter[column.dataIndex]?.length == 0 &&
                                                             searchFilterType[column.dataIndex] != '' &&
                                                                <div key={0} className='selected-tag'>
                                                                    {searchFilterType[column.dataIndex]??''}
                                                                    <span
                                                                        className='remove-tag'
                                                                        onClick={() => {


                                                                            handleSearchComboboxChange(column.dataIndex, '')
                                                                            //setSelectedMaterial(newSelectedMaterials);
                                                                        }}
                                                                    >
                                                                        X
                                                                    </span>
                                                                </div>
                                                            }
                                                            {searchFilterType[column.dataIndex] === '' && selectdTypeFilter &&  Array.isArray(selectdTypeFilter[column.dataIndex]) && selectdTypeFilter[column.dataIndex]?.length > 0 && 
                                                            selectdTypeFilter[column.dataIndex].map((value, index) => (
                                                                <div key={index} className='selected-tag'>
                                                                    {value}
                                                                    <span
                                                                        className='remove-tag'
                                                                        onClick={() => {

                                                                            const newSelectedMaterials = selectdTypeFilter[column.dataIndex].filter(item => item !== value);
                                                                            //handleMaterialComboboxChange(newSelectedMaterials)
                                                                            handleComboboxChange(column.dataIndex, newSelectedMaterials)
                                                                            //setSelectedMaterial(newSelectedMaterials);
                                                                        }}
                                                                    >
                                                                        X
                                                                    </span>
                                                                </div>
                                                            ))}

                                                        </div>
                                                    </div>
                                                }
                                            </Space>
                                        );
                                    }
                                    return null;
                                })}
                            </Content>
                        </Layout>
                        <Footer className="fixed-footer">
                            <div className="button-container">
                                <Button type="primary" style={{ marginTop: 6 }} onClick={onClearFilters}>
                                    Clear Filters
                                </Button>
                                <Button type="primary" style={{ marginTop: 6, }} onClick={closeDrawer}>
                                    Submit
                                </Button>
                            </div>
                        </Footer>
                    </div>
                </Drawer>
            }

        </div>
    );
};
const mapStateToProps = ({ PricingListReducer }) => {
    const projectList = get(PricingListReducer, 'PricingList', []);

    const isLoading = get(PricingListReducer, 'isFetchLoading', false);
    const listCount = get(PricingListReducer, 'PricingListCount', null);
    const listTotalCount = get(PricingListReducer, 'PricingListTotalCount', null);

    const projectListData = get(PricingListReducer, 'PricingListData', []);

    const isPricingDataLoading = get(PricingListReducer, 'isPricingListLoading', false);
    const listDataCount = get(PricingListReducer, 'PricingListDataCount', null);
    const listDataTotalCount = get(PricingListReducer, 'PricingListDataTotalCount', null);

    return {
        projectList,
        isLoading,
        listCount,
        listTotalCount,
        //
        projectListData,
        isPricingDataLoading,
        listDataCount,
        listDataTotalCount
    }
}

const mapDispatchToProps = {

    //onPricingList: fetchPricingdList,
    onPricingList: fetchPricingListData,
}


export default connect(mapStateToProps, mapDispatchToProps)(PricingPage);

