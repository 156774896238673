import React, { useEffect, useState } from 'react';
import { Input, Button, Layout, Card, Row, Col } from 'antd';
import { Content, Footer } from 'antd/lib/layout/layout';

const Equipment = (props) => {
    const { formData, setFormData, OnGenerateReport, confirmLoading,powerSupplies,setIsUserEdited,isUserEdited } = props;
    const [isNonZereo, setIsNonZero] = useState(false)
    const handleInputChange = (record, value) => {
        const updatedData = { ...formData, [record.equipment]: Number(value) };
        setIsUserEdited(true)
        setFormData(updatedData);
    };
    useEffect(() => {
      const valid = hasNonZeroValue(formData)
      if(!valid)
      {
        setIsUserEdited(valid)
      }
        setIsNonZero(!valid)

    }, [formData])

    const equipmentList = [
        'PFM / COUMTERBORE', 'HPU', 'IWM', 'IWM Major Spares',
        'R/R 40FT', 'R/R 80FT', 'A/BAND', 'P/BAND',
        'Welding Shelters', 'Welding Shelters Tie-In', 'CBUC', 'CBUC Spare Copper Sets',
        'CBUC Major Spares', 'P-600 Stations', 'P-600 Major Spares', 'P-600 Spare Bugs',
        'M-300C Stations', 'M-300C Major Spares', 'M-300C Spare Bugs', 'M-500 Station',
        'M-500 Spare Bugs', 'M-500 Major Spare', 'Project Suppt. Cost', 'Project WPQ Cost',
        'WHS Container', 'Spares & Tools', 'Cold Weather Pkg', 'P-625 Stations',
        'P-625 Major Spares', 'P-625 Spare Bugs', 'Miller XMT 350 Power Supply', 'Fronius Power Supply', 'Lincoln V350 Pro Power Supply'
    ];
    // Map power supply keys to their corresponding entries in equipmentList
    const powerSupplyMapping = {
        "Miller XMT 350": "Miller XMT 350 Power Supply",
        "Lincoln": "Lincoln V350 Pro Power Supply",
        "Fronius": "Fronius Power Supply"
    };

    // Filter power supplies based on their status (True)
    const filteredPowerSupplies = Object.keys(powerSupplies)
        .filter(key => powerSupplies[key] === "True")
        .map(key => powerSupplyMapping[key]);

    // Combine original equipmentList with filtered power supplies
    const finalEquipmentList = equipmentList.filter(item => !Object.values(powerSupplyMapping).includes(item))
        .concat(filteredPowerSupplies);


    const OnClearAllvalue = () => {
        const updatedData = { ...formData };
        Object.keys(updatedData).forEach((key) => {
            updatedData[key] = 0;
        });
        setIsUserEdited(false)
        setFormData(updatedData);
    };

    const onGenrateMargin = async () => {
        //console.log(formData)
        //.log(hasNonZeroValue(formData))
        await OnGenerateReport()
        setIsUserEdited(false)
    }

    // Split equipmentList into groups of 8 for each card
    const groupedEquipment = [];
    for (let i = 0; i < finalEquipmentList.length; i += 8) {
        // Check if we are near the end and there is only one item left
        if (finalEquipmentList.length - i <= 9 && finalEquipmentList.length - i > 8) {
            // Push the last 9 items into the final group
            groupedEquipment.push(finalEquipmentList.slice(i, i + 9));
            break; // Exit loop since we've processed all items
        } else {
            // Otherwise, push the normal group of 8
            groupedEquipment.push(finalEquipmentList.slice(i, i + 8));
        }
    }


    const hasNonZeroValue = (data) => {
        return Object.values(data).some(value => typeof value === 'number' && value !== 0);
    };

    return (
        <Layout style={{ height: '93vh', width: '100%' }}>
        <Content 
        style={{
            border: "1px solid #e0e0e0", // Light border around the component
            borderRadius: "8px", // Rounded corners for a smoother look
            backgroundColor: "transparent", // Light background color for visual separation
            padding: "5px", // Add padding for spacing
            boxSizing: "border-box",
             overflowY: 'auto'
        }}
        >
             
                    {/* Loop through the entire equipment list, displaying it in a single column */}
                    {finalEquipmentList.map((equipment, idx) => (
                        <div className="equipment-row" key={idx} style={{ marginBottom: '5px' }}>
                            <div className="equipment-column" style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                <span className="equipment-name" style={{ flex: '1', paddingLeft: '5px',  textAlign:'left' }}>{equipment}</span>
                                <Input
                                     min={0}
                                    className="equipment-qty"
                                    type="number"
                                    value={formData[equipment] ?? 0}
                                    onChange={(e) => handleInputChange({ equipment }, e.target.value)}
                                    style={{ width: '100px', textAlign:'center' }}
                                />
                            </div>
                        </div>
                    ))}
                
            </Content>
          
            <Footer className="equipment-footer" style={{ textAlign: 'center'  ,marginTop:'3px'}}>
                <Button type="primary" onClick={OnClearAllvalue}>
                    Clear
                </Button>
                <Button type="primary" loading={confirmLoading} onClick={onGenrateMargin} disabled={isNonZereo || !isUserEdited}>
                    Generate Margin
                </Button>
            </Footer>
        </Layout>
    );
};

export default Equipment;




// import React, { useState } from 'react';
// import { Table, Button, Layout } from 'antd';
// import { Content, Footer } from 'antd/lib/layout/layout';

// const Equipment = (props) => {
//     const { setFormData, formData, OnGenerateReport, confirmLoading } = props;

//     const equipmentList = [
//         'PFM / COUMTERBORE', 'HPU', 'IWM', 'IWM Major Spares', 'CBUC',
//         'CBUC Spare Copper Sets', 'CBUC Major Spares', 'R/R 40FT', 'R/R 80FT',
//         'A/BAND', 'P/BAND', 'P-600 Stations', 'P-600 Major Spares', 'P-600 Spare Bugs',
//         'M-300C Stations', 'M-300C Major Spares', 'M-300C Spare Bugs', 'M-500 Station',
//         'M-500 Spare Bugs', 'M-500 Major Spare', 'Project Suppt. Cost', 'Project WPQ Cost',
//         'WHS Container', 'Spares & Tools', 'Welding Shelters', 'Welding Shelters Tie-In',
//         'Miller XMT 350 Power Sup', 'Fronius Power Sup', 'Cold Weather Pkg',
//         'P-625 Stations', 'P-625 Major Spares', 'P-625 Spare Bugs'
//     ];

//     const [dataSource, setDataSource] = useState(
//         equipmentList.map((item, index) => ({
//             key: index.toString(),
//             equipment: item,
//             qty: formData[item] || 0,
//         }))
//     );

//     const handleSave = (row) => {
//         const newData = [...dataSource];
//         const index = newData.findIndex((item) => row.key === item.key);
//         const item = newData[index];
//         newData.splice(index, 1, { ...item, ...row });
//         setDataSource(newData);

//         // Update formData with the new quantity value
//         setFormData((prevData) => ({ ...prevData, [row.equipment]: row.qty }));
//     };

//     const columns = [
//         {
//             title: 'Equipment',
//             dataIndex: 'equipment',
//             key: 'equipment',
//         },
//         {
//             title: 'QTY',
//             dataIndex: 'qty',
//             key: 'qty',
//             editable: true,
//             render: (text, record) => (
//                 <div
//                     contentEditable
//                     suppressContentEditableWarning
//                     onBlur={(e) => {
//                         const newQty = e.target.innerText;
//                         handleSave({ ...record, qty: Number(newQty) });
//                     }}
//                     style={{ padding: '4px' }}  // Reduce padding for each cell
//                 >
//                     {text}


                    
//                 </div>



//             ),
//         },
//     ];

//     return (
//         <Layout style={{ height: '90vh', width: '100%' }}>
//             <Content style={{ padding: '0px', overflowY: 'auto' }}>
//                 <Table
//                     dataSource={dataSource}
//                     columns={columns}
//                     className='prcing-Table'
//                     pagination={false}
//                 // rowClassName={() => 'editable-row'}

//                 />
//             </Content>
//             <Footer style={{ textAlign: 'center', backgroundColor: 'transparent' }}>
//                 <Button type="primary" style={{ marginRight: '10px', borderRadius: '30px' }}>
//                     Clear
//                 </Button>
//                 <Button
//                     type="primary"
//                     style={{ marginRight: '10px', borderRadius: '30px' }}
//                     loading={confirmLoading}
//                     onClick={OnGenerateReport}
//                 >
//                     Generate Margin
//                 </Button>
//             </Footer>
//         </Layout>
//     );
// };
// export default Equipment;