import { notification } from 'antd';
import get from 'lodash/get';
import {
  all,
  call,
  put,
  takeLatest,
} from 'redux-saga/effects';


import axiosConfig from './axiosConfig';
import { FETCHPRICINGLIST, FETCH_PRICING_LIST } from './types';
import { fetchPricingdList, fetchPricingListFailure, fetchPricingListSuccess, fetchPricingListDataSuccess, fetchPricingListDataFailure } from './action';
import NotificationEmailsAxiosConfig from '../../services/NotificationEmailsAxiosConfig';
import axios from 'axios';


function* fetchPricingList(payload = {}) {
  try {

    let url = '/GetGroupPricingList';
    let queryParams = [];

    // Helper function to check if an object is empty
    const isEmptyObject = (obj) => {
      return obj && Object.keys(obj).length === 0 && obj.constructor === Object;
    };

    if (payload.pageNumber) {
      queryParams.push(`pageNumber=${payload.pageNumber}`);
    }

    if (payload.pageSize) {
      queryParams.push(`pageSize=${payload.pageSize}`);
    }

    if (payload.searchKey && !isEmptyObject(payload.searchKey)) {
      queryParams.push(`searchKey=${encodeURIComponent(JSON.stringify(payload.searchKey))}`);
    }

    if (payload.sort) {
      queryParams.push(`sort=${encodeURIComponent(JSON.stringify(payload.sort))}`);
    }

    if (queryParams.length > 0) {
      url += `?${queryParams.join('&')}`;
    }



    const res = yield axiosConfig.get(url);

    if (res.status === 200) {
      const responseData = get(res, ['data', 'data'], {});
      yield put(fetchPricingListSuccess({ responseData }));
    } else {
      yield put(fetchPricingListFailure());
      notification.error({
        message: 'Something went wrong.',
      });
    }
  } catch (error) {
    yield put(fetchPricingListFailure());
    notification.destroy();
    notification.error({
      message: 'A problem occurred while fetching Pricing list.',
      description: `${error.message}`,
    });
  }
}
//
function* fetchPricingListData(payload = {}) {
  try {

    let url = '/GetGroupPricingList';
    let queryParams = [];

    // Helper function to check if an object is empty
    const isEmptyObject = (obj) => {
      return obj && Object.keys(obj).length === 0 && obj.constructor === Object;
    };

    if (payload.pageNumber) {
      queryParams.push(`pageNumber=${payload.pageNumber}`);
    }

    if (payload.pageSize) {
      queryParams.push(`pageSize=${payload.pageSize}`);
    }

    if (payload.searchKey && !isEmptyObject(payload.searchKey)) {
      queryParams.push(`searchKey=${encodeURIComponent(JSON.stringify(payload.searchKey))}`);
    }

    if (payload.sort) {
      queryParams.push(`sort=${encodeURIComponent(JSON.stringify(payload.sort))}`);
    }

    if (queryParams.length > 0) {
      url += `?${queryParams.join('&')}`;
    }



    const res = yield axiosConfig.get(url);
    
    if (res.status === 200) {
      const responseData = get(res, ['data', 'data'], {});
      yield put(fetchPricingListDataSuccess({ responseData }));
    } else {
      yield put(fetchPricingListFailure());
      notification.error({
        message: 'Something went wrong.',
      });
    }
  } catch (error) {
    yield put(fetchPricingListDataFailure());
    notification.destroy();
    notification.error({
      message: 'A problem occurred while fetching Pricing list.',
      description: `${error.message}`,
    });
  }
}




function* PricingListWatcher() {
  //yield takeLatest(GENERATESPAREPARTS, generateRecordlist);
  yield takeLatest(FETCHPRICINGLIST, fetchPricingList);
  yield takeLatest(FETCH_PRICING_LIST, fetchPricingListData);
  // yield takeLatest(DELETESPAREPARTS, deleteRecordList);


}

function* PricingListSagas() {
  yield all([
    call(PricingListWatcher),
  ]);
}

export default PricingListSagas;




export const generateSummary = async (data) => {
  try {

    const url = `${process.env.REACT_APP_Pricing_Generator}/generate_summary`;      

    //const url = 'http://localhost:5000/generate_summary'
    //console.log('Url Data::', data)

    const res = await fetch(`${url}`, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-type': 'application/json',
        // Authorization: token
      },
      body: JSON.stringify(data),
    });

    notification.destroy();
    //console.log(await res.json())
    if (res.status === 200) {

      const response1 = await res.json();

      console.log(response1.pricing_details)

      return response1.pricing_details
    }
    if (res.status === 403) {
      notification.error({
        message: 'Unauthorized',
      });
      return null;
    }
    return null;
  } catch (error) {
    return null;
  }
};

export const RegenerateSummary = async (data) => {
  try {

    const url = `${process.env.REACT_APP_Pricing_Generator}/Regenerate_summary`;
    //const url = 'http://localhost:5000/Regenerate_summary'
    //console.log('Url Data::', data)



    const res = await fetch(`${url}`, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-type': 'application/json',
        // Authorization: token
      },
      body: JSON.stringify(data),
    });

    notification.destroy();
    //console.log(await res.json())
    if (res.status === 200) {

      const response1 = await res.json();

      console.log(response1.pricing_details)

      return response1.pricing_details
    }
    if (res.status === 403) {
      notification.error({
        message: 'Unauthorized',
      });
      return null;
    }
    return null;
  } catch (error) {
    return null;
  }
};
export const GenerateExcelReport = async (data) => {
  try {

    const url = `${process.env.REACT_APP_Pricing_Generator}/generate-report`;
    //const url = 'http://localhost:5000/generate-report'
    //console.log('Url Data::', data)



    const res = await fetch(`${url}`, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-type': 'application/json',
        // Authorization: token
      },
      body: JSON.stringify(data),
    });

    notification.destroy();
    //console.log(await res.json())
    if (res.status === 200) {

      const response1 = await res.json();

      console.log(response1)

      return response1.report_url
    }
    if (res.status === 403) {
      notification.error({
        message: 'Unauthorized',
      });
      return null;
    }
    return null;
  } catch (error) {
    return null;
  }
};
export const getCategoryListFromDatabse = async () => {
  try {
    let url;


    url = `/GetCategoryTypes`;

    const res = await axiosConfig.get(url);
    if (res.status === 403) {
      notification.error({
        message: 'Unauthorized',
      });
      return null;
    } if (res.status === 200) {
      const data = get(res, ['data', 'data'], {});
      //console.log('testType', data)
      return data;
    }
    return null;
  } catch (error) {
    return null;
  }
};

export const getDiameterListFromDatabse = async (EquipmentType) => {
  try {
    let url;

    url = `/GetDiameterTypes?EquipmentType=${(EquipmentType)}`;

    const res = await axiosConfig.get(url);
    if (res.status === 403) {
      notification.error({
        message: 'Unauthorized',
      });
      return null;
    } if (res.status === 200) {
      const data = get(res, ['data', 'data'], {});
      //console.log('testType', data)
      return data;
    }
    return [];
  } catch (error) {
    return [];
  }
};

export const getListFromDatabase = async (tableName) => {
  try {
    let url;
    url = `/GetList?Table_Name=${(tableName)}`;


    const res = await axiosConfig.get(url);
    if (res.status === 403) {
      notification.error({
        message: 'Unauthorized',
      });
      return null;
    } if (res.status === 200) {
      const data = get(res, ['data', 'data'], {});
      //console.log('testType', data)
      return data;
    }
    return [];
  } catch (error) {
    return [];
  }
};


export const AddOrUpdateMasterData = async (data, isNotify = true) => {


  try {
    const res = await axiosConfig.post('/AddOrUpdateMaster', data);

    notification.destroy();
    // console.log(yield res.json)
    if (res.status === 200) {
      const responseData = get(res, ['data', 'data'], {});
      if (isNotify)
        notification.success({
          message: 'Added Successfully.',
          duration: 3,
        });

      return responseData

    }

    else {

      notification.error({
        message: 'Something went wrong.',
      });
      return null

    }


  } catch (error) {

    notification.destroy();
    notification.error({
      message: 'Update Submission Failed.',
      description: `${error}`,
    });
    return null

  }
}

export const AddOrUpdateProject = async (data, isEdit = false ,isSendMaill =false) => {


  try {

    if (data) {

      const list = {
        data: data,
        isEdit: isEdit

      }
      const res = await axiosConfig.post('/AddOrUpdate', data);

      notification.destroy();
      // console.log(yield res.json)
      if (res.status === 200) {
        const responseData = get(res, ['data', 'data'], {});
        notification.success({
          message: data.isEdit || isEdit ? 'Update Successfully.' : 'Added Successfully.',
          duration: 3,
        });
        // await fetchRecordList()
        const stage = process.env.REACT_APP_STAGE
      
        if(isSendMaill && stage == 'prod')
        {
        const user = JSON.parse(localStorage.getItem("user"));
        let sub = "New pricing log generated by " + user.name
        let Title = "Pricing log generation notification"
        if (data.isEdit) {
          sub = "Pricing log modified by " + user.name
          Title = "Pricing log updated notification"
        }

        const emailData = {
          TitleContent: Title,
          Subject: sub,

          data: {
            "Quote Number": data.Proposal_Number,
            "Project Name": data.Project_Name,
            "Project Type": data.Project_Type,
            "Representative": data.Representative,
            "Contractor": data.Contractor,
            "Location": data.location,
            "No. Of Welds": data.No_of_Joints,
          }
        }
        await NotificationEmailsAxiosConfig.post(`NotifyEmails`, emailData);
      }
        return responseData

      }

      else {

        notification.error({
          message: 'Something went wrong.',
        });
        return null

      }
    }

  } catch (error) {

    notification.destroy();
    notification.error({
      message: 'Update Submission Failed.',
      description: `${error}`,
    });
    return null

  }
}

export const AddOrUpdateReportUrl = async (data, isEdit = false) => {


  try {

    if (data) {

      const res = await axiosConfig.post('/AddOrUpdateRortUrl', data);

      notification.destroy();
      // console.log(yield res.json)
      if (res.status === 200) {
        const responseData = get(res, ['data', 'data'], {});
        notification.success({
          message: 'Report Generated Successfully.',
          duration: 3,
        });
        // await fetchRecordList()
        return responseData

      }

      else {

        notification.error({
          message: 'Something went wrong.',
        });
        return null

      }
    }

  } catch (error) {

    notification.destroy();
    notification.error({
      message: 'Update Submission Failed.',
      description: `${error}`,
    });
    return null

  }
}

export const DeleteMasterDetails = async (data) => {

  try {
    const resmaster = await axiosConfig.post('/DeleteMaster', data);
    //console.log(resmaster)
    if (resmaster.status === 200) {
      notification.success({
        message: 'Deleted Successfully.',
        duration: 3,
      });
      return true
    }
    else {

      notification.error({
        message: 'Something went wrong.',
      });
      return false
    }


  } catch (error) {

    notification.destroy();
    notification.error({
      message: 'Delete Submission Failed.',
      description: `${error}`,
    });
    return false

  }
}

export const DeletePricingDetails = async (data) => {

  try {
    const resmaster = await axiosConfig.post('/DeleteProjects', data);
    //console.log(resmaster)
    if (resmaster.status === 200) {
      notification.success({
        message: 'Deleted Successfully.',
        duration: 3,
      });
      return true
    }
    else {

      notification.error({
        message: 'Something went wrong.',
      });
      return false
    }


  } catch (error) {

    notification.destroy();
    notification.error({
      message: 'Delete Submission Failed.',
      description: `${error}`,
    });
    return false

  }
}

export const getFilterCategoryListFromDatabse = async (payload) => {
  try {
    let url;

    if (payload.searchKey) {

      const encodedSearchKey = encodeURIComponent(JSON.stringify(payload.searchKey));
      url = `/GetFilterCategoryList?header=${(payload.header)}&searchKey=${encodedSearchKey}`;
    }
    else
      url = `/GetFilterCategoryList?header=${(payload.header)}`;

    const res = await axiosConfig.get(url);
    if (res.status === 403) {
      notification.error({
        message: 'Unauthorized',
      });
      return null;
    } if (res.status === 200) {
      const data = get(res, ['data', 'data'], {});
      //console.log('testType', data)
      return data;
    }
    return null;
  } catch (error) {
    return null;
  }
};