import { notification } from 'antd';
import fetch from '../services/fetch';
import { BASE_URL } from '../config';
import axiosConfig from './axiosConfig';
import get from 'lodash/get';

export const getUsersToAdd = async (projectNo) => {
  try {
    const projNo = encodeURIComponent(projectNo).replace(/[!'()]/g, escape).replace(/\*/g, '%2A');
    const res = await fetch(`${BASE_URL}/projects/${projNo}/users/list`, {
      method: 'GET',
      headers: {
        Accept: 'application/json',
      },
    });
    if (res.status === 403) {
      notification.error({
        message: 'Unauthorized',
      });
      return null;
    } if (res.status === 200) {
      const usersData = await res.json();
      return usersData;
    }
    return null;
  } catch (error) {
    return null;
  }
};

export const getUsers = async (pageToken, pih) => {
  try {
    let url;
    // if (pageToken && pih) {
    //   url = `/cognito/users?paginationToken=${pageToken}&searchKey=${pih}`
    // } else 
    if (pih) {
      url = `/cognito/users?paginationToken&searchKey=${pih}`
    } else if (pageToken) {
      url = `/cognito/users?paginationToken=${pageToken}&searchKey`
    } else {
      url = `/cognito/users`
    }
    const res = await axiosConfig.get(url);
    if (res.status === 403) {
      notification.error({
        message: 'Unauthorized',
      });
      return null;
    } if (res.status === 200) {
      const usersData = get(res, ['data', 'data'], {});
      return usersData;
    }
    return null;
  } catch (error) {
    return null;
  }
};

export const getMembers = async (userData) => {
  try {
    const res = await fetch(`${BASE_URL}/users/details`, {
      method: 'POST',
      body: JSON.stringify({ userData }),
      headers: {
        Accept: 'application/json',
      },
    });
    if (res.status === 403) {
      notification.error({
        message: 'Unauthorized',
      });
      return null;
    } if (res.status === 200) {
      const usersData = await res.json();
      return usersData;
    }
    return null;
  } catch (error) {
    return null;
  }
};

export const addTeamMembers = async (projectNo, users) => {
  try {
    const projNo = encodeURIComponent(projectNo).replace(/[!'()]/g, escape).replace(/\*/g, '%2A');
    const res = await fetch(`${BASE_URL}/project/user/add?project_numbers=${projNo}`, {
      method: 'POST',
      body: JSON.stringify({ userId: users }),
      headers: {
        Accept: 'application/json',
      },
    });
    if (res.status === 403) {
      notification.error({
        message: 'Unauthorized',
      });
      return null;
    } if (res.status === 200) {
      return true;
    }
    return null;
  } catch (error) {
    return null;
  }
};

export const removeTeamMember = async (projectNo, userId) => {
  try {
    const projNo = encodeURIComponent(projectNo).replace(/[!'()]/g, escape).replace(/\*/g, '%2A');
    const res = await fetch(`${BASE_URL}/project/user/delete?project_numbers=${projNo}&user_id=${userId}`, {
      method: 'DELETE',
      headers: {
        Accept: 'application/json',
      },
    });
    if (res.status === 403) {
      notification.error({
        message: 'Unauthorized',
      });
      return null;
    } if (res.status === 200) {
      return true;
    }
    return null;
  } catch (error) {
    return null;
  }
};

export const getAccess = async (userName) => {
  try {
    let url = `/cognito/user/groups/${userName}`
    const res = await axiosConfig.get(url);
    if (res.status === 403) {
      notification.error({
        message: 'Unauthorized',
      });
      return null;
    } if (res.status === 200) {
      const usersData = get(res, ['data', 'data'], {});
      return usersData;
    }
    return null;
  } catch (error) {
    return null;
  }
};

export const getRolesToAdd = async (projectNo) => {
  try {
    const projNo = encodeURIComponent(projectNo).replace(/[!'()]/g, escape).replace(/\*/g, '%2A');
    const res = await fetch(`${BASE_URL}/project/summary/get-personnel-role?project_id=${projNo}`, {
      method: 'GET',
      headers: {
        Accept: 'application/json',
      },
    });
    if (res.status === 403) {
      notification.error({
        message: 'Unauthorized',
      });
      return null;
    } if (res.status === 200) {
      const usersData = await res.json();
      return usersData;
    }
    return null;
  } catch (error) {
    return null;
  }
};

export const addRoles = async (selectedRole, userIds) => {
  try {
    const res = await fetch(`${BASE_URL}/project/summary/add-personnel-role`, {
      method: 'POST',
      body: JSON.stringify({
        entries: [{
          role_id: selectedRole,
          user_project_id: userIds
        }]
      }),
      headers: {
        Accept: 'application/json',
      },
    });
    if (res.status === 403) {
      notification.error({
        message: 'Unauthorized',
      });
      return null;
    } if (res.status === 200) {
      return true;
    }
    return null;
  } catch (error) {
    return null;
  }
};
